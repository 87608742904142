import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import EmailOutlineIcon from 'mdi-react/EmailOutlineIcon';
import CardAccountDetailsIcon from 'mdi-react/PasswordOutlineIcon';
import Signin from '../../assets/blue.png'
import Logo from '../../assets/blue.png'
import axios from 'axios';
import { Auth } from '../../Context/local.context';



const LogIn = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [isLoaded, setisLoaded] = useState(true);
  const { setAuthData } = useContext(Auth)

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  function submit(event) {
    event.preventDefault()

    if (validateEmail(email)) {
      setisLoaded(false);

      axios.post("api/dashboard/Auth/Login",
        {
          "Email": email,
          "Password": password
        }
      ).then(response => {
        
        if (response.data.StatusCode === 0) {
          localStorage.setItem("authnav", JSON.stringify(response.data.ResponseObject));
          setAuthData(response.data.ResponseObject);
          history.push(response.data.ResponseObject.Navigations[0].FrontEndUrl);
        } else {
          alert(response.data.StatusMessage)
        }
        setisLoaded(true);

      }).catch(error => {
        setisLoaded(true);
        alert("Invalid Login")
        
      })





      // if(email==="admin@soneri.com"&&password==="123456")
      // history.push("/admin/dashboard/index")

    } else {
      alert("Invalid email address.");
      setisLoaded(true);

    }


  }
  return (
    !isLoaded ? (
      <div style={{ textAlign: "center" }}>
        {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
        <div className={`load${true ? '' : ' loaded'}`}>

          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
      </div>
    ) :
      <div className="row col-md-12 col-sm-12 col-lg-12">

        <div className="row col-md-12 col-sm-12 col-lg-12" >
          <div className="col-md-6 col-lg-6 col-sm-12" id="mobidiv" style={{ margin: "auto" }}>
            <div style={{ margin: 5 }}>
              {/* <h4 style={{fontWeight:"bold",textTransform:"uppercase"}}>Welcome</h4>
            <h1 style={{color:"black"}}>Soneri Digital Onboarding</h1> */}
            </div>
            <img src={Signin} style={{ height: "10vh", objectFit: "contain" }} />
            {/* </div>
        <div className="col-md-6 col-lg-6 col-sm-12" style={{marginTop:50}}> */}
            <div style={{ textAlign: "center" }} >
              <img src={Logo} id="faysallogo" style={{ display: "none" }} />
            </div>
            <form className="form" onSubmit={submit} style={{ marginTop: 30 }}>
              <h3>Admin Login</h3>
              <div className="form__form-group" style={{ marginTop: 20 }}>
                <span className="form__form-group-label" style={{ marginLeft: 40, fontSize: 15, fontWeight: 400, color: "black" }}>Email</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <EmailOutlineIcon />
                  </div>
                  <input
                    style={{ borderColor: "var(--faysal)", borderRadius: 5, height: 35, fontSize: 14 }}
                    name="name"
                    type="email"
                    autoComplete="off"
                    placeholder="Input your email here"
                    required
                    value={email}
                    onChange={(txt) => { setEmail(txt.target.value); }}
                  />
                </div>
              </div>

              <div className="form__form-group" >
                <span className="form__form-group-label" style={{ marginLeft: 40, fontSize: 15, fontWeight: 400, color: "black" }}>Password</span>
                <div className="form__form-group-field">
                  <div className="form__form-group-icon">
                    <CardAccountDetailsIcon />
                  </div>
                  <input
                    style={{ borderColor: "var(--faysal)", borderRadius: 5, height: 35, fontSize: 14 }}
                    name="name"
                    type="password"
                    placeholder="Input your password"
                    required
                    autoComplete="off"
                    value={password}
                    onChange={(txt) => { setpassword(txt.target.value); }}
                  />
                </div>
              </div>
              <button className={"btn"} type={"submit"} style={{ marginLeft: 30, backgroundColor: "var(--faysal)", color: "white", fontWeight: "bold" }}>LOGIN</button>
            </form>
          </div>
        </div>
      </div>
  )
}

export default LogIn;

