import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, AttachFile, Person, AssignmentInd, CalendarToday, PhoneAndroid, Email } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import Cinput from "../customComponents/CustomInput";
import { CheckAlpha, CheckNumeric, CheckPhone, ContineuResentOtp } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import Recaptcha from 'react-recaptcha'
import { Config } from "../../congig";
import { AppScreen } from "./screen";
import { ApiDataContext } from "../Context/Api.context";
import CustomCheck from "../customComponents/CustomCheck";
const publicIp = require("react-public-ip");

const BasicDetails = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext);

    const { showInfo, showError, setLoading, DynamicModelShow, DynamicModelHide } = useContext(AlertDataContext);
    const { VerifyDetail } = useContext(FunctionsContext)
    const history = useHistory();
    const [checkexpirybool, setcheckexpirybool] = useState(false)
    const [Captcha, setCaptcha] = useState(false)
    const [isCNICCheck, setIsCNICCheck] = useState(false)
    const [Layout, setLayout] = useState(false)
    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);
    const [goforwardDpb, setgoforwardDpb] = useState(true)

    const datasubmit = async (e) => {
        //e.preventDefault();
        setLoading(true);
        await getLocation();
        if (!isCNICCheck && !window.setedit && AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")]) {
            setLoading(true);
            var resp = await CheckPhone(data);
            setIsCNICCheck(true);

            if (resp.StatusCode === 0) {
                if (resp.ResponseObject.Exist === true) {
                    var t = (<>
                        <p id="server-modal-description">The following number you entered is registered with another application. Would you like to continue with the previous application.</p>
                        <button style={{ margin: 10, background: "var(--secondary)", color: "#fff", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => {
                            DynamicModelHide();
                            history.push("/")
                        }} >
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Cancel
                        </button>
                        <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                            onClick={() => {
                                async function click() {
                                    setLoading(true);
                                    DynamicModelHide();

                                    history.push("/continueotp", {
                                        email: resp.ResponseObject.Email,
                                        phone: resp.ResponseObject.PhoneNo
                                    });
                                    setLoading(false);


                                }
                                click();
                            }}
                        >Continue
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button >
                    </>)
                    setLoading(false);
                    DynamicModelShow("Number Already Exist",
                        t
                        , false);
                    return;

                }
            }
        }
        const ipv4 = await publicIp.v4()
        setData({ ...data, UserIp: ipv4 });
        if (Config.robot && !enable) {
            if (Captcha == false) {
                alert("Validate Captcha.")
                setLoading(false);

                return;
            }
        }
        if (data.latitude === null) {
            alert("Your location is required application.");
            getLocation();
            setLoading(false);

            return
        }
        // var t = new Date(data.dateofbirth);
        // var age = parseInt(new Date().getFullYear()) - parseInt(t.getFullYear());
        // if (age < 18) {
        //     alert("This will be a Minor account and details of Guardian (Parents/Legal) would be required under the separate TAB")
        // }
        
        var resp = await VerifyDetail(data, AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")] ? true : false);
        setLoading(false);
        if (resp.StatusCode === 0) {
            if (AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")]) { history.push("individual") }
            else { history.push("otp"); }

        }
    }
    var callback = function () {

    };
    var verifyCallback = function (response) {

        setCaptcha(true)
    };

    const CNICFoucusOut = async () => {
        if (data?.cnictype === "CNIC" && data?.cnic.length < 13) {
            return
        }
        setLoading(true);
        var resp = await CheckPhone(data);
        setIsCNICCheck(true);

        if (resp.StatusCode === 0) {
            if (resp.ResponseObject.Exist === true) {
                var t = (<>
                    <p id="server-modal-description">The following number you entered is registered with another application. Would you like to continue with the previous application.</p>
                    <button style={{ margin: 10, background: "var(--secondary)", color: "#fff", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => {
                        DynamicModelHide();
                        history.push("/")
                    }} >
                        <ArrowLeft style={{ marginLeft: -10 }} />
                        Cancel
                    </button>
                    <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                        onClick={() => {
                            async function click() {
                                setLoading(true);
                                DynamicModelHide();

                                history.push("continueotp", {
                                    email: resp.ResponseObject.Email,
                                    phone: resp.ResponseObject.PhoneNo
                                });
                                setLoading(false);


                            }
                            click();
                        }}
                    >Continue
                        <ArrowRight style={{ marginRight: -10 }} />
                    </button >
                </>)
                setLoading(false);
                DynamicModelShow("Number Already Exist",
                    t
                    , false);

            } else {
                // DynamicModelHide()
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getLocation();
        setActive("Applicant Basic Details & OTP");
    }, [])
    const checkexpiry = (txt) => {
        var value = txt.target.value;
        var t = new Date(value);
        if (t <= new Date()) {
            // alert("Please ensure to submit NADRA receipt/token along with your CNIC.");
            setcheckexpirybool(true);
        } else {
            setcheckexpirybool(false);

        }
        setvalue(txt);
    }
    function getLocation() {
        return new Promise(async (resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    setData({
                        ...data,
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    })
                    resolve();
                }, () => {
                    setData({
                        ...data,
                        latitude: null,
                        longitude: null
                    })
                    resolve();
                });
            }
        });
    }

    const checkdob = (txt) => {
        var t = new Date(txt.target.value);
        var age = parseInt(new Date().getFullYear()) - parseInt(t.getFullYear());
        if (age < 18) {
            alert("Dear Customer, currently we are not offering Digital Account Opening facility for Minors. Inconvenience is regretted, please approach nearest Soneri Bank Branch.");
            setgoforwardDpb(false);
        } else {
            setgoforwardDpb(true);
        }
        setvalue(txt);
    }
    return (
        <Animatedpage enablemode={() => setenable(false)} submit={() => {
            if (!enable) {
                datasubmit()
            } else {
                history.push("individual");
            }
        }} pop={() => setLayout(true)}
            buttons={
                <div className="btndiv">
                    <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("otherdetails")}>
                        <ArrowLeft style={{ marginLeft: -10 }} />
                        Back
                    </button>
                    <button type="submit" disabled={!goforwardDpb} style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>
                        {window.setedit === true ? "Update" : "Next"}
                        <ArrowRight style={{ marginRight: -10 }} />
                    </button>
                </div>
            }

        >
            {Layout ?
                <Grid item xs={12} id="s1q2">
                    <Row>
                        <fieldset disabled={enable}>

                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <Person style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Customer Name</h1>
                                        <input className="inputtxt" type={"text"} name="customerName" value={data.customerName} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                        <p className="Qhelper2">As per NADRA ID document.</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <AssignmentInd style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Identification Type</h1>
                                        <select name="cnictype" className="inputtxt" onChange={(txt) => setvalue(txt)} required
                                            disabled={AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")] ? true : false}
                                        >
                                            <option value="">Select</option>
                                            {
                                                ApiData.CNICList.map(p => (
                                                    <option value={p.Id} selected={data.cnictype !== undefined && (data.cnictype === p.Name || data.cnictype === p.Id) ? true : false}>{p.Name}</option>
                                                ))
                                            }
                                        </select>
                                        <p className="Qhelper2"></p>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <AssignmentInd style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">NADRA issued identity document No.</h1>
                                        <input className="inputtxt" type={"text"} minLength={data.cnictype === "CNIC" ? 13 : 13} maxLength={data.cnictype === "CNIC" ? 13 : 13} value={data.cnic} name="cnic" onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} onBlur={() => CNICFoucusOut()} required
                                            disabled={AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")] ? true : false}
                                        />
                                        <p className="Qhelper2">Enter ID number without dashes.</p>
                                    </div>
                                </div>

                            </div>

                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <CalendarToday style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Issuance Date</h1>
                                        <input className="inputtxt" type="date" value={data.issuancedate} max={new Date().getFullYear() + "-0" + (parseInt(new Date().getMonth()) + 1) + "-" + new Date().getDate()} name="issuancedate" onChange={(txt) => {
                                            if (new Date(txt.target.value) > new Date()) {
                                                setData({ ...data, dateofbirth: new Date() });
                                                // checkdob(txt)
                                            } else {
                                                setvalue(txt)
                                            }
                                        }} required />
                                        <p className="Qhelper2">Enter CNIC issuance date.</p>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <CalendarToday style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Expiry Date</h1>
                                        <input className="inputtxt" type="date" name="expirydate"
                                            min={new Date(data.issuancedate).getFullYear() + "-" + (parseInt(new Date(data.issuancedate).getMonth()) + 1) + "-" + parseInt(new Date(data.issuancedate).getDate() + 1)}
                                            max={"9999-12-31"}
                                            onChange={(txt) => checkexpiry(txt)} required value={data.expirydate} disabled={data.BD_Lifetime_1} />
                                        <p className="Qhelper2">Enter CNIC expiry date.</p>
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"BD_Lifetime_1"} setvalue={setvalue} required={false} />
                                    </div>
                                </div>
                            </div>

                            {
                                checkexpirybool && (data.BD_Lifetime_1 === undefined || data.BD_Lifetime_1 === "") ?
                                    <div>
                                        <h1 className="Q" style={{ color: "red" }}>Please ensure to submit NADRA receipt/token along with your CNIC.</h1>
                                        <div className="inputrow">
                                            <div className="inputcolumn1">
                                                <AttachFile style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                            </div>
                                            <div className="inputcolumn2">

                                                {AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")] ?
                                                    <>
                                                        <h1 className="Q">Attach NADRA receipt/token</h1>
                                                        <div className="btn"
                                                            onClick={() => { document.querySelector("input[name='cnicreceipt']").click(); }}
                                                            style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                            {data.cnicreceipt ? data.cnicreceipt[0]?.name : "Change File"}
                                                        </div>
                                                        <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="cnicreceipt" multiple={false} onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                                        <p className="Qhelper2">Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                                    </>
                                                    :
                                                    <>
                                                        <h1 className="Q">Attach NADRA receipt/token</h1>
                                                        <input className="inputtxt" type="file" accept="application/pdf, image/png,image/jpg,image/jpeg" name="cnicreceipt" multiple={false} onChange={(txt) => setvalue(txt)} required />
                                                        <p className="Qhelper2">Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                                    </>}

                                            </div>
                                        </div>

                                    </div>
                                    : <></>
                            }
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <AttachFile style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        {AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")] ?
                                            <>
                                                <h1 className="Q">Attach CNIC (Front)</h1>
                                                <div className="btn"
                                                    onClick={() => { document.querySelector("input[name='cnicdocfront']").click(); }}
                                                    style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                    {data.cnicdocfront ? data.cnicdocfront[0]?.name : "Change File"}
                                                </div>
                                                <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="cnicdocfront" multiple={false} onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                                <p className="Qhelper2" style={{ marginTop: 0 }}>Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                            </>
                                            :
                                            <>
                                                <h1 className="Q">Attach CNIC (Front)</h1>
                                                <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="cnicdocfront" multiple={false} onChange={(txt) => setvalue(txt)} required />
                                                <p className="Qhelper2" style={{ marginTop: 0 }}>Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                            </>}
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <AttachFile style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">

                                        {AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")] ?
                                            <>
                                                <h1 className="Q">Attach CNIC (Back)</h1>
                                                <div className="btn"
                                                    onClick={() => { document.querySelector("input[name='cnicdocback']").click(); }}
                                                    style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                    {data.cnicdocback ? data.cnicdocback[0]?.name : "Change File"}
                                                </div>
                                                <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="cnicdocback" multiple={false} onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                                <p className="Qhelper2">Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                            </>
                                            :
                                            <>
                                                <h1 className="Q">Attach CNIC (Back)</h1>
                                                <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="cnicdocback" multiple={false} onChange={(txt) => setvalue(txt)} required />
                                                <p className="Qhelper2">Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                            </>}
                                    </div>
                                </div>

                            </div>


                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <CalendarToday style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Date of Birth</h1>
                                        <input className="inputtxt" type="date" max={new Date().getFullYear() + "-0" + (parseInt(new Date().getMonth()) + 1) + "-" + new Date().getDate()} name="dateofbirth" value={data.dateofbirth} onChange={(txt) => {
                                            if (new Date(txt.target.value) > new Date()) {
                                                setData({ ...data, dateofbirth: new Date() });
                                                // checkdob(txt)
                                            } else {
                                                checkdob(txt)
                                            }
                                        }} required />
                                        <p className="Qhelper2">As per NADRA record.</p>
                                    </div>
                                </div>

                            </div>

                            <div style={{ marginBottom: 30 }}>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <PhoneAndroid style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Cell Number/WhatsApp Number</h1>
                                        {/* <input className="inputtxt" type="tel" maxLength={13} minLength={13} name="mnumber" value={data.mnumber} onChange={(txt) => setvalue(txt)} required /> */}
                                        <div class="uk-width-1-2@s input-group">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    +92
                                                </div>
                                            </div>
                                            <input class="inputtxt" type="tel" maxLength={10} minLength={10} placeholder="" style={{ width: "93%", borderLeft: "none !important" }} value={data.mnumber} name="mnumber" onChange={(txt) => CheckNumeric(txt, () => {
                                                var fl = txt.target.value.charAt(0);
                                                if (fl == 0) {                                                    
                                                    txt.target.value = txt.target.value.slice(0, -1)
                                                }
                                                setvalue(txt)

                                            }, () => { })} required
                                                disabled={AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")] ? true : false}
                                            />
                                            <p className="Qhelper2" style={{visibility:"visible"}}>Example +92-3001111111</p>
                                            <p className="Qhelper2" style={{visibility:"visible"}}>Note: If your mobile number is ported on another service provider,kindly type “REG” and send SMS to “9293” or contact Soneri Bank: 021111766374 for assistance.</p>
                                            <CustomCheck list={ApiData.CheckBoxList} Name={"BD_Whatsapp_1"} setvalue={setvalue} required={false} />
                                            {Config.robot && !enable ?
                                                <Recaptcha

                                                    sitekey="6Ld_88YcAAAAAB179MAic7n2N5tgDn6jHERoYKew"
                                                    render="explicit"
                                                    verifyCallback={verifyCallback}
                                                    onloadCallback={callback}
                                                /> : null}
                                        </div>
                                    </div>
                                </div>

                                <p className="Qhelper2"></p>
                            </div>

                            <div>
                                <div className="inputrow">
                                    <div className="inputcolumn1">
                                        <Email style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                    </div>
                                    <div className="inputcolumn2">
                                        <h1 className="Q">Email Address</h1>
                                        <input className="inputtxt" type="email" name="email" value={data.email} onChange={(txt) => setvalue(txt)} required
                                            disabled={AppScreen[history.location.pathname.replace("/", "")] <= AppScreen[sessionStorage.getItem("CurrentScreen")] ? true : false}
                                        />
                                        <p className="Qhelper2"></p>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                    </Row>
                </Grid>
                : <></>}
        </Animatedpage>
    )

}
export default BasicDetails;