import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AlertDataContext } from "./Alert.context";
import { ApiDataContext } from "./Api.context";
import { DataContext } from "./Data.Context";

const FunctionsContext = React.createContext({});

const FunctionsContextProvider = ({ children }) => {
    const { showInfo, showError, setLoading, setmOTP } = useContext(AlertDataContext);
    const { data, setData } = useContext(DataContext);
    const { ApiData } = useContext(ApiDataContext)

    const history = useHistory();

    function VerifyDetail(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {
            var data = {
                "AccountDetails":
                {
                    "IsEdit": window.setedit !== undefined ? window.setedit : false,
                    "BankingType": Number.isInteger(context.selectedAT.id) ? parseInt(context.selectedAT.id) : context.selectedAT.id,
                    "ProductTypeId": Number.isInteger(context.option.Id) ? parseInt(context.option.Id) : context.option.Id,
                    "Currency": Number.isInteger(context.currency) ? parseInt(context.currency) : context.currency,
                    "AccountType": Number.isInteger(context.type) ? parseInt(context.type) : context.type,
                    "AccountCategory": context.category,
                    "ExpiryDate": context.BD_Lifetime_1 === undefined || context.BD_Lifetime_1 === "" || context.BD_Lifetime_1 === "None" ? context.expirydate : new Date().toDateString(),
                    "NadraIssuanceDate": context.issuancedate === undefined ? document.getElementsByName('issuancedate')[0].value : context.issuancedate,
                    "ExpiryLifetime": context.BD_Lifetime_1 !== "" && context.BD_Lifetime_1 !== "None" && context.BD_Lifetime_1 !== undefined ? true : false,
                    "ExpiryLifetimeDetail": context.BD_Lifetime_1 !== "" && context.BD_Lifetime_1 !== "None" && context.BD_Lifetime_1 !== undefined ? ApiData.CheckBoxList.filter(p => p.Name === "BD_Lifetime_1")[0].id : 0,
                    "MobileNumberConsent": context.BD_Whatsapp_1 !== "" && context.BD_Whatsapp_1 !== "None" && context.BD_Whatsapp_1 !== undefined ? true : false,
                    "MobileNumberConsentDetail": context.BD_Whatsapp_1 !== "" && context.BD_Whatsapp_1 !== "None" && context.BD_Whatsapp_1 !== undefined ? ApiData.CheckBoxList.filter(p => p.Name === "BD_Whatsapp_1")[0].id : 0,
                    "latitude": context.latitude,
                    "longitude": context.longitude,
                    "CNICType": context.cnictype,
                    "PreBasic1_1": context.PreBasic1_1 !== "" && context.PreBasic1_1 !== "None" && context.PreBasic1_1 !== undefined && context.PreBasic1_1 !== 0 ? ApiData.CheckBoxList.filter(p => p.Name === "PreBasic1_1")[0].id : 0,
                    "PreBasic2_1": context.PreBasic2_1 !== "" && context.PreBasic2_1 !== "None" && context.PreBasic2_1 !== undefined ? ApiData.CheckBoxList.filter(p => p.Name === "PreBasic2_1")[0].id : 0,
                    "exportercurrency": context.PreBasic1_1 !== "" && context.PreBasic1_1 !== "None" && context.PreBasic1_1 !== undefined && context.PreBasic1_1 !== 0 ? context.exportercurrency : 0
                },
                "baseAccountVerification":
                {
                    "IsEdit": window.setedit !== undefined ? window.setedit : false,
                    "CustomerName": context.customerName,
                    "CNIC": context.cnic,
                    "DateOfBirth": context.dateofbirth,
                    "PhoneNumber": "92" + context.mnumber.replace("+", ""),
                    "Email": context.email
                },
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
            };
            var ffile = document.getElementsByName("cnicdocfront")[0]?.files.length > 0 ? document.getElementsByName("cnicdocfront")[0]?.files[0] : null;
            var Bfile = document.getElementsByName("cnicdocback")[0]?.files.length > 0 ? document.getElementsByName("cnicdocback")[0]?.files[0] : null;
            var cnicFront = new KeyValue("CNICFront", ffile);
            var cnicBack = new KeyValue("CNICBack", Bfile);
            var request_data = getFileData(data, cnicFront, cnicBack);
            if (new Date(context.expirydate) < new Date()) {
                var Rfile = document.getElementsByName("CNICReceipt")[0]?.files.length > 0 ? document.getElementsByName("CNICReceipt")[0]?.files[0] : null;
                var cnicreceipt = new KeyValue("CNICReceipt", Rfile);
                request_data = getFileData(data, cnicFront, cnicBack, cnicreceipt);
            }

            var resp = await postRequest("api/DigitalAccount/VerifyDetail", request_data);
            console.log(resp);
            resolve({ ...resp });
        })
    }

    function IndividualDetail(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {
            var data = {
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
                "MotherMaidenName": context.mother,
                "FatherHusbandName": context.father,
                "PlaceOfBirth": context.pob,
                "Gender": isNaN(context.gender) ? context.gender : context.gender !== "0" ? parseInt(context.gender) : 0,
                "MaritalStatus": isNaN(context.Marital) ? context.Marital : parseInt(context.Marital),
            };


            var resp = await postRequest("api/DigitalAccount/IndividualDetail", data);
            resolve({ ...resp });

        })
    }



    function IndividualDetail_II(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {

            var data = {
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
                "Nationality": parseInt(context.nationality),
                "ResidentCountry": parseInt(context.resident),
                "OtherNationality": context.othNationality === "" || context.othNationality === "None" || context.othNationality === null || context.othNationality === undefined ? 0 : parseInt(context.othNationality),
                "Education": isNaN(parseInt(context.education)) && context.education !== "" ? ApiData.Education.filter(p => p.Name === context.education)[0].id : parseInt(context.education),
                "PresentResidentialAddress": context.PresentResidential,
                "Landline": context.landline,
                "PermanentResidentialAddress": context.PermanentResidential,
                "PermanentProvince": context.PermanentProvince,
                "PermanentCity": context.PermanentCity,
                "PresentProvince": context.PresentProvince,
                "PresentCity": context.PresentCity,
                "NextofKinName": context.kinname,
                "NextOfKinAddress": context.kinaddress,
                "NextOfKinCNIC": context.kincnic,
                "NextOfKinRelation": context.kinrelation,
                "NextOfKinContactNumber": context.kinnumber,
                "FINALIZE_Address1_1": context.FINALIZE_Address1_1 !== undefined && isNaN(parseInt(context.FINALIZE_Address1_1)) && context.FINALIZE_Address1_1 !== "" ? ApiData.CheckBoxList.filter(p => p.Name === "FINALIZE_Address1_1")[0].id : context.FINALIZE_Address1_1 === "" || context.FINALIZE_Address1_1 === undefined ? 0 : parseInt(context.FINALIZE_Address1_1),
                "SameAsAbove": context.OTHERINFO_SameAsAbove_1 !== undefined && isNaN(parseInt(context.OTHERINFO_SameAsAbove_1)) && context.OTHERINFO_SameAsAbove_1 !== "" ? ApiData.CheckBoxList.filter(p => p.Name === "OTHERINFO_SameAsAbove_1")[0].id : context.OTHERINFO_SameAsAbove_1 === "" || context.OTHERINFO_SameAsAbove_1 === undefined ? 0 : parseInt(context.OTHERINFO_SameAsAbove_1),

            };
            var bDoc = document.getElementById("bill") != undefined && document.getElementById("bill").files.length > 0 ? document.getElementById("bill").files[0] : null;
            //var bIDoc = document.getElementById("Beneficialincomedoc").files.length > 0 ? document.getElementById("Beneficialincomedoc").files[0] : null;
            var Beneficialdoc = new KeyValue("BillDoc", bDoc);
            var request_data = getFileData(data, Beneficialdoc);


            var resp = await postRequest("api/DigitalAccount/IndividualDetailII", request_data);
            resolve({ ...resp });
        })
    }

    function IndividualDetail_III(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {
            var soruce = 0;

            context.Income.map(p => {
                soruce = soruce | p.value;
            })
            var data = {
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
                "EmployeerName": context.employeer,
                "OfficeAddress": context.officeaddress,
                "OfficePhone": context.officenumber,
                "NatureOccupation": isNaN(context.NatureOccupation) ? ApiData.NatureOccupationList.filter(r => r.Name === context.NatureOccupation)[0].id : parseInt(context.NatureOccupation),
                "Occupation": isNaN(context.Occupation) ? ApiData.OccupationList.filter(r => r.Name === context.Occupation)[0].id : parseInt(context.Occupation),
                "OccupationCheck": context.occupationcheck === true ? true : false,
                "SourceOfIncome": parseInt(soruce),
                "OfficeProvince": context.OfficeProvince,
                "OfficeCity": context.OfficeCity,
                "OccupationCheckBox": context?.FINALIZE_Occupation_1 ? ApiData.CheckBoxList.filter(p => p.Name === "FINALIZE_Occupation_1")[0].id : 0,
                "SourceOfIncomeCheck": context?.ID_Check_2 ? ApiData.CheckBoxList.filter(p => p.Name === "ID_Check_2")[0].id : 0,
                "ZakatExemption": context.Zakat === "1" ? true : false,
                "ReasonForExemptionOfZakat": isNaN(parseInt(context.Zakatreason)) && context.Zakat === "1" ? ApiData.ExemptionList.filter(p => p.Name === context.Zakatreason)[0].id : context.Zakat === "1" ? parseInt(context.Zakatreason) : 0,
                "ZakatExemptionCheckBox": context.Zakatreason === "2" && context.Zakat === "1" ? ApiData.CheckBoxList.filter(p => p.Name === "ZNT_NonMuslim_1")[0].id : context.Zakatreason === "1" && context.Zakat === "1" ? ApiData.CheckBoxList.filter(p => p.Name === "ZNT_Fiqqah_1")[0].id : 0,
                "ZakatExemptionCheck": context.ZakatExemptionCheck === true ? true : false,
            };
            var incomereceipts = [];
            var incomereceiptFiles = document.getElementsByName("incomereceipt")[0];;
            if (incomereceiptFiles !== undefined) {
                incomereceiptFiles = incomereceiptFiles.files;
                for (let i = 0; i < incomereceiptFiles.length; i++) {
                    incomereceipts.push(new KeyValue("IncomeReceipt", incomereceiptFiles[i]));
                    //formData.append("images", images[i]);
                }
            }
            //var incomereceipt = new KeyValue("IncomeReceipt", document.getElementsByName("incomereceipt")[0].files);
            var Zakatflag = false;
            var declarationCZ = null;

            if (parseInt(context.Zakat) === 1 && parseInt(context.Zakatreason) === 1) {
                declarationCZ = new KeyValue("CZ50", document.getElementById("declarationCZ").files[0]);
                Zakatflag = true;
            }
            var request_data = getFileData(data, ...incomereceipts, declarationCZ);

            var resp = await postRequest("api/DigitalAccount/IndividualDetailIII", request_data);
            resolve({ ...resp });
        })
    }

    function Beneficial(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {

            var data = {
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
                "Name": context.BeneficialName,
                "Address": context.BeneficialAddress,
                "Mobile": "92" + context.BeneficialMobile.replace("+", ""),
                "LegalIdNo": context.BeneficialLegalID,
                "Relationship": context.BeneficialRelationship,
                "BeneficialCheck": isNaN(parseInt(context.FP_Check_1)) ? ApiData.CheckBoxList.filter(p => p.Name === "FP_Check_1")[0].id : parseInt(context.FP_Check_1),
                "BeneficialCity": context.BeneficialCity,
                "BeneficialProvince": context.BeneficialProvince,

            };


            var Beneficialegeldoc = [];

            var bDoc = document.getElementById("Beneficialdoc");
            //var bIDoc = document.getElementById("Beneficialincomedoc").files.length > 0 ? document.getElementById("Beneficialincomedoc").files[0] : null;
            //var Beneficialdoc = new KeyValue("BeneficialIdDoc", bDoc);
            if (bDoc !== undefined) {
                bDoc = bDoc.files;
                for (let i = 0; i < bDoc.length; i++) {
                    Beneficialegeldoc.push(new KeyValue("BeneficialIDDoclist", bDoc[i]));
                    //formData.append("images", images[i]);
                }
            }


            //var Beneficialincomedoc = new KeyValue("BeneficialIncomeDoc", bIDoc);

            var Beneficialincomedoc = [];
            var BeneficialincomedocFiles = document.getElementsByName("Beneficialincomedoc")[0];;
            if (BeneficialincomedocFiles !== undefined) {
                BeneficialincomedocFiles = BeneficialincomedocFiles.files;
                for (let i = 0; i < BeneficialincomedocFiles.length; i++) {
                    Beneficialincomedoc.push(new KeyValue("BeneficialIncomeDoc", BeneficialincomedocFiles[i]));
                    //formData.append("images", images[i]);
                }
            }



            var request_data = getFileData(data, ...Beneficialegeldoc, ...Beneficialincomedoc);
            var resp = await postRequest("api/DigitalAccount/Beneficial", request_data);
            resolve({ ...resp });
        })
    }

    function ZakatNTrans(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {
            var arr = [];
            context.COUNTRYTAXRESIDENCE.map(p => {
                if (p.taxResidence !== undefined && p.taxResidence !== "" && (p.TIN !== undefined || p.TINReason !== undefined)) {
                    arr.push({
                        "Country": isNaN(parseInt(p.taxResidence)) ? ApiData.Country.filter(r => r.Name.replaceAll(" ", "") === p.taxResidence)[0].id : parseInt(p.taxResidence),
                        "TIN": p.TIN !== "" && p.TIN !== undefined ? p.TIN : "",
                        "TINReason": p.TINReason !== "" && p.TINReason !== "None" && p.TINReason !== undefined ? isNaN(p.TINReason) ? ApiData.TINReasonList.filter(r => r.Name === p.TINReason)[0].id : parseInt(p.TINReason) : 0,
                        "TINRemarks": p.TINremarks !== "" && p.TINremarks !== undefined ? p.TINremarks : ""
                    })
                }
            })
            var data = {
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
                "FATCAStatus": isNaN(parseInt(context.FATCAStatus)) ? ApiData.FATCAStatuslist.filter(r => r.Name === context.FATCAStatus)[0].id : context.FATCAStatus,
                "FATCADeclarations": isNaN(parseInt(context.FATCADeclarations)) ? context.FATCADeclarations === "" || context.FATCADeclarations === "None" ? 0 : ApiData.FATCADeclarationslist.filter(r => r.Name === context.FATCADeclarations)[0].id : context.FATCADeclarations,
                "countryTaxResidence": arr

            };


            var FATCADeclarations = null;

            var FATCAStatus = false;
            var ftfile = document.getElementById("WFile")?.files.length > 0 ? document.getElementById("WFile").files[0] : null;
            if (context.FATCAStatus !== "1" && ftfile !== null) {
                FATCADeclarations = new KeyValue("FATCADeclarationsDoc", ftfile);
                FATCAStatus = true;
            }

            var request_data = new FormData();
            if (FATCAStatus) {
                request_data = getFileData(data, FATCADeclarations);
            }
            else {
                buildFormData(request_data, data);
            }



            var resp = await postRequest("api/DigitalAccount/ZakatAndTransaction", request_data);
            resolve({ ...resp });
        })
    }

    function Remitter(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {
            var data = {
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
                "Name": context.remitterName,
                "Relationship": context.remitterRelation,
                "LegalId": context.remitterlegalID,
            }
            var resp = await postRequest("api/DigitalAccount/Remitter", data);
            resolve({ ...resp });
        })
    }


    function CNCInfo(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {
            var atmres = 0;
            context.ATMReason.map(p => {
                atmres = atmres | p.value;
            })
            var nutureRes = 0;
            context.NutureTrans.map(p => {
                nutureRes = nutureRes | p.value;
            })

            var data = {
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
                "ChequeBook": context.Checkbook === "1" ? true : false,
                "ChequeBookNoOfLeaves": context.Checkbook === "1" ? 1 : 0,
                "ChequeCheck": context.CNC_Checkbook_1 !== "" ? ApiData.CheckBoxList.filter(p => p.Name === "CNC_Checkbook_1")[0].id : 0,
                "ATMDebitCard": context.ATM === "1" ? true : false,
                "EFT": context.EFT === "1" ? true : false,
                "ESS": context.ESS === "1" ? true : false,
                "BranchCode": context.Branchname,
                "BranchRef": context.Branchref,
                "ESF": context.ESS === "1" && context.ESS !== undefined ? isNaN(parseInt(context.ESF)) && context.ESF !== undefined ? ApiData.StatementFrequencyList.filter(p => p.Name === context.ESF)[0].id : context.ESF !== "" && context.ESF !== undefined ? parseInt(context.ESF) : 0 : 0,
                "CardReason": parseInt(atmres),//context.,
                "DebitCardType": context.ATM === "1" ? parseInt(context.ATMCard.Id) : 0,
                "NatureOfTransactions": parseInt(nutureRes),// context.,
                "EstimatedAnnualIncome": isNaN(parseInt(context.EstimatedIncome)) ? ApiData.AnnualIncomeList.filter(p => p.Name === context.EstimatedIncome)[0].id : parseInt(context.EstimatedIncome),
                "ExpectedMonthlyCreditTransactions": context.MonthlyCredittrans !== undefined ? (isNaN(parseInt(context.MonthlyCredittrans)) ? ApiData.TransactionCountList.filter(p => p.Name === context.MonthlyCredittrans)[0].id : parseInt(context.MonthlyCredittrans)) : 0,
                "ExpectedMonthlyDebitTransactions": context.MonthlyDebittrans !== undefined ? (isNaN(parseInt(context.MonthlyDebittrans)) ? ApiData.TransactionCountList.filter(p => p.Name === context.MonthlyDebittrans)[0].id : parseInt(context.MonthlyDebittrans)) : 0,
                "ExpectedMonthlyCredit": context.MonthlyCredit !== undefined ? (isNaN(parseInt(context.MonthlyCredit)) ? ApiData.ExpectedMonthlyList.filter(p => p.Name === context.MonthlyCredit)[0].id : parseInt(context.MonthlyCredit)) : 0,
                "ExpectedMonthlyDebit": context.MonthlyDebit !== undefined ? (isNaN(parseInt(context.MonthlyDebit)) ? ApiData.ExpectedMonthlyList.filter(p => p.Name === context.MonthlyDebit)[0].id : parseInt(context.MonthlyDebit)) : 0,
                "PurposeOfAccount": isNaN(parseInt(context.purpose)) ? ApiData.accountPurposeList.filter(p => p.Name === context.purpose)[0].id : parseInt(context.purpose),
                "AgreeWithTerms": true,
                "SignatureCheck1": context.CNC_Signature1_1 !== "" ? ApiData.CheckBoxList.filter(p => p.Name === "CNC_Signature1_2")[0].id : 0,
                "SignatureCheck2": context.CNC_Signature2_1 !== "" ? ApiData.CheckBoxList.filter(p => p.Name === "CNC_Signature2_1")[0].id : 0,
                "SignatureCheck3": context.CNC_Signature3_1 !== "" ? ApiData.CheckBoxList.filter(p => p.Name === "CNC_Signature3_1")[0].id : 0,
                "SignatureCheck4": context.CNC_Signature4_1 !== "" ? ApiData.CheckBoxList.filter(p => p.Name === "CNC_Signature4_1")[0].id : 0,
                "ESFCheckbox": isNaN(parseInt(context.CNC_ESF_1)) ? ApiData.CheckBoxList.filter(p => p.Name === "CNC_ESF_1")[0].id : parseInt(context.CNC_ESF_1),
                "EFTCheckbox": isNaN(parseInt(context.CNC_EFT_1)) ? ApiData.CheckBoxList.filter(p => p.Name === "CNC_EFT_1")[0].id : parseInt(context.CNC_EFT_1),
                "SMSSubscription": context.smssubscription === "1" ? true : false,
                "CorrespondingAddress": isNaN(parseInt(context.CorrespondingAddress)) ? ApiData.CorrespondingAddressList.filter(p => p.Name === context.CorrespondingAddress)[0].id : parseInt(context.CorrespondingAddress)

            };
            var sDoc = document.getElementById("signature")?.files?.length > 0 ? document.getElementById("signature").files[0] : null;
            var Signature = new KeyValue("SignatureDoc", sDoc);
            var request_data = getFileData(data, Signature);
            var resp = await postRequest("api/DigitalAccount/ChequeAndCard", request_data);
            console.log(resp);
            resolve({ ...resp });
        })
    }
    function NumberNCNICCheck(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {

            var data = {};
            var resp = await postRequest("api/DigitalAccount/CheckNumberAndCNIC", data);
            resolve({ ...resp });
        })
    }




    function ResentOtp(context, IsEditMode = false) {
        return new Promise((resolve, reject) => {
            fetch(`api/DigitalAccount/ResendOTP`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "ReferenceId": context.cnic })
                }
            ).then((res) => res.json().then((resp) => {
                resolve({ ...resp });
            })).catch(err => {

            })
        })
    }

    function CheckPhone(context, IsEditMode = false) {
        return new Promise((resolve, reject) => {
            fetch(`api/DigitalAccount/CheckNumber`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ "ReferenceId": "92" + context.mnumber.replace("+", "") })
                }
            ).then((res) => res.json().then((resp) => {
                resolve({ ...resp });
            })).catch(err => {

            })
        })
    }

    function VerifyOtp(context, otp, Eotp, IsEditMode, ReferenceId = "") {
        return new Promise((resolve, reject) => {
            var data = {
                "IsEdit": window.setedit !== undefined ? window.setedit : false,
                "SMSOTP": otp,
                "EmailOTP": Eotp,
                "CNIC": context.cnic,
                "ReferenceId": ReferenceId
            };
            fetch("api/DigitalAccount/VerifyOTP",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
            ).then((res) => res.json().then((resp) => {
                resolve({ ...resp });
            })).catch(err => {

            })
        })
    }

    function KeyValue(key, value) {
        this.key = key;
        this.value = value;
    }

    function getFileData(data, ...attachment) {

        var form_data = new FormData();
        if (!attachment) {
            let _attachment = [...arguments];

            //for (var key in data) {
            //    if (typeof (data[key]) == 'object') {
            //        form_data.append(key, JSON.stringify(data[key]));
            //    }
            //    else {
            //        form_data.append(key, data[key]);
            //    }

            //}

            buildFormData(form_data, data);

            _attachment.forEach((element) => {
                if (element !== null) {
                    form_data.append(element.key, element.value);
                }
            });

            return form_data;
        }

        //for (var key in data) {
        //    if (typeof (data[key]) == 'object') {
        //        form_data.append(key, JSON.stringify(data[key]));
        //    }
        //    else {
        //        form_data.append(key, data[key]);
        //    }

        //}

        buildFormData(form_data, data);

        attachment.forEach((element) => {
            if (element !== null) {
                form_data.append(element.key, element.value);
            }
        });

        return form_data;
    }

    function buildFormData(formData, data, parentKey) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    function postRequest(endpoint, data) {
        return new Promise((resolve, reject) => {

            var fetchObj = {};
            if (data instanceof FormData) {
                fetchObj = {
                    method: 'POST',
                    /*headers: { 'Content-Type': 'multipart/form-data' },*/
                    /*headers: { 'Content-Type': undefined},*/
                    body: data
                }
                //delete fetchObj.headers['Content-Type'];
            }
            else {
                fetchObj = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                };

            }

            fetch(endpoint,
                fetchObj
            ).then((res) => res.json().then((resp) => {
                console.log(resp);
                if (resp.StatusCode === 0 || resp.StatusCode === 28) {
                    window.setedit = false;
                    resolve({ ...resp });

                }
                else if (resp.StatusCode === 2 || resp.StatusCode === 12) {
                    setmOTP("");
                    resolve({ ...resp });
                }
                else if (resp.StatusCode === 23) {
                    showError(resp.StatusMessage);
                    history.push("/" + resp.ResponseObject.AccountVerificationScreen)
                }
                else {
                    showError(resp.StatusMessage);
                    resolve({ ...resp });

                }
            })).catch(err => {

            })
        })
    }
    function CheckCNIC() {
        return new Promise(async (resolve, reject) => {

            var data = {};
            var resp = await postRequest("api/DigitalAccount/ValidatePhoneAndCNIC", data);
            resolve({ ...resp });

        })
    }

    function Finish() {
        return new Promise(async (resolve, reject) => {
            var data = {};
            var resp = await postRequest("api/DigitalAccount/UpdateCaseStatus", data);
            resolve();

        })
    }

    function GetCurrentScreen() {
        return new Promise((resolve, reject) => {
            fetch("api/DigitalAccount/GetCurrentScreen").then((res) => res.json().then((resp) => {
                if (resp.StatusCode === 0) {

                    resolve({ ...resp });

                }
                else {
                    //showError("Some Error occurs. Please check your details");                   
                    resolve({ ...resp });

                }
            })).catch(err => {

            })
        })
    }

    function Liveness(str) {
        return new Promise(async (resolve, reject) => {
            var data = {
                Base64Image: str
            };
            var resp = await postRequest("api/DigitalAccount/LivelinessCheck", data);
            resolve({ ...resp });

        })
    }
    function VerifyRef(Ref) {
        return new Promise(async (resolve, reject) => {
            var data = {
                ReferenceId: Ref
            };
            var resp = await postRequest("api/DigitalAccount/VerifyReferenceId", data);
            resolve({ ...resp });

        })
    }
    function LivenessConsent(context, IsEditMode = false) {
        return new Promise(async (resolve, reject) => {
            var data = {
                "PhoneCNICConsent": context.PhoneCNICConsent,
                "KYCConsent": context.KYCConsent,
                "CDCConsent": context.CDCConsent,
                "DataConsent": context.DataConsent,
                "DigitalConsent": context.DigitalConsent,
                "KFSConsent": context.KFSConsent,
                "CheckBox1": parseInt(context.Liveness_Check1_2),
                "CheckBox2": parseInt(context.Liveness_Check2_2),
                "CheckBox3": parseInt(context.Liveness_Check3_2),
                "CheckBox4": parseInt(context.Liveness_Check4_2),
                "CheckBox5": parseInt(context.Liveness_Check5_2),
                "CheckBox6": parseInt(context.Liveness_Check6_2)
            };
            var resp = await postRequest("api/DigitalAccount/LivenessConsent", data);
            resolve({ ...resp });

        })
    }
    return (
        <FunctionsContext.Provider value={{
            VerifyDetail,
            ResentOtp,
            IndividualDetail,
            IndividualDetail_II,
            IndividualDetail_III,
            VerifyOtp,
            CheckPhone,
            Beneficial,
            ZakatNTrans,
            CNCInfo,
            NumberNCNICCheck,
            Remitter,
            CheckCNIC,
            GetCurrentScreen,
            Liveness,
            LivenessConsent,
            VerifyRef,
            Finish,
            postRequest

        }}>
            {children}
        </FunctionsContext.Provider>
    )
};

export {
    FunctionsContext,
    FunctionsContextProvider
};