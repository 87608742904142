import React, { useContext, useEffect, useState } from 'react';
import Slide from '@material-ui/core/Slide';

import Logo from '../Assets/Brain.png'
import Mustaqeem from '../Assets/mustaqeem.png'

import { DataContext } from './Context/Data.Context';
import Sample from '../Assets/sample.jpg';
import Row from "./Row";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Fab, Grid } from '@material-ui/core';
import { StepsContext } from './Context/Steps.Context';
import Img1 from '../Assets/1.png';
import Img2 from '../Assets/2.jpg';
import Img3 from '../Assets/3.png';
import Img4 from '../Assets/4.png';

import MainC from '../Assets/MainC.jpg';
import MainM from '../Assets/MainM.jpg';
import ThankC from '../Assets/ThankC.jpg';
import ThankM from '../Assets/ThankM.jpg';
import FreelanceM from '../Assets/FreelanceM.jpg';
import FreelanceC from '../Assets/FreelanceC.jpg';
import AssanM from '../Assets/AssanM.jpg';
import AssanC from '../Assets/AssanC.jpg';
import RemitterM from '../Assets/RemitterM.jpg';
import RemitterC from '../Assets/RemitterC.jpg';
import RepeatC from '../Assets/repeatC.jpg';
import Repeat from '../Assets/repeat.jpg';


import { Step, Stepper } from 'react-form-stepper';
import { useHistory } from 'react-router';
import { getEnums } from './service';
import { ApiDataContext } from './Context/Api.context';
import { AlertDataContext } from './Context/Alert.context';
import { FunctionsContext } from './Context/Functions.context';
import { ArrowRight, Edit, FindInPageSharp } from '@material-ui/icons';
import { Config } from '../congig'
import { AppScreen } from './Questions/screen';

const Animatedpage = ({ children, submit, pop, sideImage, enablemode, buttons }) => {
    const { data } = useContext(DataContext);
    const { steps } = useContext(StepsContext);
    const { AddSteps, setComplete } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext);
    const { setLoading } = useContext(AlertDataContext)
    const { GetCurrentScreen, Finish } = useContext(FunctionsContext)
    const [Tabsteps, setTabsteps] = useState(steps);
    const [imgindex, setImgindex] = useState(0)
    const imglist = [Img1, Img2, Img3, Img4];
    const history = useHistory();
    const [enable, setenable] = useState((history.location.pathname === "/" ? -1 : AppScreen[history.location.pathname.replace("/", "")]) < AppScreen[sessionStorage.getItem("CurrentScreen")] ? true : false);
    const [CurrenImg, setCurrenImg] = useState(MainC)
    useEffect(() => {
        function stopEnterKey(evt) {
            var evt = (evt);// ? evt : ((event) ? event : null);
            var node = (evt.target) ? evt.target : ((evt.srcElement) ? evt.srcElement : null);
            if ((evt.keyCode == 13) && (node.type == "text")) { return false; }
        }
        document.onkeypress = stopEnterKey;
        setTabsteps(steps)
    }, [steps])

    useEffect(() => {

        ValidateSideImage();

    }, [data])
    useEffect(() => {
        validateScreen();
    }, [sessionStorage.getItem("CurrentScreen")])
    function validateScreen() {

        console.log("condition", sessionStorage.getItem("CurrentScreen") !== null && sessionStorage.getItem("CurrentScreen") !== undefined && (history.location.pathname === "/" ? -1 : AppScreen[history.location.pathname.replace("/", "")]) < AppScreen[sessionStorage.getItem("CurrentScreen")]);
        if (sessionStorage.getItem("CurrentScreen") !== null && sessionStorage.getItem("CurrentScreen") !== undefined && (history.location.pathname === "/" ? -1 : AppScreen[history.location.pathname.replace("/", "")]) < AppScreen[sessionStorage.getItem("CurrentScreen")]) {
            setenable(true)
        } else {
            setenable(false)
        }
    }
    function ValidateSideImage() {

        var path = history.location.pathname.replace("/", "");
        var BankingType = data.selectedAT?.id;
        var AccountType = data.option?.Id;
        if (path === "thankyou" && BankingType === 1) {
            setCurrenImg(ThankC);
        }

        else if (path === "thankyou" && BankingType === 2) {
            setCurrenImg(ThankM);
        }

        else if (AccountType === 1 && BankingType === 2) {
            setCurrenImg(AssanM);
        }

        else if (AccountType === 2 && BankingType === 2) {
            setCurrenImg(RemitterM);
        }

        else if (AccountType === 3 && BankingType === 2) {
            setCurrenImg(FreelanceM);
        }
        else if (AccountType === 1 && BankingType === 1) {
            setCurrenImg(AssanC);
        }

        else if (AccountType === 2 && BankingType === 1) {
            setCurrenImg(RemitterC);
        }
        else if (AccountType === 3 && BankingType === 1) {
            setCurrenImg(FreelanceC);
        }


        else if (BankingType === 1) {
            setCurrenImg(MainC);
        }

        else if (BankingType === 2) {
            setCurrenImg(MainM);
        }

        else {
            setCurrenImg(Repeat);

        }



    }


    useEffect(async () => {

        checklocalversion();
        var r = steps.findIndex(p => p.Name === "Remitter Details");

        if (r === -1 && parseInt(data.option.Id) === 2) {
            AddSteps("Remitter Details", "Zakat & FATCA Status");
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        sessionStorage.setItem("CurrentScreen", "");
        var s = await placecurrentscreen();
        var p = await Enums();
        var t = document.getElementsByTagName("input");

        for (let index = 0; index < t.length; index++) {
            const element = t[index];
            if (element.type === "file") {
                if (element.accept === "") {
                    element.accept = "application/pdf, image/png,image/jpg,image/jpeg";
                }
            }

        }
        window.setedit = false;
    }, [])

    function checklocalversion() {
        if (localStorage.getItem("localstorageVersion") !== Config.localstorageVersion) {
            localStorage.setItem("datacollection", "{}");
            localStorage.setItem("localstorageVersion", Config.localstorageVersion);
        }

    }



    async function placecurrentscreen() {

        if (sessionStorage.getItem("ReferralId") !== "" && sessionStorage.getItem("ReferralId") !== null && sessionStorage.getItem("ReferralId") !== undefined) {
            sessionStorage.setItem("CurrentScreen", "thankyou");
        }
        else {
            var resp = await GetCurrentScreen();
            if (resp.StatusCode === 0) {
                if (resp.ResponseObject.Exist === true) {
                    sessionStorage.setItem("CurrentScreen", resp.ResponseObject.ScreenName);
                }
            } else {
                sessionStorage.setItem("CurrentScreen", "-1");
            }
        }
    }
    async function Enums() {
        setLoading(true)
        var arr = await getEnums();

        if (arr.StatusCode === 0) {

            setApiData({ ...ApiData, ...arr.ResponseObject });

            if (pop !== undefined) {
                pop();
            }
        }
        setLoading(false)

    }

    const checkselect = (event) => {
        event.preventDefault();
        for (const el of document.getElementById('appform').querySelectorAll("[required]")) {
            if (!el.reportValidity()) {
                alert("Please fill all required fields.");
                return;
            }
        }
        submit(event);
    }

    return (
        <>
            <div class="row">
                <div class="column1" style={{ backgroundColor: "var(--primary)" }}>
                    <div style={{ display: "inline-block", textAlign: "left", marginTop: 6 }} id="logoheader">
                        <div style={{ margin: 10 }}>
                            <h5 style={{ fontWeight: "bold", textTransform: "uppercase" }}>Welcome To</h5>
                            <h3 style={{ color: "black" }}>Soneri Digital Onboarding</h3>
                        </div>
                    </div>
                    <div class="sidebanner" style={{ backgroundImage: `url(${CurrenImg})`, height: "80%", width: "100%", backgroundSize: "contain", backgroundRepeat: "no-repeat" }}></div>
                </div>
                <div class="column2">
                    <div style={{ textAlign: "center" }}>
                        {data.selectedAT.BankingType === "Mustaqeem" && history.location.pathname !== "/" ?
                            <img src={Mustaqeem} style={{ marginTop: 10, width: 155, margin: "auto" }} />
                            :
                            <img src={Logo} style={{ marginTop: 10, width: 155, margin: "auto" }} />
                        }
                    </div>
                    {Tabsteps.findIndex(p => p.active === true) > -1 ?
                        <>
                            <h4 style={{ marginTop: 15, fontSize: "1.2em", color: "#646777", display: "inline-block" }}>{data.selectedAT.BankingType} ({data.option.AccountType})</h4>
                            <h4 style={{ marginTop: 15, fontSize: "15px", color: "#646777", display: "inline-block", float: "right" }}>{data.customerName} {(data?.cnic ? "(" + data.cnic + ")" : <></>)}</h4>
                            <Stepper activeStep={Tabsteps.findIndex(p => p.active === true)} connectorStateColors={true} style={{ width: "100%" }} connectorStyleConfig={{ activeColor: "#ffc423", completedColor: "#7c7c7c" }}>
                                {Tabsteps.map((p, i) => (
                                    <Step label={p.Name} key={i} styleConfig={{ activeBgColor: "#ffc423", completedBgColor: "#7c7c7c" }} />
                                ))}
                            </Stepper>

                        </>
                        : null}
                    {enable ?
                        <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => {
                            enablemode(); setenable(false); window.setedit = true;
                        }}>
                            <Edit style={{ marginLeft: -10 }} />
                            Edit
                        </button> : <></>}
                    <Slide direction="right" in={true} mountOnEnter unmountOnExit >
                        <form onSubmit={checkselect} id="appform">
                            <fieldset disabled={enable}>
                                <div className="formdiv">
                                    {children}
                                </div>
                            </fieldset>
                            <Row>
                                {buttons}
                            </Row>
                        </form>
                    </Slide>
                    {sessionStorage.getItem("ReferralId") === null && AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] && window.setedit === false ?
                        <Fab style={{ position: "absolute", width: 100, right: 20, top: "85vh", backgroundColor: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                            onClick={() => history.push("/" + sessionStorage.getItem("CurrentScreen"))}
                        >
                            Proceed
                            <ArrowRight style={{ marginRight: -10 }} />

                        </Fab>

                        : <></>}

                    {sessionStorage.getItem("ReferralId") !== "" && sessionStorage.getItem("ReferralId") !== null && sessionStorage.getItem("ReferralId") !== undefined && AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] && window.setedit === false ?
                        <Fab style={{ position: "absolute", width: 100, right: 20, top: "85vh", backgroundColor: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                            onClick={async () => {
                                setLoading(true);
                                var t = await Finish();
                                setLoading(false);
                                history.push("thankyou");
                            }}
                        >
                            Finished
                            <ArrowRight style={{ marginRight: -10 }} />

                        </Fab>

                        : <></>
                    }
                </div>
            </div>
        </>
    );
}

export default Animatedpage;
