import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox, AttachFile } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { ApiDataContext } from "../Context/Api.context";
import { FunctionsContext } from "../Context/Functions.context";
import { AlertDataContext } from "../Context/Alert.context";
import { AppScreen } from "./screen";
import { CheckAlpha, CheckNumeric, sortArray } from "../service";
import DocB from '../../Assets/Documents/Annexure B - Requirement for Account Opening.pdf'
import Select from 'react-select';
import CustomCheck from "../customComponents/CustomCheck";

const FinalizeInfo2 = () => {
    const { data, setData, setvalue, setmultiple } = useContext(DataContext);
    const { setActive, AddSteps, RemoveSteps, steps } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const { IndividualDetail_III } = useContext(FunctionsContext)
    const { setLoading } = useContext(AlertDataContext);
    const [show, setshow] = useState(false);

    const history = useHistory();
    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);


    const datasubmit = async (e) => {
        // e.preventDefault();
        if (data?.Income === undefined || data?.Income?.length < 1) {
            alert("Source of Income is required.")
            return;
        }

        setLoading(true);
        var resp = await IndividualDetail_III(data, enable);
        setLoading(false);

        if (resp.StatusCode === 0) {
            setApiData({ ...ApiData, Zakatreason: resp.ResponseObject.ExemptionList, TINReasonList: resp.ResponseObject.TINReasonList })

            if (data.Income.findIndex(p => p.value === 2048) === -1) {
                history.push("znt");
            } else {
                history.push("beneficial");
            }
        }
    }
    useEffect(() => {
        setActive("Applicant Profile Information");
        checkifvalueExist();
    }, [])
    const populatemultiopt = (arr) => {
        var s = [];
        arr.map(p => {
            s.push({ value: p.id, label: p.Name });
        })

        return s;
    }
    const setmultiples = (txt, name) => {
        if (txt.findIndex(p => p.value === 2048) !== -1) {
            if (steps.findIndex(p => p.Name === "Fund Provider") === -1) {
                AddSteps("Fund Provider", "Applicant Profile Information")
            }
        } else {
            RemoveSteps("Fund Provider");
        }
        var t = data;
        t[name] = txt;
        setData({ ...t });

    }


    const checkifvalueExist = () => {
        return new Promise((resolve, reject) => {
            if (data.Income !== undefined && data.Income !== "1" && data?.Income.length > 0) {
                var s = [];
                data?.Income.map(p => {
                    if (p.label) {
                        s.push(p);
                    } else {
                        var opt = ApiData.sourceOfIncomeList.filter(o => o.Name === p.trim())[0];
                        if (opt?.Name !== undefined) {
                            // setmultiple({ value: opt.id, label: opt.Name }, "ATMReason")
                            s.push({ value: opt.id, label: opt.Name });
                        }
                    }
                });
                setmultiple(s, "Income")
            }
            resolve();
        })
    }
    useEffect(async () => {
        if (show === true) {
            setLoading(true);
            await checkifvalueExist()
            setLoading(false);
        }
    }, [show])
    return (
        <>

            <Animatedpage enablemode={() => setenable(false)} submit={() => { !enable ? datasubmit() : history.push((data.Income !== undefined && data.Income.findIndex(p => p.value === 2048) !== -1) ? "beneficial" : "znt") }} pop={() => { setshow(true); }}
                buttons={
                    <div className="btndiv">
                        <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                            history.push("otherinfo")
                        }>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>
                            {window.setedit === true ? "Update" : "Next"}
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                }
            >
                {show === true ?
                    <Grid item xs={12} id="s1q2">
                        <Row>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q">Zakat Exemption</h1>
                                <select className="inputtxt" name="Zakat" onChange={(txt) => setvalue(txt)} required
                                    disabled={(data.currency !== "1" && data.currency !== "PKR") || (data.type === "1" || data.type === "Current") ? true : false}
                                >
                                    <option value="">Select</option>

                                    <option value={"0"} selected={data.Zakat !== undefined && data.Zakat === "0"}>No</option>
                                    <option value={"1"} selected={data.Zakat !== undefined && data.Zakat === "1"}>Yes</option>
                                </select>
                                <p className="Qhelper2"></p>
                            </div>
                            {data.Zakat === "1" ?
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                    <h1 className="Q">Reason For Exemption</h1>
                                    <select className="inputtxt" name="Zakatreason" onChange={(txt) => setvalue(txt)} required
                                    //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                    >
                                        <option value="">Select</option>

                                        {ApiData.ExemptionList.map(p => (
                                            <option value={p.id} selected={data.Zakatreason !== undefined && (data.Zakatreason === p.id || data.Zakatreason === ("" + p.id + "") || data.Zakatreason === p.Name)}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>

                                    {(data.Zakatreason === "2") ?
                                        <>
                                            <CustomCheck list={ApiData.CheckBoxList} Name={"ZNT_NonMuslim_1"} setvalue={setvalue} required={true} />
                                        </> :
                                        <>
                                            <CustomCheck list={ApiData.CheckBoxList} Name={"ZNT_Fiqqah_1"} setvalue={setvalue} required={true} />
                                            <div>
                                                <div className="">

                                                    <div>
                                                        {AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] && data.declarationCZ ?
                                                            <>
                                                                <h1 className="Q">Attach Zakat Declaration CZ-50</h1>
                                                                <div className="btn"
                                                                    onClick={() => { document.querySelector("input[name='declarationCZ']").click(); }}
                                                                    style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                                    {data.declarationCZ ? data.declarationCZ[0]?.name : "Change File"}
                                                                </div>
                                                                <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" id="declarationCZ" name="declarationCZ" multiple={false} onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                                                <p className="Qhelper2">Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                                            </>
                                                            :
                                                            <>
                                                                <h1 className="Q">Attach Zakat Declaration CZ-50</h1>
                                                                <input type={"file"} multiple={false} id="declarationCZ" name="declarationCZ" onChange={(txt) => setvalue(txt)} required />
                                                                <p className="Qhelper2"></p>
                                                            </>}
                                                    </div>
                                                </div>

                                            </div>
                                        </>
                                    }
                                </div> : <></>}
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> / 3</p> */}
                                <h1 className="Q">Occupation (<a href={DocB} target={"_blank"} download>Details</a>)</h1>
                                <select className="inputtxt" type="text" name="Occupation" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>

                                    {ApiData.OccupationList.map(p => (
                                        <option value={p.id} selected={data.Occupation !== undefined && parseInt(data.Occupation) === p.id || data.Occupation === p.Name ? true : false}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2">Select the appropriate/relevant as per available documentary evidence.</p>
                            </div>
                            <br />
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 3</p> */}
                                <h1 className="Q">Source of Income</h1>
                                {/* <select className="inputtxt" type="text" name="Income" onChange={(txt) => {
                                        setvalue(txt)
                                        if (txt.target.value === "12") {
                                            AddSteps("Fund Provider", "Individual Information (Applicant - 1)")
                                        }
                                    }} required>
                                        <option value="">Select</option>

                                        {ApiData.sourceOfIncomeList.map(p => (
                                            <option value={p.id}>{p.Name}</option>
                                        ))}
                                    </select> */}
                                <Select
                                    defaultValue={data.Income}
                                    isMulti
                                    name="Income"
                                    options={populatemultiopt(ApiData.sourceOfIncomeList)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(txt) => setmultiples(txt, "Income")}

                                />
                                <p className="Qhelper2">Select the appropriate/relevant as per available documentary evidence</p>
                            </div>
                            <>
                                <CustomCheck list={ApiData.CheckBoxList} Name={"FINALIZE_Occupation_1"} setvalue={setvalue} required={true} />
                            </>


                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> / 3</p> */}
                                <h1 className="Q">Nature of Occupation/Business</h1>
                                <select className="inputtxt" type="text" name="NatureOccupation" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>

                                    {ApiData.NatureOccupationList.map(p => (
                                        <option value={p.id} selected={data.NatureOccupation !== undefined && parseInt(data.NatureOccupation) === p.id || data.NatureOccupation === p.Name ? true : false}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2">Select the appropriate/relevant as per available documentary evidence.</p>
                            </div>



                            {/* || data?.Income?.length > 1 */}
                            {data?.Income !== "1" && data?.Income?.findIndex(p => p.value === 2048) === -1 || data?.Income?.length > 1 ?
                                <>
                                    <div style={{ marginTop: 20 }}>
                                        {AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] ?
                                            <>
                                                <h1 className="Q">Attach Source of Income Doc</h1>
                                                <div className="btn"
                                                    onClick={() => { document.querySelector("input[name='incomereceipt']").click(); }}
                                                    style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                    {data.incomereceipt ? data.incomereceipt[0]?.name : "Change File"}
                                                </div>
                                                <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="incomereceipt" multiple={false} onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                                <p className="Qhelper2">Please attach documentary evidence(s) as per above selected Occupation/Source of Income.</p>
                                            </>
                                            :
                                            <>
                                                <h1 className="Q">Attach Source of Income Doc</h1>
                                                <input className="inputtxt" type="file" name="incomereceipt" id="incomereceipt" multiple={true} onChange={(txt) => setvalue(txt)} />
                                                <p className="Qhelper2">Please attach documentary evidence(s) as per above selected Occupation/Source of Income.</p>
                                            </>}
                                    </div>
                                    <CustomCheck list={ApiData.CheckBoxList} Name={"ID_Check_2"} setvalue={setvalue} required={true} />
                                    <div style={{ marginTop: 20 }}>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                        <h1 className="Q">Employer/Business Name</h1>
                                        <input className="inputtxt" name="employeer" value={data.employeer} onChange={(txt) => setvalue(txt)} />
                                        <p className="Qhelper2"></p>
                                    </div>
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                        <h1 className="Q">Office Province</h1>
                                        <select className="inputtxt" name="OfficeProvince" value={data.OfficeProvince} onChange={(txt) => setvalue(txt)} >
                                            <option value={""}>Select</option>
                                            {sortArray(ApiData.CityList, "Province").map(p => (
                                                <option value={p.Province} selected={data.OfficeProvince !== undefined && data.OfficeProvince === p.Province ? true : false}>{p.Province}</option>
                                            ))}
                                        </select>
                                        <p className="Qhelper2"></p>
                                    </div>
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                        <h1 className="Q">Office City</h1>
                                        <select className="inputtxt" name="OfficeCity" value={data.OfficeCity} onChange={(txt) => setvalue(txt)} >
                                            <option value={""}>Select</option>
                                            {ApiData?.CityList !==undefined && data.OfficeProvince !== undefined && data.OfficeProvince !== "" ? sortArray(ApiData.CityList.filter(p => p.Province === data.OfficeProvince)[0]?.Cities, "abc").map(p => (
                                                <option value={p} selected={data.OfficeCity !== undefined && data.OfficeCity === p ? true : false}>{p}</option>
                                            )) : <></>}
                                        </select>
                                        <p className="Qhelper2"></p>
                                    </div>
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                        <h1 className="Q">Office Address</h1>
                                        <input className="inputtxt" name="officeaddress" value={data.officeaddress} onChange={(txt) => setvalue(txt)} />
                                        <p className="Qhelper2">House no., Street no, Area, Sector etc.</p>
                                    </div>
                                    <div>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                        <h1 className="Q">Office Contact Number</h1>
                                        <div class="uk-width-1-2@s input-group">
                                            <div class="input-group-append">
                                                <div class="input-group-text">
                                                    +92
                                                </div>
                                            </div>
                                            <input class="inputtxt" type="tel" maxLength={13} minLength={9} placeholder="" style={{ width: "93.3%", borderLeft: "none !important" }} value={data.officenumber} name="officenumber" onChange={(txt) => CheckNumeric(txt, () => {
                                                var fl = txt.target.value.charAt(0);
                                                if (fl == 0) {
                                                    txt.target.value = txt.target.value.slice(0, -1)
                                                }
                                                setvalue(txt)
                                            }, () => { })} />

                                        </div>

                                        <p className="Qhelper2">Example +92-3001111111</p>
                                    </div>
                                </>
                                : <></>
                            }

                        </Row>
                    </Grid>
                    : <></>}
            </Animatedpage>
        </>
    )

}
export default FinalizeInfo2;