import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox, CheckBoxOutlineBlankRounded, EmojiObjects } from "@material-ui/icons";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import DocCMustaqeem from '../../Assets/Documents/Annexure C - Digital Account Account KFS Mustaqeem.pdf'
import DocC from '../../Assets/Documents/Annexure C - Digital Account Account KFS.pdf'
import AAC from '../../Assets/Documents/T and C AOF Asaan Account Conventional.pdf'
import AAI from '../../Assets/Documents/T and C AOF Asaan Remittance Mustaqeem.pdf'
import ARC from '../../Assets/Documents/T and C AOF Asaan Remittance Conventional.pdf'
import ARM from '../../Assets/Documents/T and C AOF Asaan Remittance Mustaqeem.pdf'
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import Check from '../../Assets/ready.svg'
import requestOnLivness from "fas-web-ui-components/requestModule"
import CustomCheck from "../customComponents/CustomCheck";
import { ApiDataContext } from "../Context/Api.context";
import Bulb from '../../Assets/bulb.png';
import AOFM from '../../Assets/Documents/T and C Account Opening Mustaqeem (Other than Asaan).pdf'
import AOFC from '../../Assets/Documents/T and C Account Opening Conventional (Other than Asaan).pdf'
const BasicDetails = () => {
    const { setActive, setComplete } = useContext(StepsContext);
    const { setLoading } = useContext(AlertDataContext)
    const { ApiData, setApiData } = useContext(ApiDataContext)

    const { LivenessConsent, Liveness } = useContext(FunctionsContext)
    const [countdown, setcountdown] = useState(60);
    const history = useHistory();
    const { data, setData, setvalue } = useContext(DataContext);
    const [selfie, setSelfie] = useState(false)
    const [text, setText] = useState("")
    const [obj, setobj] = useState({});
    const [base64img, setbase64img] = useState("");
    const camera = React.createRef();
    const [Layout, setLayout] = useState(false)
    const [webcam, setwebcam] = useState(true);
    useEffect(() => {
        setActive("Liveness Test");
        let event = new Event("openCamera");
        camera.current.dispatchEvent(event)
        camera.current.addEventListener("capture", handleCapture);

    }, [])
    const OpenCamera = () => {
        let event = new Event("openCamera");
        camera.current.dispatchEvent(event)
        camera.current.addEventListener("capture", handleCapture);

        navigator.getMedia = (navigator.getUserMedia || // use the proper vendor prefix
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia);

        navigator.getMedia({ video: true }, function () {
            setwebcam(true);
        }, function () {
            setwebcam(false);
        });
    }


    const handleCapture = async event => {
        const photos = event.detail.photos;
        
        setText("");
        var base64 = await blobToBase64(photos[0]);

        setLoading(true);
        var t = await Liveness(base64);
        if (t.StatusCode === 0) {
            // setText(t.ResponseObject.error);
            // setobj(t.ResponseObject);
            // if (parseFloat(t.ResponseObject.probability).toFixed(2) > 0.6) {
                setSelfie(true)
                setbase64img(base64);
            // } else {
            //     setSelfie(false)
            // }

        }
        setLoading(false);
        let events = new Event("openCamera");
        camera.current.dispatchEvent(events)
    };

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }
    const checkotp = async (event) => {
        //event.preventDefault();

        // if (!selfie) {
        //     alert("Liveness Test is required");
        //     return;
        // }
        setLoading(true)
        let eve = new Event("closeCamera");
        camera.current.dispatchEvent(eve)
        var a = await LivenessConsent(data);
        if (a.StatusCode === 0) {

            history.push("cnicverification")
        }
        setLoading(false)
    }


    return (
        <Animatedpage submit={checkotp} pop={() => setLayout(true)}>

            <Grid item xs={12} id="s1q2">
                <Row>
                    <h3 className="Q">Photo requirements</h3>
                    <h3 className="Q">Your photos must be:</h3>
                    <ul style={{ marginBottom: 10 }}>
                        <li>Clear, sharp and in focus</li>
                        <li>Taken with a neutral facial expression</li>
                        <li>Eyes open and clearly visible</li>
                        <li>Mouth closed, neutral expression</li>
                    </ul>
                    <h3 className="Q">Place your face in the frame and look towards camera.</h3>
                    <camera-component
                        url_logo="../blue.png"
                        is_multiframe={"false"}
                        disable_control_panel={true}
                        timer={false}
                        show_mask={"false"}
                        background_color="#f7f8fa"
                        ref={camera}
                        face_detection={true}
                        model_path="/models/"
                    />
                    {text !== "" && !selfie ?
                        <table style={{ display: "inline-block" }}>
                            <thead>
                                <tr>
                                    <th>Probability</th>
                                    <th>Quality</th>
                                    <th>Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{parseFloat(obj.probability).toFixed(2)}</td>
                                    <td>{parseFloat(obj.quality).toFixed(2)}</td>
                                    <td>{parseFloat(obj.score).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                        : <></>}
                    {
                        selfie ? <>
                            <br />
                            <img src={base64img} style={{ width: 200, height: 150 }} />
                            <br />
                            <img src={Check} style={{ width: 50 }} />
                            <label className="Q">Liveness Test Successful</label>
                            <br />
                        </> : <>
                            {text !== "" ?
                                <>
                                    <h3 className="Q" style={{ color: "red" }}>Please try again.</h3>
                                    <h3 className="Q" style={{ color: "red" }}>Note: {text}</h3>
                                    <button type="button" style={{ margin: 10, background: "var(--light)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => {
                                            let event = new Event("openCamera");
                                            camera.current.dispatchEvent(event);
                                        }}>Try Again</button>
                                    <button type="button"
                                        style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => { let event = new Event("takePhoto"); camera.current.dispatchEvent(event) }}>Capture</button>
                                </>
                                : webcam === true ? <>
                                    <button type="button"
                                        style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => {
                                            let event = new Event("openCamera");
                                            camera.current.dispatchEvent(event)
                                            camera.current.addEventListener("capture", handleCapture);
                                        }}>Open Camera</button>
                                    <button type="button"
                                        style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                        onClick={() => { let event = new Event("takePhoto"); camera.current.dispatchEvent(event) }}>Capture</button>
                                </> : <div>
                                    <img src={Bulb} style={{ width: 30, height: 30, marginRight: 10, marginBottom: 30, display: "inline-block" }} />
                                    <div
                                        style={{ margin: 10, border: "none", borderRadius: 10, marginTop: 20, color: "#000", padding: 10, display: "inline-block", fontSize: 15, width: "90%" }}
                                    >
                                        Please enable/turn on your primary camera.<br />
                                        Please allow this webpage access to your camera from your browser's settings.
                                    </div>
                                </div>}
                        </>
                    }



                    {/*                     
                    <h3>This option is only for UAT</h3>
                    <button type="button"
                        style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }}
                        onClick={() => {setSelfie(true)}}>Ignore</button> */}


                    {Layout ?
                        <>
                            <div style={{ marginTop: 20 }}>
                                <h3 style={{ margin: 20, textAlign: "center" }}>Terms And Conditions And KYC</h3>
                                <ol style={{ listStyle: "none" }}>
                                    <li style={{ marginBottom: 10 }}>
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"Liveness_Check1_2"} setvalue={setvalue} required={true} />
                                    </li>
                                    <li style={{ marginBottom: 10 }}>
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"Liveness_Check2_2"} setvalue={setvalue} required={true} />
                                    </li>
                                    <li style={{ marginBottom: 10 }}>
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"Liveness_Check3_2"} setvalue={setvalue} required={true} />
                                    </li>
                                    <li style={{ marginBottom: 10 }}>
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"Liveness_Check4_2"} setvalue={setvalue} required={true} />
                                    </li>
                                    <li style={{ marginBottom: 10 }}>
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"Liveness_Check5_2"} setvalue={setvalue} required={true} /><a
                                            href={data.selectedAT.id === "1" || data.selectedAT.id === 1 ?
                                                (data.option.Id === "1" ? AAC : data.option.Id === "2" ? ARC : AOFC) :
                                                (data.option.Id === "2" ? AAI : data.option.Id === "2" ? ARM : AOFM)
                                            }
                                            target="_blank"
                                        >Terms and Conditions</a></li>
                                    <li style={{ marginBottom: 10 }}>
                                        <CustomCheck list={ApiData.CheckBoxList} Name={"Liveness_Check6_2"} setvalue={setvalue} required={true} />
                                        <a href={data.selectedAT.id === "1" || data.selectedAT.id === 1 ? DocC : DocCMustaqeem} target="_blank">Key Fact Statement</a>
                                    </li>

                                </ol>
                            </div>

                        </> : <></>
                    }

                    <div className="btndiv">
                        {/* <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("cnc")}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button> */}
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Submit
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                </Row>
            </Grid>

        </Animatedpage >
    )

}
export default BasicDetails;