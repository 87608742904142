function postRequest(endpoint, data) {
    return new Promise((resolve, reject) => {
        
        fetch(endpoint,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: data !== null ? JSON.stringify(data) : ""
            }
        ).then((res) => res.json().then((resp) => {
            if (resp.StatusCode === 2 || resp.StatusCode === 12) {
                window.location.href = "/admin/login";
                resolve();
            } else {
                resolve(resp);
            }
        })).catch(err => {
            
        })
    })
}

export {
    postRequest
}