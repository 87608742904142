import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory, useLocation } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import Playstore from '../../Assets/playstore.webp'
import Appstore from '../../Assets/appstore.jpg'
const Thankyou = () => {
    const history = useHistory();
    const { setActive, setComplete } = useContext(StepsContext);
    const { data } = useContext(DataContext);
    const d = new Date();
    const location = useLocation();

    useEffect(() => {
        setActive("Form Submit");
        localStorage.removeItem("dataCon")
        localStorage.removeItem("ExpiryTime")
    }, [])
    return (
        <Animatedpage>
            <Grid item xs={12} id="s1q2" style={{ marginTop: "5vh" }}>
                <Row>
                    <div>
                        <h1 className="Q">Thank you</h1>
                        <p className="Qhelper">You application has been submitted for Account Opening at Soneri Bank<br /> Kindly download our Digital Application from Google / Apple store to perform Biometric verification. Your request will be processed within 02 working days after completion of Biometric Verification.</p>
                        <a href=" https://play.google.com/store/apps/details?id=com.p3.soneridigital" target="_blank">
                            <img src={Playstore} style={{ width: 200, marginTop: 20, marginRight: 10 }} />
                        </a>
                        <a href="https://apps.apple.com/pk/app/soneri-digital/id1575323634" target="_blank">
                            <img src={Appstore} style={{ width: 200, marginTop: 20 }} />
                        </a>
                        {location?.state?.Ref !== null && location?.state?.Ref !== undefined ? <p className="Qhelper">Your request reference number is {location.state.Ref}.</p> : <></>}
                    </div>

                </Row>
            </Grid>
        </Animatedpage>
    )

}
export default Thankyou;