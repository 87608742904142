import React, { useContext } from "react";
import { useEffect } from "react";
import { DataContext } from "../Context/Data.Context";



const CustomCheck = ({ text, list, Name, required, setvalue, disable = false }) => {
    const val = list.filter(p => p.Name === Name)[0];
    const leb = React.createRef();
    const { data } = useContext(DataContext);
    useEffect(()=>{
        if(leb.current!==null){
            leb.current.innerHTML=val.Discription;

        }

    },[leb])
    return (<>
        <label for={Name} className="" style={{ marginBottom: 20 }}>
            <input type="checkbox" id={Name} name={Name} checked={data[Name] !== undefined && data[Name] !== null && data[Name] !== "" && data[Name] !== "None" ? true : false} value={val.id} onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} required={required} disabled={disable} />
            {/* {val.Discription.split("<br />").map(p => (
                <>
                    {p}
                    <br />
                </>
            ))} */}
            <div ref={leb} style={{display:"initial"}}>
            </div>
        </label>
    </>);

}

export default CustomCheck;