import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Main from '../../Assets/MainC.jpg'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { ApiDataContext } from "../Context/Api.context";
import { getProductType } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { AppScreen } from "./screen";
import { FunctionsContext } from "../Context/Functions.context";
const AccountOption = () => {
    const { data, setData } = useContext(DataContext);
    const { setActive, setComplete, AddSteps } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const { DynamicModelShow, setLoading, showError } = useContext(AlertDataContext)
    const { VerifyDetail } = useContext(FunctionsContext)

    const [options, setoptions] = useState([])
    const [sel, setsel] = useState(false)
    const history = useHistory();
    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);

    useEffect(() => {
        setActive("Account Type");
        setData({ ...data, SideImage: Main });
        async function getProduct() {
            if (ApiData.Producttype === undefined) {
                var arr = await getProductType();
                
                setoptions([...arr.ResponseObject]);
                setApiData({ ...ApiData, Producttype: arr.ResponseObject });
                // setData({ ...data, option: arr[0].AccountType })
            } else {
                setoptions([...ApiData.Producttype]);
            }
        }
        getProduct();
    }, [])
    useEffect(() => {
        setsel(data.option?.AccountType ? false : true);

    }, [data]);




    const showFDAModel = (data) => {
        var t = <>
            <ul>
                {data.LongDescription.map(o => (
                    <li>{o}</li>
                ))}
            </ul>
            {data.Note !== "" ?
                <p className="Q">Note:{data.Note}</p>
                : <></>}
        </>;
        DynamicModelShow(data.AccountType + " Facilities", t, true);
    }
    return (
        <Animatedpage enablemode={() => setenable(false)} submit={async () => {
            if (!sel) {
                //setComplete("Banking Type");
                if (window.setedit) {
                    var resp = await VerifyDetail(data, enable);
                    setLoading(false);
                    if (resp.StatusCode === 0) {
                        history.push("otherdetails")
                    } else {
                        showError("Some Error occurs. Please check your details");
                    }
                } else {
                    history.push("otherdetails")
                }
            } else {
                alert("Please select a account type.");
            }
        }}
            buttons={
                <>
                    <button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("/")}>
                        <ArrowLeft style={{ marginLeft: -10 }} />
                        Back
                    </button>
                    <button variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                        disabled={sel}
                    >{window.setedit === true ? "Update" : "Next"}
                        <ArrowRight style={{ marginRight: -10 }} />
                    </button>
                </>
            }
        >

            <Grid item xs={12} id="s1q2">
                <Row>

                    {/* <p style={{ marginTop: 30 }} className="Qhelper" >Question <b>2</b></p> */}
                    <h1 className="Q" style={{ fontSize: 20, marginTop: 20 }}>Select your preferred account type</h1>
                    {options.map(p => (
                        <div key={p.Id} className={"Carddiv" + (data.option.Id === p.Id ? " Active" : "")} onClick={() => {

                            
                            if (p.AccountType === "Asaan Digital Remittance") {
                                AddSteps("Remitter Details", "FATCA & CRS Details");

                            }
                            if (!enable) {
                                if (p.AccountType === "Freelancer Account") {
                                    setData({ ...data, option: { ...p } })
                                } else {
                                    setData({ ...data, option: { ...p } })
                                }
                            }
                            showFDAModel(p);
                            setsel(false);
                        }}>
                            {data.option.Id === p.Id ? <CheckBox className="CardCheck" style={{ color: "var(--light)" }} /> : null}
                            <p className="AccountTypeName" style={{ fontWeight: "bold" }}>{p.AccountType}</p>
                            <ul>
                                {p.ShortDescription.map(s => (
                                    <li>{s}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                    <br />


                </Row>
            </Grid>
        </Animatedpage>
    )

}
export default AccountOption;