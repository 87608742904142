import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Layout } from './Layout';
import { Home } from './Home';
import AccountType from './Questions/Accounttype';
import AccountOptions from './Questions/AccountOptions';
import AccountOtherdetails from './Questions/AccountOtherdetails';
import Basicdetails from './Questions/Basicdetails';
import OtpVerification from './Questions/OtpEmailVerification';
import IndividualInformation from './Questions/IndividualInformation';
import FinalizeInfo from './Questions/FinalizeInfo';
import FinalizeInfop2 from './Questions/FinalizeInfop2';
import Thankyou from './Questions/Thankyou';
import BeneficialInfo from './Questions/BeneficialInfo';
import ZakatNTrans from './Questions/ZakatNTrans';
import CNC from './Questions/CheckNCard';
import RemitterInfo from './Questions/RemitterInfo';
import Liveness from './Questions/Liveness';
import CNICVerification from './Questions/CNICVerification';
import Download from './Questions/Download';
import Continueotp from './Questions/OtpVerification';
import NotFound from './Questions/404';
import { Config } from '../congig';


//import '../custom.css'
import { DataContextProvider } from './Context/Data.Context';
import { StepsContextProvider } from './Context/Steps.Context';
import { ApiDataContextProvider } from './Context/Api.context';
import { AlertDataContextProvider } from './Context/Alert.context';
import { FunctionsContextProvider } from './Context/Functions.context';
import Verify from './Questions/Verify';

export default class ClientSide extends Component {

  componentDidMount() {
    window.onclose = () => {
      window.localStorage.clear();
    }

  }


  componentWillMount() {
    if (Config.ADMIN === false) {
      require('../custom.css');
    }
  }

  render() {
    return (
      <>
        <ApiDataContextProvider>
          <DataContextProvider>
            <StepsContextProvider>
              <AlertDataContextProvider>
                <FunctionsContextProvider>
                  <Layout>
                    <Switch>

                      {/* <Route exact path='*' component={NotFound} /> */}
                      {/* <Redirect exact from='/' to='/Digitalonboarding' />
                      <Redirect exact from='/accountoption' to='/Digitalonboarding/accountoption'/>
                      <Redirect exact from='/otherdetails' to='/Digitalonboarding/otherdetails'/>
                      <Redirect exact from='/basicdetails' to='/Digitalonboarding/basicdetails'/>
                      <Redirect exact from='/otp' to='/Digitalonboarding/otp'/>
                      <Redirect exact from='/individual' to='/Digitalonboarding/individual'/>
                      <Redirect exact from='/otherinfo' to='/Digitalonboarding/otherinfo'/>
                      <Redirect exact from='/finalize' to='/Digitalonboarding/finalize'/>
                      <Redirect exact from='/beneficial' to='/Digitalonboarding/beneficial'/>
                      <Redirect exact from='/znt' to='/Digitalonboarding/znt'/>
                      <Redirect exact from='/cnc' to='/Digitalonboarding/cnc'/>
                      <Redirect exact from='/remitter' to='/Digitalonboarding/remitter'/>
                      <Redirect exact from='/liveness' to='/Digitalonboarding/liveness'/>
                      <Redirect exact from='/cnicverification' to='/Digitalonboarding/cnicverification'/>
                      <Redirect exact from='/downloads' to='/Digitalonboarding/downloads'/>
                      <Redirect exact from='/continueotp' to='/Digitalonboarding/continueotp'/>
                      <Redirect exact from='/thankyou' to='/Digitalonboarding/thankyou'/>
                      <Redirect exact from='/verify' to='/Digitalonboarding/verify'/> */}


                      <Route exact path='/' component={AccountType} />
                      <Route exact path='/accountoption' component={AccountOptions} />
                      <Route exact path='/otherdetails' component={AccountOtherdetails} />
                      <Route exact path='/basicdetails' component={Basicdetails} />
                      <Route exact path='/otp' component={OtpVerification} />
                      <Route exact path='/individual' component={IndividualInformation} />
                      <Route exact path='/otherinfo' component={FinalizeInfo} />
                      <Route exact path='/finalize' component={FinalizeInfop2} />
                      <Route exact path='/beneficial' component={BeneficialInfo} />
                      <Route exact path='/znt' component={ZakatNTrans} />
                      <Route exact path='/cnc' component={CNC} />
                      <Route exact path='/remitter' component={RemitterInfo} />
                      <Route exact path='/liveness' component={Liveness} />
                      <Route exact path='/cnicverification' component={CNICVerification} />
                      <Route exact path='/downloads' component={Download} />
                      <Route exact path='/continueotp' component={Continueotp} />
                      <Route exact path='/thankyou' component={Thankyou} />
                      <Route exact path='/verify' component={Verify} />

                    </Switch>

                  </Layout>
                </FunctionsContextProvider>
              </AlertDataContextProvider>
            </StepsContextProvider>
          </DataContextProvider>
        </ApiDataContextProvider>
      </>
    );
  }
}
