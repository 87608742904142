import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import { AppScreen } from "./screen";
import { CheckAlpha, CheckNumeric } from "../service";

const RemitterInfo = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, steps, AddSteps } = useContext(StepsContext);

    const { showInfo, showError, setLoading } = useContext(AlertDataContext);
    const { Remitter } = useContext(FunctionsContext)
    const history = useHistory();
    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);


    useEffect(() => {
        
        AddSteps("Remitter Details", "Zakat & FATCA Status");
        setActive("Remitter Details");

    }, [])

    const datasubmit = async (e) => {
        //e.preventDefault();
        setLoading(true);
        var resp = await Remitter(data, enable);
        setLoading(false);

        if (resp.StatusCode === 0) {
            history.push("cnc")
        } else {
            showError("Some Error occurs. Please check your details");
        }
    }
    return (
        <Animatedpage enablemode={() => setenable(false)} submit={() => { !enable ? datasubmit() : history.push("cnc") }}
            buttons={

                <div className="btndiv">
                    <button variant="contained" variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                        history.push("znt")
                    }>
                        <ArrowLeft style={{ marginLeft: -10 }} />
                        Back
                    </button>
                    <button type="submit" variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>
                        {window.setedit === true ? "Update" : "Next"}
                        <ArrowRight style={{ marginRight: -10 }} />
                    </button>
                </div>
            }
        >
            <Grid item xs={12} id="s1q2">
                <Row>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                        <h1 className="Q">Name</h1>

                        <input className="inputtxt" type={"text"} name="remitterName" value={data.remitterName} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                        <p className="Qhelper2">Please mention the name of person who will be prospective remitter</p>
                    </div>

                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                        <h1 className="Q">Relationship</h1>

                        <input className="inputtxt" type={"text"} name="remitterRelation" value={data.remitterRelation} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                        <p className="Qhelper2">Please mention the relation with prospective remitter</p>
                    </div>

                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                        <h1 className="Q">Legal Identification No.</h1>

                        <input className="inputtxt" type={"text"} name="remitterlegalID" value={data.remitterlegalID} onChange={(txt) => setvalue(txt)} maxLength={25} required />
                        <p className="Qhelper2">Please mention the legal ID of prospective remitter</p>
                    </div>

                </Row>
            </Grid>
        </Animatedpage>
    );
}

export default RemitterInfo;