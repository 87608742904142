const AppScreen = {
    "-1": -1,
    "/": -1,
    "": -1,
    "/Digitalonboarding": -1,
    accountoption: 0,
    otherdetails: 0,
    basicdetails: 1,
    otp: 2,
    individual: 3,
    otherinfo: 4,
    finalize: 5,
    beneficial: 6,
    znt: 7,
    remitter: 8,
    cnc: 9,
    liveness: 10,
    cnicverification: 11,
    thankyou: 12
}

export {
    AppScreen
}