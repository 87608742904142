import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import { ContineuResentOtp, ResentOtp, VerifyContinueOTP, VerifyOtp } from "../service";
import { AlertDataContext } from "../Context/Alert.context";
import { ApiDataContext } from "../Context/Api.context";


const BasicDetails = ({ route }) => {
    const { data, Bindata, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading } = useContext(AlertDataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)

    const [otp, setotp] = useState("")
    const [countdown, setcountdown] = useState(60);
    const history = useHistory();
    const [mnumber, setmnumber] = useState("");
    const [email, setemail] = useState("");
    useEffect(() => {
        setActive("Applicant Basic Details & OTP");
        setLoading(true);
        if (history.location.state?.phone) {
            async function a() {
                setmnumber(history.location.state.phone);
                setemail(history.location.state.email);
                var t = await ContineuResentOtp(data);
            }
            a();
        } else {
            history.replace("/");
        }
        setLoading(false);
    }, [])
    useEffect(() => {
        countdowntimer('clock', 3, 0);

    }, []);

    function countdowntimer(element, minutes, seconds) {
        var time = minutes * 60 + seconds;
        var interval = setInterval(function () {
            var el = document.getElementById("element");
            if (time == 0) {
                clearInterval(interval);
                var t = document.createElement("p");
                t.onclick = function () { resend(); countdowntimer('clock', 3, 0); };
                t.classList.add("Qhelper");
                t.style.textAlign = "center";
                t.style.backgroundColor = "var(--secondary)";
                t.style.color = "white";
                t.style.width = "110px";
                t.style.padding = "10px";
                t.style.cursor = "pointer";
                t.innerText = "Re-sent OTP";
                if (el !== null && el !== undefined) {
                    el.innerHTML = "";
                    el.appendChild(t);
                }
                //el.innerHTML = "<p onClick={() => { resend(); countdown('clock', 3, 0); }} className='Qhelper' style={{ textAlign: 'center', marginBottom: 10, background: 'var(--secondary)', border: 'none', borderRadius: 10, marginTop: 0, width: 110, cursor: 'pointer', padding: 10, color: 'white' }}>Resend OTP</p>";
                // setTimeout(function () {
                //     countdowntimer('clock', 3, 0);
                // }, 2000);
            }
            var minutes = Math.floor(time / 60);
            if (minutes < 10) minutes = "0" + minutes;
            var seconds = time % 60;
            if (seconds < 10) seconds = "0" + seconds;
            var text = "OTP expire in " + minutes + ':' + seconds;
            if (time > 0) {
                el.innerHTML = text;
            }
            time--;

        }, 1000);
    }
    const datasubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);
        var resp = await VerifyContinueOTP(data.cnic, otp);


        if (resp.StatusCode === 0) {
            var o = await Bindata(resp.ResponseObject);
            history.replace("/" + resp.ResponseObject.currentScreen);
        } else {
            showError("Some Error occurs. Please check your details");
        }
        setLoading(false);
    }

    const resend = async () => {

        setLoading(true);
        var resp = await ResentOtp(data);
        setLoading(false);
        countdowntimer('clock', 3, 0);
    }
    return (
        <Animatedpage submit={datasubmit}
            buttons={
                <div className="btndiv">
                    <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("basicdetails")}>
                        <ArrowLeft style={{ marginLeft: -10 }} />
                        Back
                    </button>
                    <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                        <ArrowRight style={{ marginRight: -10 }} />
                    </button>
                </div>
            }>
            <Grid item xs={12} id="s1q2">
                <Row>
                    <div>
                        <p className="Qhelper">Phone Number : <b>{mnumber}</b></p>
                        <p className="Qhelper">Email : <b>{email}</b></p>
                    </div>
                    <div>
                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> / 1</p> */}
                        <p className="Qhelper" style={{ fontWeight: "bold" }}>Enter the OTP code sent to you mobile number.</p>
                        <input className="inputtxt" type={"text"} maxLength={4} minLength={4} name="otp" onChange={(txt) => setotp(txt.target.value)} required placeholder="Mobile OTP here" />
                        {/* {countdown < 1 ? <p onClick={() => resend()} className="Qhelper" style={{ textAlign: "center", marginBottom: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 0, width: 110, cursor: "pointer", padding: 10, color: "white" }}>Resend OTP</p> : <p className="Qhelper">OTP expire in 00:{countdown < 10 ? "0" + countdown : countdown}</p>} */}
                        <div id="element" style={{ margin: 20 }}></div>

                    </div>
                    <label for="otpchk" className="" style={{ marginBottom: 20 }}>
                        <input type="checkbox" id="otpchk" name="otpchk" onChange={(txt) => setvalue(txt)} style={{ marginRight: 5, width: 18, height: 18 }} required />
                        All correspondence with the customer will be carried out on above-mentioned email address and mobile no. unless changed in the future by the customer.
                    </label >

                </Row>
            </Grid>
        </Animatedpage >
    )

}
export default BasicDetails;