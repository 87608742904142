import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox, PhoneAndroid, AssignmentInd } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory, useLocation } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import "./CNIC.css";
import { FunctionsContext } from "../Context/Functions.context";
import { AlertDataContext } from "../Context/Alert.context";
import { CheckNumeric, ResentOtp, VerifyContinueOTP } from "../service";
import { Config } from "../../congig";
import Modal from '@material-ui/core/Modal';
import CustomCheck from "../customComponents/CustomCheck";
import { ApiDataContext } from "../Context/Api.context";

const BasicDetails = () => {
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading, DynamicModelShow, DynamicModelHide } = useContext(AlertDataContext);
    const { data, setData, setvalue } = useContext(DataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext);

    const [countdown, setcountdown] = useState(60);
    const history = useHistory();
    const [sucess, setSucess] = useState(false)
    const location = useLocation();
    const [otp, setotp] = useState("")
    const [Captcha, setCaptcha] = useState(false)
    const [CNIC, setCNIC] = useState(data?.cnic)
    const [mobile, setmobile] = useState(data?.mnumber)

    const [varify, setVarify] = useState(true)
    const [text, setText] = useState("Record does not exist against CNIC/MSISDN paring on Target CMO.");
    const { CheckCNIC, postRequest } = useContext(FunctionsContext)
    const [allowCNIC, setallowCNIC] = useState(false);
    const [ShowEdit, setShowEdit] = useState(true);
    const [ShowEditMobile, setShowEditMobile] = useState(false);
    const [otpverify, setotpverify] = useState(false);
    const [Showmodel, setShowmodel] = useState(false);
    useEffect(() => {
        setActive("CNIC Verification");
    }, [])

    var callback = function () {

    };
    var verifyCallback = function (response) {

        setCaptcha(true)
    };
    useEffect(async () => {
        //showChangenumberModel();
        var t = await postRequest("api/DigitalAccount/ValidatePhoneAndCNIC", {});
        
        if (t.StatusCode === 0) {
            history.replace("thankyou", {
                Ref: t.ResponseObject.RefNo
            });
        } else {
            setVarify(false)
            setText(t.StatusMessage);
            setallowCNIC(t.ResponseObject.allowCNIC);
            if (t.ResponseObject.allowCNIC) {
                setCNIC(data.cnic);
            }
        }
    }, [])
    const checkotp = (event) => {
        event.preventDefault();
        history.push("thankyou")

    }

    function countdowntimer(element, minutes, seconds) {
        var time = minutes * 60 + seconds;
        var interval = setInterval(function () {
            var el = document.getElementById("element");
            if (time == 0) {
                clearInterval(interval);
                var t = document.createElement("button");
                t.onclick = function () { resend(); countdowntimer('clock', 3, 0); };
                //t.classList.add("Qhelper");
                t.style.textAlign = "center";
                t.style.backgroundColor = "var(--secondary)";
                t.style.color = "white";
                t.style.width = "110px";
                t.style.padding = "10px";
                t.style.cursor = "pointer";
                t.innerText = "Re-sent OTP";
                el.innerHTML = "";
                el.appendChild(t);
                //el.innerHTML = "<p onClick={() => { resend(); countdown('clock', 3, 0); }} className='Qhelper' style={{ textAlign: 'center', marginBottom: 10, background: 'var(--secondary)', border: 'none', borderRadius: 10, marginTop: 0, width: 110, cursor: 'pointer', padding: 10, color: 'white' }}>Resend OTP</p>";
                // setTimeout(function () {
                //     countdowntimer('clock', 3, 0);
                // }, 2000);
            }
            var minutes = Math.floor(time / 60);
            if (minutes < 10) minutes = "0" + minutes;
            var seconds = time % 60;
            if (seconds < 10) seconds = "0" + seconds;
            var text = "OTP expire in " + minutes + ':' + seconds;
            if (time > 0) {
                el.innerHTML = text;
            }
            time--;

        }, 1000);
    }

    const resend = async () => {

        setLoading(true);
        var resp = await ResentOtp(data);
        setLoading(false);
        countdowntimer('clock', 3, 0);
    }
    return (
        <>
            <Animatedpage submit={() => { }}>
                <Grid item xs={12} id="s1q2">
                    <Row>
                        {varify ?
                            <div style={{ textAlign: "center", paddingTop: 30 }}>
                                <h4 style={{ margin: "auto" }}>{!sucess ? "Verifying CNIC-SIM Pairing" : "Verification Successful"}</h4>
                                <div className="containerload">
                                    <div className="loading-bar">
                                        <div className="progress-bar"></div>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div style={{ textAlign: "center", paddingTop: 30 }}>
                                    <h4 style={{ margin: "auto", color: "red" }}>{"Verification Failed"}</h4>
                                    {/*<h3 className="Q" style={{ margin: "auto", marginTop: 10, textAlign: "center" }}>{text}</h3>*/}
                                    {ShowEdit ?
                                        <div
                                            style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20, width: "fit-content", padding: 10, margin: "auto", marginTop: 20, cursor: "pointer" }}
                                            onClick={() => setShowmodel(true)}
                                        >Change CNIC/Mobile No.
                                            <ArrowRight />
                                        </div>
                                        : <></>}
                                </div>
                            </>
                        }
                    </Row>
                </Grid>
            </Animatedpage>
            {Showmodel ?
                <div style={{ position: "absolute", bottom: 10, left: 20 }} >
                    <Modal
                        disablePortal
                        disableEnforceFocus
                        disableAutoFocus
                        open
                        aria-labelledby="server-modal-title"
                        aria-describedby="server-modal-description"
                    // className={classes.modal}
                    // container={() => rootRef.current}
                    >
                        <div className="DynamicModelcls" style={{ width: "55vw", height: "fit-content", background: "white", alignSelf: "center", margin: "auto", marginTop: "2vh", borderRadius: "20px", padding: 20 }}>
                            <div className="dymodelbody" style={{ padding: 30 }}>
                                <>
                                    {/* <h3 style={{ textAlign: "center", color: "red" }}>CHANGE CNIC & MOBILE NUMBER</h3> */}
                                    <h3>Your provided number is not registered with your CNIC, please provide correct information</h3>
                                    <div>
                                        <div style={{ width: "90%" }}>
                                            <div className="inputrow">
                                                <div className="inputcolumn1">
                                                    <AssignmentInd style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                                </div>
                                                <div className="inputcolumn2">
                                                    <h1 className="Q">NADRA issued identity document No.</h1>
                                                    <input className="inputtxt" type={"text"} minLength={data.cnictype === "CNIC" ? 13 : 13} maxLength={data.cnictype === "CNIC" ? 13 : 13} name="cnic" onChange={(txt) => CheckNumeric(txt, () => { setCNIC(txt.target.value) }, () => { })}
                                                        //onBlur={() => CNICFoucusOut()} 
                                                        value={CNIC}
                                                        disabled={allowCNIC && !otpverify ? true : false}
                                                        id="CCNIC"
                                                        required />
                                                    <p className="Qhelper2">Enter ID number without dashes.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginBottom: 30, width: "90%" }}>
                                            <div className="inputrow">
                                                <div className="inputcolumn1">
                                                    <PhoneAndroid style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                                </div>
                                                <div className="inputcolumn2" >
                                                    <h1 className="Q">Cell Number/WhatsApp Number</h1>
                                                    {/* <input className="inputtxt" type="tel" maxLength={13} minLength={13} name="mnumber" value={data.mnumber} onChange={(txt) => setvalue(txt)} required /> */}
                                                    <div class="uk-width-1-2@s input-group">
                                                        <div class="input-group-append">
                                                            <div class="input-group-text">
                                                                +92
                                                            </div>
                                                        </div>
                                                        <input class="inputtxt" type="tel" maxLength={10} minLength={10} placeholder=""
                                                            style={{ width: "92%", borderLeft: "none !important" }} name="mnumber"
                                                            value={data?.mnumber}
                                                            id="CNumber"
                                                            disabled={ShowEditMobile && !otpverify ? true : false}
                                                            onChange={(txt) => CheckNumeric(txt, () => {
                                                                var fl = txt.target.value.charAt(0);
                                                                if (fl == 0) {
                                                                    txt.target.value = txt.target.value.slice(0, -1)
                                                                }
                                                                setvalue(txt)
                                                            }, () => { })} required />
                                                        <p className="Qhelper2" style={{ visibility: "visible" }}>Example +92-3001111111</p>
                                                        <p className="Qhelper2" style={{ visibility: "visible" }}>Note: If your mobile number is ported on another service provider,kindly type “REG” and send SMS to “9293” or contact Soneri Bank: 021111766374 for assistance.</p>
                                                        <CustomCheck list={ApiData.CheckBoxList} Name={"BD_Whatsapp_1"} setvalue={setvalue} required={false} disabled={ShowEditMobile && !otpverify ? true : false} />
                                                        {Config.robot && !otpverify ?
                                                            <Recaptcha

                                                                sitekey="6Ld_88YcAAAAAB179MAic7n2N5tgDn6jHERoYKew"
                                                                render="explicit"
                                                                verifyCallback={verifyCallback}
                                                                onloadCallback={callback}
                                                            /> : null}

                                                    </div>
                                                </div>
                                            </div>

                                            <p className="Qhelper2"></p>
                                        </div>

                                        {otpverify ? <>
                                            <div style={{ marginBottom: 30, marginTop: 20, width: "90%" }}>
                                                <div className="inputrow">
                                                    <div className="inputcolumn1">
                                                        <PhoneAndroid style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                                    </div>
                                                    <div className="inputcolumn2">
                                                        <p className="Qhelper">Enter the OTP code sent to you mobile number.</p>
                                                        <input className="inputtxt" type={"text"} maxLength={4} minLength={4} name="otp" id="OTP" required />
                                                    </div>
                                                </div>
                                                <div id="element" style={{ margin: 20 }}></div>
                                            </div>
                                        </> : <></>}
                                    </div>
                                    {!otpverify ?
                                        <button
                                            style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                            onClick={() => {
                                                async function change() {
                                                    setLoading(true);
                                                    var cn = document.getElementById("CCNIC").value;
                                                    var mnumber = document.getElementById("CNumber").value;
                                                    var t = await postRequest("api/DigitalAccount/CheckNewNumber", { CNIC: cn, Number: mnumber, });
                                                    if (t.StatusCode === 0) {
                                                        setotpverify(true);
                                                        countdowntimer('clock', 3, 0);

                                                    } else {
                                                        alert(t.StatusMessage);

                                                    }
                                                    setLoading(false);
                                                }
                                                change();
                                            }}
                                        >Sent OTP
                                            <ArrowRight />
                                        </button>
                                        :
                                        <button
                                            style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                                            onClick={() => {
                                                async function change() {
                                                    setLoading(true);
                                                    var cn = document.getElementById("CCNIC").value;
                                                    var mnumber = document.getElementById("CNumber").value;
                                                    var OTP = document.getElementById("OTP").value;
                                                    var resp = await VerifyContinueOTP(data.cnic, OTP);
                                                    if (resp.StatusCode === 0) {

                                                        var t = await postRequest("api/DigitalAccount/ChangeCNICNumber", { CNIC: cn, Number: "92" + mnumber, "MobileNumberConsent": data.BD_Whatsapp_1 !== "" && data.BD_Whatsapp_1 !== "None" && data.BD_Whatsapp_1 !== undefined ? ApiData.CheckBoxList.filter(p => p.Name === "BD_Whatsapp_1")[0].id : 0 });
                                                        
                                                        if (t.StatusCode === 0) {
                                                            history.replace("thankyou", {
                                                                Ref: t.ResponseObject.RefNo
                                                            });
                                                        } else {
                                                            window.localStorage.clear();
                                                            localStorage.clear();
                                                            sessionStorage.clear();
                                                            localStorage.removeItem("dataCon")
                                                            localStorage.removeItem("ExpiryTime")
                                                            alert(t.StatusMessage);
                                                            history.replace("/");

                                                        }
                                                    } else {
                                                        alert(resp.StatusMessage);
                                                    }
                                                    setLoading(false);
                                                }
                                                change();
                                            }}
                                        >Proceed
                                            <ArrowRight />
                                        </button>
                                    }
                                </>
                            </div>
                        </div>
                    </Modal>
                </div>
                : <></>}

        </>
    )

}
export default BasicDetails;