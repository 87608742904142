import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { AlertDataContext } from "../Context/Alert.context";

import { ApiDataContext } from "../Context/Api.context";
import { FunctionsContext } from "../Context/Functions.context";
import { AppScreen } from "./screen";
import { CheckNumeric, CheckAlpha, sortArray } from "../service";
import CustomCheck from "../customComponents/CustomCheck";

const FinalizeInfo = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading, setmOTP } = useContext(AlertDataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const { IndividualDetail_II } = useContext(FunctionsContext)
    const history = useHistory();
    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);
    const [show, setshow] = useState(false);

    const datasubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);

        var resp = await IndividualDetail_II(data, enable);
        setLoading(false);
        if (resp.StatusCode === 0) {
            setApiData({ ...ApiData, sourceOfIncomeList: [...resp.ResponseObject.sourceOfIncomeList], OccupationList: [...resp.ResponseObject.OccupationList], OccupationChechList: [...resp.ResponseObject.OccupationChechList] });
            history.push("finalize");
        }
    }
    useEffect(() => {
        setData({ ...data, nationality: 168, resident: 168 })
        setActive("Applicant Profile Information");
    }, [])
    return (
        <>
            {true ?
                <Animatedpage enablemode={() => setenable(false)} submit={() => { !enable ? datasubmit() : history.push("finalize") }} pop={() => { setshow(true); }}
                    buttons={
                        <div className="btndiv">
                            <button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("individual")}>
                                <ArrowLeft style={{ marginLeft: -10 }} />
                                Back
                            </button>
                            <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>
                                {window.setedit === true ? "Update" : "Next"}
                                <ArrowRight style={{ marginRight: -10 }} />
                            </button>
                        </div>
                    }
                >
                    {show === true ?
                        <Grid item xs={12} id="s1q2">
                            <Row>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /8</p> */}
                                    <h1 className="Q">Nationality</h1>
                                    {/* <input className="inputtxt" name="nationality" value={data.nationality} disabled onChange={(txt) => setvalue(txt)} required /> */}
                                    <select className="inputtxt" type="text" name="nationality" onChange={(txt) => setvalue(txt)} required disabled>
                                        {
                                            sortArray(ApiData.Country, "Name").map(p => (
                                                <option value={p.id} selected={p.id === 168 ? true : false}>{p.Name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="Qhelper"></p>
                                </div>

                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> / 8</p> */}
                                    <h1 className="Q">Resident Country</h1>
                                    {/* <input className="inputtxt" value={data.resident} disabled name="resident" onChange={(txt) => setvalue(txt)} required /> */}
                                    <select className="inputtxt" type="text" name="resident" onChange={(txt) => setvalue(txt)} required disabled>
                                        {
                                            sortArray(ApiData.Country, "Name").map(p => (
                                                <option value={p.id} selected={p.id === 168 ? true : false}>{p.Name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="Qhelper"></p>
                                </div>

                                {/* <div>
                                    
                                    <h1 className="Q">Other Nationality</h1>
                                    <p className="Qhelper"></p>
                                    <select className="inputtxt" type="text" name="othNationality" onChange={(txt) => setvalue(txt)} disabled>
                                        <option>Select</option>
                                        {
                                        ApiData.Country.map(p => (
                                            <option value={p.id} selected={parseInt(data.othNationality) === p.id ? true : false}>{p.Name}</option>
                                        ))
                                    } 
                                    </select>
                                    <p className="Qhelper"></p>
                                </div> */}




                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>8</b> / 8</p> */}
                                    <h1 className="Q">Education</h1>
                                    <p className="Qhelper"></p>
                                    <select className="inputtxt" type="text" name="education" onChange={(txt) => setvalue(txt)}>
                                        <option value={""}>Select</option>
                                        {
                                            ApiData.Education.map(p => (
                                                <option value={p.id} selected={parseInt(data.education) === p.id || data.education === p.Name ? true : false}>{p.Name}</option>
                                            ))
                                        }
                                    </select>
                                    <p className="Qhelper"></p>
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                    <h1 className="Q">Present Residential Province</h1>
                                    <select className="inputtxt" name="PresentProvince" value={data.PresentProvince} onChange={(txt) => setvalue(txt)} required>
                                        <option value={""}>Select</option>
                                        {sortArray(ApiData.CityList, "Province").map(p => (
                                            <option selected={data.PresentProvince !== undefined && (parseInt(data.PresentProvince) === p.id || data.PresentProvince === p.Name) ? true : false} value={p.Province} >{p.Province}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                    <h1 className="Q">Present Residential City</h1>
                                    <select className="inputtxt" name="PresentCity" value={data.PresentCity} onChange={(txt) => setvalue(txt)} required>
                                        <option value={""}>Select</option>
                                        {ApiData.CityList !== undefined && data?.PresentProvince !== undefined && data?.PresentProvince !== "" ?
                                            sortArray(ApiData.CityList.filter(p => p.Province === data.PresentProvince)[0]?.Cities, "Name").map(p => (
                                                <option selected={data.PresentCity !== undefined && (parseInt(data.PresentCity) === p.id || data.PresentCity === p.Name) ? true : false} value={p}>{p}</option>
                                            ))
                                            : <></>
                                        }

                                    </select>
                                    <p className="Qhelper2"></p>
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                    <h1 className="Q">Present Residential Address</h1>
                                    <input className="inputtxt" name="PresentResidential" value={data.PresentResidential} onChange={(txt) => setvalue(txt)} required />
                                    <p className="Qhelper2">House no., Street no, Area, Sector etc.</p>
                                </div>
                                <div>
                                    <h1 className="Q">Residential /landline contact number</h1>
                                    {/* <input className="inputtxt" type="tel" maxLength={13} minLength={13} name="mnumber" value={data.mnumber} onChange={(txt) => setvalue(txt)} required /> */}
                                    <div class="uk-width-1-2@s input-group">
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                +92
                                            </div>
                                        </div>
                                        <input class="inputtxt" type="tel" maxLength={13} minLength={9} placeholder="" style={{ width: "93%", borderLeft: "none !important" }} value={data.landline} name="landline" onChange={(txt) => CheckNumeric(txt, () => {
                                            var fl = txt.target.value.charAt(0);
                                            if (fl == 0) {
                                                txt.target.value = txt.target.value.slice(0, -1)
                                            }
                                            setvalue(txt)
                                        }, () => { })} />

                                    </div>
                                    <p className="Qhelper2">Example +92-3001111111</p>
                                </div>
                                <div>

                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                    <h1 className="Q">Permanent Residential Province</h1>
                                    <CustomCheck list={ApiData.CheckBoxList} Name={"OTHERINFO_SameAsAbove_1"} setvalue={(txt) => {
                                        setvalue(txt);
                                        setData({ ...data, PermanentProvince: data.PresentProvince, PermanentCity: data.PresentCity, PermanentResidential: data.PresentResidential })
                                    }} required={false} />
                                    <select className="inputtxt" name="PermanentProvince" value={data.PermanentProvince} disabled={data.OTHERINFO_SameAsAbove_1 !== undefined && data.OTHERINFO_SameAsAbove_1 !== null && data.OTHERINFO_SameAsAbove_1 !== "" && data.OTHERINFO_SameAsAbove_1 !== "None"} onChange={(txt) => setvalue(txt)}>
                                        <option value={""}>Select</option>
                                        {sortArray(ApiData.CityList, "Province").map(p => (
                                            <option value={p.Province} selected={data.PermanentProvince !== undefined && (parseInt(data.PermanentProvince) === p.id || data.PermanentProvince === p.Name) ? true : false}>{p.Province}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                    <h1 className="Q">Permanent Residential City</h1>
                                    <select className="inputtxt" name="PermanentCity" value={data.PermanentCity} disabled={data.OTHERINFO_SameAsAbove_1 !== undefined && data.OTHERINFO_SameAsAbove_1 !== null && data.OTHERINFO_SameAsAbove_1 !== "" && data.OTHERINFO_SameAsAbove_1 !== "None"} onChange={(txt) => setvalue(txt)}>
                                        <option value={""}>Select</option>
                                        {data?.PermanentProvince !== undefined && data?.PermanentProvince !== "" ?
                                            sortArray(ApiData.CityList.filter(p => p.Province === data.PermanentProvince)[0]?.Cities, "Name").map(p => (
                                                <option value={p} selected={data.PermanentCity !== undefined && (parseInt(data.PermanentCity) === p.id || data.PermanentCity === p.Name) ? true : false}>{p}</option>
                                            ))
                                            : <></>
                                        }
                                    </select>
                                    <p className="Qhelper2"></p>
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                    <h1 className="Q">Permanent Residential Address</h1>
                                    <input className="inputtxt" name="PermanentResidential" value={data.OTHERINFO_SameAsAbove_1 === true ? data.PermanentResidential : data.PermanentResidential} onChange={(txt) => setvalue(txt)} disabled={data.OTHERINFO_SameAsAbove_1 !== undefined && data.OTHERINFO_SameAsAbove_1 !== null && data.OTHERINFO_SameAsAbove_1 !== "" && data.OTHERINFO_SameAsAbove_1 !== "None"} />
                                    <p className="Qhelper2">House no., Street no, Area, Sector etc.</p>

                                </div>
                                <div>
                                    <CustomCheck list={ApiData.CheckBoxList} Name={"FINALIZE_Address1_1"} setvalue={setvalue} required={false} />
                                    {data.FINALIZE_Address1_1 !== undefined && data.FINALIZE_Address1_1 !== "" && data.FINALIZE_Address1_1 !== "None" ?
                                        AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] ?
                                            <>

                                                <div className="btn"
                                                    onClick={() => { document.querySelector("input[name='bill']").click(); }}
                                                    style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                    {data.bill ? data.bill[0]?.name : "Change File"}
                                                </div>
                                                <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="bill" id="bill" multiple={false} onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                                <p className="Qhelper2">Upload utility paid bill not older than 03 months/rent agreement etc.</p>
                                            </>
                                            :
                                            <>

                                                <input className="inputtxt" multiple={false} name="bill" id="bill" type="file" onChange={(txt) => setvalue(txt)} required />
                                                <p className="Qhelper">Upload utility paid bill not older than 03 months/rent agreement etc.</p>
                                            </>
                                        : <></>
                                    }

                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>4</b> / 8</p> */}
                                    <h1 className="Q">Next of Kin Name</h1>
                                    <input className="inputtxt" type="text" value={data.kinname} name="kinname" onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} />
                                    <p className="Qhelper"></p>
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>6</b> / 8</p> */}
                                    <h1 className="Q">Next of Kin ID Number</h1>
                                    <input className="inputtxt" type="text" minLength={13} maxLength={13} value={data.kincnic} name="kincnic" onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} />
                                    <p className="Qhelper"></p>
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>5</b> / 8</p> */}
                                    <h1 className="Q">Next of Kin Address</h1>
                                    <input className="inputtxt" type="text" value={data.kinaddress} name="kinaddress" onChange={(txt) => setvalue(txt)} />
                                    <p className="Qhelper"></p>
                                </div>
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>6</b> / 8</p> */}
                                    <h1 className="Q">Relation with Next of Kin</h1>
                                    <input className="inputtxt" type="text" value={data.kinrelation} name="kinrelation" onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} />
                                    <p className="Qhelper"></p>
                                </div>

                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>7</b> / 8</p> */}
                                    <h1 className="Q">Next of Kin Contact number</h1>
                                    <div class="uk-width-1-2@s input-group">
                                        <div class="input-group-append">
                                            <div class="input-group-text">
                                                +92
                                            </div>
                                        </div>
                                        <input class="inputtxt" type="text" maxLength={13} minLength={9} placeholder="" style={{ width: "93.3%", borderLeft: "none !important" }} value={data.kinnumber} name="kinnumber" onChange={(txt) => CheckNumeric(txt, () => {
                                            var fl = txt.target.value.charAt(0);
                                            if (fl == 0) {
                                                txt.target.value = txt.target.value.slice(0, -1)
                                            }
                                            setvalue(txt)
                                        }, () => { })} />

                                    </div>
                                    <p className="Qhelper">Example +92-3001111111</p>
                                </div>


                            </Row>
                        </Grid>
                        : <></>}
                </Animatedpage> :
                <></>}
        </>
    )

}
export default FinalizeInfo;