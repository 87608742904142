
function getEnums() {
    return new Promise((resolve, reject) => {
        fetch("api/DigitalAccountApi/GetEnums").then((res) => res.json().then((resp) => {
            resolve(resp);
        })).catch(err => {

        })
    })
}
function getBankingTypes() {
    return new Promise((resolve, reject) => {
        fetch("api/DigitalAccountApi/GetBankingType").then((res) => res.json().then((resp) => {
            resolve([...resp]);
        })).catch(err => {

        })
    })
}
function getProductType() {
    return new Promise((resolve, reject) => {
        fetch("api/DigitalAccountApi/GetProductType").then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {

        })
    })
}

function VerifyDetail(context) {
    return new Promise((resolve, reject) => {
        
        var data = {
            "AccountDetails":
            {
                "BankingType": context.selectedAT.id,
                "ProductTypeId": context.option.id,
                "Currency": context.currency,
                "AccountType": context.type,
                "AccountCategory": context.category,
                "PurposeOfAccount": context.purpose
            },
            "BaseAccountVerification":
            {
                "CustomerName": context.customerName,
                "CNIC": context.cnic,
                "NadraIssuanceDate": context.issuancedate,
                "DateOfBirth": context.dateofbirth,
                "PhoneNumber": "92" + context.mnumber.replace("+", ""),
                "Email": context.email
            }
        };
        fetch("api/DigitalAccount/VerifyDetail",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {

        })
    })
}

function IndividualDetail(context) {
    return new Promise((resolve, reject) => {
        
        var data = {
            "MotherMaidenName": context.mother,
            "FatherHusbandName": context.father,
            "PlaceOfBirth": context.pob,
            "Gender": 1, //context.gender,
            "MaritalStatus": 1,// context.Marital,            
        };
        fetch("api/DigitalAccount/IndividualDetail",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {

        })
    })
}

function IndividualDetail_II(context) {
    return new Promise((resolve, reject) => {
        
        var data = {
            "Nationality": 168,// context.nationality,
            "ResidentCountry": 168,//context.resident,
            "OtherNationality": context.othNationality,
            "NextofKinName": context.kinname,
            "NextOfKinAddress": context.kinaddress,
            "NextOfKinRelation": context.kinrelation,
            "NextOfKinContactNumber": context.kinnumber,
            "Education": context.education,

        };

        fetch("api/DigitalAccount/IndividualDetailII",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {

        })
    })
}

function ResentOtp(context) {
    
    return new Promise((resolve, reject) => {
        fetch(`api/DigitalAccount/ResendOTP`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },

                body: JSON.stringify({ "ReferenceId": context.cnic, "SendEmail": true/*mnumber.replace("+", "")*/ })
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {
        })
    })
}



function ContineuResentOtp(context) {
    
    return new Promise((resolve, reject) => {
        fetch(`api/DigitalAccount/ResendOTP`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "ReferenceId": context.cnic, "SendEmail": false })
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {
        })
    })
}
function CheckPhone(context) {
    
    return new Promise((resolve, reject) => {
        fetch(`api/DigitalAccount/CheckCNIC`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ "CNIC": context.cnic })
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {
        })
    })
}

function VerifyOtp(context, otp) {
    return new Promise((resolve, reject) => {
        var data = {
            "OTP": otp,
            "PhoneNumber": "92" + context.mnumber.replace("+", "")
        };
        fetch("api/DigitalAccount/VerifyOTP",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {
        })
    })
}

function VerifyOtpSE(context, otp, Eotp, IsEditMode, ReferenceId = "") {
    return new Promise((resolve, reject) => {
        var data = {
            "IsEdit": false,//window.setedit !== undefined ? window.setedit : false,
            "IsSession": true,
            "SMSOTP": otp,
            "EmailOTP": Eotp,
            "CNIC": context.cnic,
            "ReferenceId": ReferenceId
        };
        fetch("api/DigitalAccount/VerifyOTP",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {

        })
    })
}

function VerifyContinueOTP(Number, otp) {
    return new Promise((resolve, reject) => {
        var data = {
            "SMSOTP": otp,
            "CNIC": Number
        };
        fetch("api/DigitalAccount/ContinueOTP",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        ).then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {
        })
    })
}

function getAccountDetails() {
    return new Promise((resolve, reject) => {
        fetch("api/DigitalAccountApi/GetAccountDetails").then((res) => res.json().then((resp) => {
            resolve({ ...resp });
        })).catch(err => {
        })
    })
}

function CheckAlpha(txt, success, failed) {
    
    var value = txt.target.value;
    var letters = /^[A-Za-z ]*$/;
    if (value.match(letters)) {
        success();
    }
    else {
        txt.target.value = txt.target.value.slice(0, -1);
        failed()
    }
}

function CheckNumeric(txt, success, failed) {
    var value = txt.target.value;
    var letters = /^[0-9]*$/;
    if (value.match(letters)) {
        success();
    }
    else {
        txt.target.value = txt.target.value.slice(0, -1);
        failed()
    }
}

function sortArray(array, by) {
    
    if(array === undefined){
        return [];
    }
    if (array[0][by] === undefined) {
        var returnarry = array.sort(function (a, b) {
            return compareStrings(a, b);
        })
        return returnarry;

    }
    else {
        var returnarry = array.sort(function (a, b) {
            return compareStrings(a[by], b[by]);
        })
        return returnarry;
    }

}

function compareStrings(a, b) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();

    return (a < b) ? -1 : (a > b) ? 1 : 0;
}



export {
    getBankingTypes,
    getProductType,
    getAccountDetails,
    VerifyDetail,
    ResentOtp,
    IndividualDetail,
    IndividualDetail_II,
    VerifyOtp,
    CheckPhone,
    getEnums,
    ContineuResentOtp,
    VerifyContinueOTP,
    CheckAlpha,
    CheckNumeric,
    VerifyOtpSE,
    sortArray
}


