import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Row, Table } from 'reactstrap';
import ForntCNIC from '../../assets/cnicf.jpg'
import BackCNIC from '../../assets/cnicb.jpg'
import { useHistory } from 'react-router-dom';
import SeheduleMeeting from './ScheduleMeeting';
import axios from 'axios';
import { Config } from '../../../congig';
import { postRequest } from '../service';
import { AppScreen } from '../../../components/Questions/screen'
import { ApplicationStatuses, ApplicationStatusList, ApplicationStatusesEnum, CancelSvg, checksvg, LogoB64 } from '../Enums';
import { CheckBox } from '@material-ui/icons';
import Check from '../../assets/ready.svg'
import Cancel from '../../assets/cancel.svg'
import AddNotes from './Addnotes';
import ReactPDF, { PDFDownloadLink } from '@react-pdf/renderer';
import MyDocument from './Pdfdetails';
import * as htmlToImage from 'html-to-image';

const UserList = () => {
    const history = useHistory();
    var parmsdata = history.location.state;
    const [Loading, setLoading] = useState(true);
    const [data, setData] = useState({})
    const [currentscreen, setcurrentscreen] = useState("");
    const [statuid, setstatuid] = useState();
    const [notesmodel, setNotesmodel] = useState(false);
    const [livenessimg, Setlivenessimg] = useState("");
    const [CNICF, SetCNICF] = useState("");
    const [CNICB, SetCNICB] = useState("");
    const [Checkboxlst, setCheckboxlst] = useState([]);
    const [livenessresult, setlivenessresult] = useState();
    let t = "";
    useEffect(() => {
        if (parmsdata.Id === null) {
            alert("")
            return;
        }
        axios.post("api/dashboard/DigitalAcountDashboard/CustomerDetail",
            {
                "Id": parmsdata.Id,
                "Index": parmsdata.Index
            }
        ).then(response => {
            axios.get("api/dashboard/DigitalAcountDashboard/Checkboxes").then(checklist => {
                console.log(response.data);
                setCheckboxlst([...checklist.data.ResponseObject.Checkboxes]);
                setData({ ...response.data })
                if (response.data.LivenessResults !== null && response.data.LivenessResults !== undefined) {
                    setlivenessresult(JSON.parse(response.data.LivenessResults).pop());

                }
                setcurrentscreen(response.data.AccountVerificationStep);
                setLoading(false);
            })
            // initMap(response.data.latitude, response.data.longitude);
        }).catch(error => {

        })
    }, [])

    const [meetingmodel, setMeetingmodel] = useState(false);
    const [selid, setSelid] = useState(1);

    const RejectHandler = async (id) => {

        setstatuid(id);
        setNotesmodel(!notesmodel);
    }
    const PngToJpg = (base64, callback, width, height) => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.height = height;
        canvas.width = width;
        var image = new Image();
        image.onload = function () {
            ctx.fillStyle = '#fff';
            ctx.drawImage(image, 0, 0, image.width, image.height,     // source rectangle
                0, 0, canvas.width, canvas.height);
            var data = canvas.toDataURL("image/jpeg", 1);
            callback(data);
        };
        image.src = "data:image/jpg;base64," + base64;
    }

    useEffect(() => {
        debugger
        if (data.LivenessImagePathB64 !== null && data.LivenessImagePathB64 !== undefined) {

            PngToJpg(data.LivenessImagePathB64, (convertedBase64) => { Setlivenessimg(convertedBase64); }, 160, 200)
            PngToJpg(data.CNICFrontPathB64, (convertedBase64) => { SetCNICF(convertedBase64); }, 450, 200)
            PngToJpg(data.CNICBackPathB64, (convertedBase64) => { SetCNICB(convertedBase64); }, 450, 200)

        }
    }, [data])





    return (
        <div style={{}}>
            {!Loading ?
                <div>
                    <Row style={{ marginBottom: 10 }}>
                        <Col md={12}>
                            <h3 className="page-title">User Details</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col>
                            <Card>
                                <CardBody >
                                    <div id='convertToImage'>
                                        {data.ApplicationStatus === "Application Draft" && AppScreen[currentscreen] <= AppScreen.basicdetails ? <></> :
                                            <>
                                                <h4 style={{ marginBottom: 20 }}>Account Details</h4>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {/* Config.LOCALADDRESS + "/" +  */}
                                                    {data.LivenessImagePath !== null ? <> <img style={{ height: 160, width: 200 }} src={livenessimg} /> <br /></> : ""}

                                                    {data.LivenessResults !== null && data.LivenessResults !== "" ?
                                                        <table className='table table-striped'>
                                                            <thead>
                                                                <tr>
                                                                    <th>Liveness Probability</th>
                                                                    <th>Liveness Quality</th>
                                                                    <th>Liveness Score</th>
                                                                    <th>Liveness Result</th>
                                                                    <th>Liveness Details</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                <tr>
                                                                    <td>{livenessresult?.probability}</td>
                                                                    <td>{livenessresult?.quality}</td>
                                                                    <td>{livenessresult?.score}</td>
                                                                    <td>{livenessresult?.error_code}</td>
                                                                    <td>{livenessresult?.error}</td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                        : <></>}
                                                </Row>
                                                <Row style={{ marginTop: 20 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Reference Id</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ReferenceId}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Created On</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{new Date(data.CreatedOn).toDateString()}</p>
                                                            </div>
                                                        </div>

                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Submitted On</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ApplicationStatusTimeStamps != undefined && data.ApplicationStatusTimeStamps !== "" ? (new Date(JSON.parse(data.ApplicationStatusTimeStamps).filter(p => p.TransactionStatus === 2)[0]?.DateTime).toDateString()).replace("Invalid Date", "") : ""}</p>
                                                            </div>
                                                        </div>

                                                    </Col>
                                                    <Col>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: 20 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Banking segment</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.BankingType}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 10 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Product type</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ProductType}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Currency</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.Currency}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Account type</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.AccountType}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Account Category</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.AccountCategory}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Purpose of account</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.PurposeOfAccount}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </>
                                        }
                                        {data.ApplicationStatus === "Application Draft" && AppScreen[currentscreen] <= AppScreen.basicdetails ? <></> :
                                            <>
                                                <h4 style={{ marginBottom: 20, marginTop: 20 }}>Basic Details</h4>

                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Customer Name</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                >{data.CustomerName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>National Identity Card</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.CNIC}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Identification Type</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                >{data.CNICType}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>

                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Issuance Date</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{new Date(data.NadraIssuanceDate).toDateString()}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Expiry Date</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data?.ExpiryLifetime === true ? "" : new Date(data.ExpiryDate).toDateString()}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.ExpiryLifetime === true ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {Checkboxlst.filter(p => p.Name === "BD_Lifetime_1")[0]?.Discription}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "BD_Lifetime_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                {/* <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Computerized/Smart National Identity Card Front</span>
                                                            <div className="form__form-group-field">
                                                                <img src={CNICF} style={{ width: 450, height: 200 }} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Computerized/Smart National Identity Card Back</span>
                                                            <div className="form__form-group-field">
                                                                <img src={CNICB} style={{ width: 450, height: 200 }} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row> */}
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data.EmailVerified ?
                                                        <Col>
                                                            <img src={checksvg} style={{ width: 30, height: 30 }} />
                                                            <label className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Email OTP Verified On {new Date(data.EmailVerificationDateTime).toLocaleString()}</label>
                                                        </Col> :
                                                        <Col>
                                                            <img src={CancelSvg} style={{ width: 30, height: 30 }} />
                                                            <label className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Email OTP Not Verified</label>
                                                        </Col>
                                                    }
                                                    {data.SmsVerified ?
                                                        <Col>
                                                            <img src={checksvg} style={{ width: 30, height: 30 }} />
                                                            <label className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Phone OTP Verified On {new Date(data.PhoneVerificationDateTime).toLocaleString()}</label>
                                                        </Col>
                                                        : <Col>
                                                            <img src={CancelSvg} style={{ width: 30, height: 30 }} />
                                                            <label className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Phone OTP Not Verified</label>
                                                        </Col>
                                                    }
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Date of Birth</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{new Date(data.DateOfBirth).toDateString()}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Place of Birth</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.PlaceOfBirth}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Mobile number</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >+{data.PhoneNumber}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                E-mail</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.Email}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Latitude</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                >{data.latitude}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Longitude</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                >{data.longitude}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <a href={`https://maps.google.com/maps?q=${data.latitude},${data.longitude}&amp;output=embed`} target={"_blank"}>Open Location</a>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.MobileNumberConsentDetail && data?.MobileNumberConsentDetail !== "None" ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.MobileNumberConsentDetail}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "BD_Whatsapp_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>Exporters Currency</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.exportercurrency}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.PreBasic1_1 && data?.PreBasic1_1 !== "None" ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.PreBasic1_1}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "PreBasic1_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.PreBasic2_1 && data?.PreBasic2_1 !== "None" ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.PreBasic2_1}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "PreBasic2_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                            </>}
                                        {data.ApplicationStatus === "Application Draft" && AppScreen[currentscreen] <= AppScreen.individual ? <></> :
                                            <>
                                                <h4 style={{ marginBottom: 20, marginTop: 20 }}>Individual Information</h4>

                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Mother Maiden Name</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.MotherMaidenName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Father/Husband Name</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.FatherHusbandName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }} id="hidcls">
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Nadra Mother/Maiden Name</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.NadraMotherMaidenName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Nadra Place Of Birth</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.NadraPlaceOfBirth}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Gender</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.Gender}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Marital Status</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.MaritalStatus}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>

                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Nationality</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.Nationality}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        {/* <div className="form__form-group" style={{ marginTop: 0 }}>
                                                        <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Other Nationality</span>
                                                        <div className="form__form-group-field">
                                                            <p
                                                                disabled
                                                                style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}                                                                
                                                            //onChange={(txt) => { setFullName(txt.target.value); }}
                                                            >{"Pakistan"}</p>
                                                        </div>
                                                    </div> */}
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Resident Country</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ResidentCountry}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Next of Kin Name</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.NextofKinName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Next of Kin Address</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.NextOfKinAddress}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Relation with Next of Kin</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.NextOfKinRelation}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Next of Kin Contact number</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.NextOfKinContactNumber}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Next of Kin Contact CNIC</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.NextOfKinCNIC}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Present Residential Province</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.PresentProvince}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Present Residential City</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.PresentCity}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Present Residential Address</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.PresentResidentialAddress}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Residential /landline contact number</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.Landline}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Permanent Residential Province</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.PermanentProvince}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Permanent Residential City</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.PermanentCity}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.FINALIZE_Address1_1 !== "None" && data?.FINALIZE_Address1_1 !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.FINALIZE_Address1_1}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "FINALIZE_Address1_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                    {data.BillDocString !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.BillDocString} target={"_blank"}>Click to see bill/rent agreement attach file</a> <br /></> : ""}

                                                </Row>

                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Permanent Residential Address</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.PermanentResidentialAddress}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.SameAsAbove !== "None" && data?.SameAsAbove !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.SameAsAbove}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "OTHERINFO_SameAsAbove_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Occupation</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.Occupation}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Source of Income</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.SourceOfIncome}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                <Row style={{ marginBottom: 10 }}>

                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Employeer/Business Name</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.EmployeerName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Office Province</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.OfficeProvince}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>

                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Office City</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.OfficeCity}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>

                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Office Address</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.OfficeAddress}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Office Contact Number</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.OfficePhone}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Education</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.Education}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                            Nature of Occupation/Business</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data?.NatureOccupation}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col></Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.OccupationCheckBox !== "None" && data?.OccupationCheckBox !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.OccupationCheckBox}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "FINALIZE_Occupation_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                            </>}

                                        <Row style={{ marginBottom: 10 }}>
                                            {data?.SourceOfIncomeCheck !== "None" && data?.SourceOfIncomeCheck !== null ?
                                                <Col>
                                                    <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                        <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                        {data.SourceOfIncomeCheck}
                                                    </label>
                                                </Col>
                                                : <>
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                            {Checkboxlst.filter(p => p.Name === "ID_Check_2")[0]?.Discription}
                                                        </label>
                                                    </Col>
                                                </>}
                                        </Row>
                                        {data.ApplicationStatus === "Application Draft" && AppScreen[currentscreen] <= AppScreen.znt ? <></> :
                                            <>
                                                <h4 style={{ marginBottom: 20, marginTop: 20 }}>Zakat & FATCA Status</h4>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Zakat Exemption</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ZakatExemption === true ? "Yes" : "No"}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Reason For Exemption</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ZakatExemption === true ? data.ReasonForExemptionOfZakat : "None"}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                FATCA Status</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.FATCAStatus}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                FATCA Declarations</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.FATCAStatus !== "Non-US Person" ? data.FATCADeclarations : ""}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {data.CountryTaxResidenceList?.map(p => (
                                                    <div style={{ borderColor: "#ffc423", borderWidth: 1, borderStyle: "solid", borderRadius: 5, padding: 10, marginBottom: 10 }}>
                                                        <Row style={{ marginBottom: 10 }}>
                                                            <Col>
                                                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                    <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                        Country of Residence for Tax Purpose</span>
                                                                    <div className="form__form-group-field">
                                                                        <p
                                                                            disabled
                                                                            style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                            name="name"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            required
                                                                            value={"US"}
                                                                        //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                        >{p.Country}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                    <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                        Tax Identification No (TIN)</span>
                                                                    <div className="form__form-group-field">
                                                                        <p
                                                                            disabled
                                                                            style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                            name="name"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            required
                                                                            value={"92669855"}
                                                                        //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                        >{p.TIN}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row style={{ marginBottom: 10 }}>
                                                            <Col>
                                                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                    <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                        Reason if no TIN available</span>
                                                                    <div className="form__form-group-field">
                                                                        <p
                                                                            disabled
                                                                            style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                            name="name"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            required
                                                                            value={"The Account Holder is otherwise unable to obtain a TIN or equivalent please explain why you are unable to obtain a TIN in the below REMARKS field."}
                                                                        //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                        >{p.TINReason}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                    <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                        Remarks</span>
                                                                    <div className="form__form-group-field">
                                                                        <p
                                                                            disabled
                                                                            style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                            name="name"
                                                                            type="text"
                                                                            autoComplete="off"
                                                                            placeholder=""
                                                                            required
                                                                            value={"The Account Holder is otherwise unable to obtain a TIN."}
                                                                        //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                        >{p.TINRemarks}</p>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))}

                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.ZakatExemptionCheckBox !== "None" && data?.ZakatExemptionCheckBox !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.ZakatExemptionCheckBox}
                                                            </label>
                                                        </Col>
                                                        : <></>}
                                                </Row>
                                            </>}
                                        {data.ApplicationStatus === "Application Draft" && AppScreen[currentscreen] <= AppScreen.cnc ? <></> :
                                            <>
                                                <h4 style={{ marginBottom: 20, marginTop: 20 }}>Card & Chequebook Details</h4>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Cheque Book</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ChequeBook === true ? "Yes" : "No"}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                No. of Leave’s</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ChequeBookNoOfLeaves}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.ChequeCheck !== "None" && data?.ChequeCheck !== null && data.ChequeBook === true ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.ChequeCheck.replace("<br />", "\n")}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "CNC_Checkbook_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Electronic Fund Transfer (EFT)Consent</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.EFT === true ? "Yes" : "No"}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                E-Statement Subscription</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ESS === true ? "Yes" : "No"}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.EFTCheckbox !== "None" && data?.EFTCheckbox !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.EFTCheckbox}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "CNC_EFT_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.ESFCheckbox !== "None" && data?.ESFCheckbox !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.ESFCheckbox}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "CNC_ESF_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                E-Statement Frequency</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ESF}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Selected of Branch</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{
                                                                        data.BranchDetail !== null && data.BranchDetail !== undefined && data.BranchDetail !== "" ?
                                                                            JSON.parse(data.BranchDetail)["Branch Codes"] + "," + JSON.parse(data.BranchDetail).City + "," + JSON.parse(data.BranchDetail).Province : ""
                                                                    }</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Branch Staff Referral</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.BranchRef}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                ATM Debit Card</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ATMDebitCard === true ? "Yes" : "No"}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {data.ATMDebitCard ?
                                                    <Row style={{ marginBottom: 10 }}>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Card Type</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Soneri Master Classic Debit"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.DebitCardType}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div className="form__form-group" style={{ marginTop: 0 }}>
                                                                <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                    Reason</span>
                                                                <div className="form__form-group-field">
                                                                    <p
                                                                        disabled
                                                                        style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                        name="name"
                                                                        type="text"
                                                                        autoComplete="off"
                                                                        placeholder=""
                                                                        required
                                                                        value={"Brand Conscious"}
                                                                    //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                    >{data.CardReason}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    : <></>
                                                }
                                                {data.SignaturePath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.SignaturePath} target={"_blank"}>Click to see Signature attach file</a> <br /></> : ""}
                                                <Row style={{ marginBottom: 10 }}>

                                                    {data?.SignatureCheck1 !== "None" && data?.SignatureCheck1 !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.SignatureCheck1}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "CNC_Signature1_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.SignatureCheck2 !== "None" && data?.SignatureCheck2 !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.SignatureCheck2}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "CNC_Signature2_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.SignatureCheck3 !== "None" && data?.SignatureCheck3 !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.SignatureCheck3}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "CNC_Signature3_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    {data?.SignatureCheck4 !== "None" && data?.SignatureCheck4 !== null ?
                                                        <Col>
                                                            <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                {data.SignatureCheck4}
                                                            </label>
                                                        </Col>
                                                        : <>
                                                            <Col>
                                                                <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                                    <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                                    {Checkboxlst.filter(p => p.Name === "CNC_Signature4_1")[0]?.Discription}
                                                                </label>
                                                            </Col>
                                                        </>}
                                                </Row>

                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Nature of Transactions</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.NatureOfTransactions}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Estimated Annual Income</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.EstimatedAnnualIncome}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Expected Monthly Credit – Transactions</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ExpectedMonthlyCreditTransactions}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Expected Monthly Debit – Transactions</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ExpectedMonthlyDebitTransactions}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Expected Monthly Credit</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ExpectedMonthlyCredit}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Expected Monthly Debit</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.ExpectedMonthlyDebit}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                SMS Subscription</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.SMSSubscription ? "Yes" : "No"}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Corresponding Address</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.CorrespondingAddress}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>



                                            </>}
                                        {!data?.RDName ? <></> :
                                            <>
                                                <h4 style={{ marginBottom: 20, marginTop: 20 }}>Remitter Details</h4>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Name</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.RDName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Relationship</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.RDRelationship}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Legal Id No.</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.RDLegalIdNo}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>

                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        {data.BDName === null ? <></> :
                                            <>
                                                <h4 style={{ marginBottom: 20, marginTop: 20 }}>Fund Provider (Declaration / Consent)</h4>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Name</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.BDName}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Relationship</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.BDRelationship}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Address</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.BDAddress}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Mobile</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.BDMobile}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 10 }}>
                                                    <Col>
                                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                                Legal Id No.</span>
                                                            <div className="form__form-group-field">
                                                                <p
                                                                    disabled
                                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "#ffc423", borderBottomWidth: 2, borderStyle: "solid", borderTopWidth: 0, borderLeftWidth: 0, borderRightWidth: 0, height: "fit-content", fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                                //onChange={(txt) => { setFullName(txt.target.value); }}
                                                                >{data.BDLegalIdNo}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>

                                                    </Col>
                                                </Row>
                                            </>
                                        }
                                        <Row style={{ marginBottom: 10 }}>
                                            {data?.CheckBox1 !== "None" && data?.CheckBox1 !== null ?
                                                <Col>
                                                    <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                        <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                        {data.CheckBox1}
                                                    </label>
                                                </Col>
                                                : <>
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                            {Checkboxlst.filter(p => p.Name === "Liveness_Check1_1")[0]?.Discription}
                                                        </label>
                                                    </Col>
                                                </>}
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                            {data?.CheckBox2 !== "None" && data?.CheckBox2 !== null ?
                                                <Col>
                                                    <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                        <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                        {data.CheckBox2}
                                                    </label>
                                                </Col>
                                                : <>
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                            {Checkboxlst.filter(p => p.Name === "Liveness_Check2_1")[0]?.Discription}
                                                        </label>
                                                    </Col>
                                                </>}
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                            {data?.CheckBox3 !== "None" && data?.CheckBox3 !== null ?
                                                <Col>
                                                    <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                        <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                        {data.CheckBox3}
                                                    </label>
                                                </Col>
                                                : <>
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                            {Checkboxlst.filter(p => p.Name === "Liveness_Check3_1")[0]?.Discription}
                                                        </label>
                                                    </Col>
                                                </>}
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                            {data?.CheckBox4 !== "None" && data?.CheckBox4 !== null ?
                                                <Col>
                                                    <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                        <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                        {data.CheckBox4}
                                                    </label>
                                                </Col>
                                                : <>
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                            {Checkboxlst.filter(p => p.Name === "Liveness_Check4_1")[0]?.Discription}
                                                        </label>
                                                    </Col>
                                                </>}
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                            {data?.CheckBox5 !== "None" && data?.CheckBox5 !== null ?
                                                <Col>
                                                    <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                        <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                        {data.CheckBox5}
                                                    </label>
                                                </Col>
                                                : <>
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                            {Checkboxlst.filter(p => p.Name === "Liveness_Check5_1")[0]?.Discription}
                                                        </label>
                                                    </Col>
                                                </>}
                                        </Row>
                                        <Row style={{ marginBottom: 10 }}>
                                            {data?.CheckBox6 !== "None" && data?.CheckBox6 !== null ?
                                                <Col>
                                                    <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                        <img src={checksvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                        {data.CheckBox6}
                                                    </label>
                                                </Col>
                                                : <>
                                                    <Col>
                                                        <label className="" style={{ marginBottom: 10, marginTop: 20 }}>
                                                            <img src={CancelSvg} style={{ width: 25, height: 25, marginRight: 10 }} />
                                                            {Checkboxlst.filter(p => p.Name === "Liveness_Check6_1")[0]?.Discription}
                                                        </label>
                                                    </Col>
                                                </>}
                                        </Row>
                                    </div>
                                    <Row style={{ marginBottom: 10 }} id="docdiv">
                                        <h4 style={{ marginBottom: 20, marginTop: 20 }}>Documents</h4>
                                        <Col>
                                            {data.BeneficialIncomeDocPath !== null && data.BeneficialIncomeDocPath !== undefined? <>
                                                {
                                                    JSON.parse(data.BeneficialIncomeDocPath).map((p, i) => (
                                                        <a href={Config.LOCALADDRESS + "/" + p} target={"_blank"}>Click to see Beneficial Income  attach file {i + 1}<br /></a>
                                                    ))
                                                }
                                            </> : ""}
                                            {data.BeneficialIdDocPathlist !== null && data.BeneficialIdDocPathlist !== undefined ? <>
                                                {
                                                    JSON.parse(data.BeneficialIdDocPathlist).map((p, i) => (
                                                        <a href={Config.LOCALADDRESS + "/" + p} target={"_blank"}>Click to see Beneficial Legal Id  attach file {i + 1}<br /></a>
                                                    ))
                                                }
                                            </> : ""}

                                            {data.IncomeReceiptPath !== null && data.IncomeReceiptPath !== undefined ? <>
                                                {
                                                    JSON.parse(data.IncomeReceiptPath).map((p, i) => (
                                                        <a href={Config.LOCALADDRESS + "/" + p} target={"_blank"}>Click to see IncomeReceiptPath attach file {i + 1}<br /></a>
                                                    ))
                                                }
                                            </> : ""}


                                            {data.CZ50Path !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.CZ50Path} target={"_blank"}>Click to see CZ50Path attach file</a> <br /></> : ""}

                                            {data.FATCADeclarationsPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.FATCADeclarationsPath} target={"_blank"}>Click to see FATCADeclarationsPath attach file</a> <br /></> : ""}

                                            {data.BeneficialIdDocPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.BeneficialIdDocPath} target={"_blank"}>Click to see BeneficialIdDocPath attach file</a> <br /></> : ""}

                                            {/* {data.BeneficialIncomeDocPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.BeneficialIncomeDocPath} target={"_blank"}>Click to see BeneficialIncomeDocPath attach file</a> <br /></> : ""} */}

                                            {data.CNICFrontPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.CNICFrontPath} target={"_blank"}>Click to see CNICFrontPath attach file</a> <br /></> : ""}

                                            {data.CNICBackPath !== null ? <> <a href={Config.LOCALADDRESS + "/" + data.CNICBackPath} target={"_blank"}>Click to see CNICBackPath attach file</a> <br /></> : ""}
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <button onClick={() => {
                                        history.goBack();
                                    }} className="btn" style={{ backgroundColor: "var(--islamic)", color: "white" }}>Back</button>
                                    {/* <button onClick={() => {
                                        ApproveHandler();
                                    }} className="btn" style={{ backgroundColor: "#ffc423", color: "white" }}>Approve</button> */}

                                    {data.ApplicationStatusList?.map(o => (
                                        <button onClick={() => {
                                            RejectHandler(o.id);
                                        }} className="btn" style={{ backgroundColor: "#ffc423", color: "white" }}>{o.Name.replace("_", " ")}</button>
                                    ))}

                                    {data.ApplicationStatus !== ApplicationStatuses.applicationDraft && data.ApplicationStatus !== ApplicationStatuses.BM_Required ?
                                        <>
                                            <button onClick={() => {
                                                RejectHandler(8);
                                            }} className="btn" style={{ backgroundColor: "#ffc423", color: "white" }}>Set as BM Required</button>
                                        </> : <></>}
                                    {data.ApplicationStatus !== ApplicationStatuses.applicationDraft ?
                                        <>

                                            <button onClick={() => {
                                                setLoading(true);
                                                var header = document.getElementById('fileheader').innerHTML;
                                                fetch("api/dashboard/DigitalAcountDashboard/ValidateUserInfo",
                                                    {
                                                        method: 'POST',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                        },
                                                        body: JSON.stringify({ "CNIC": data.CNIC, "html": header }),
                                                    }).then((res) => res.json()).then((resp) => {
                                                        debugger
                                                        // var url = window.URL.createObjectURL(blob);
                                                        // var a = document.createElement('a');
                                                        // a.href = url;
                                                        // a.download = `Nadra-Verification-${data.ReferenceId}.pdf`;
                                                        // a.click();
                                                        if (resp.StatusCode === 0) {
                                                            window.location.reload();
                                                        } else {
                                                            alert(resp.StatusMessage);
                                                        }
                                                        setLoading(false);
                                                    }).catch(error => {
                                                        alert(error);
                                                        setLoading(false);
                                                    })
                                            }} className="btn" style={{ marginLeft: 30, backgroundColor: "#ffc423", color: "white" }}>User Nadra Verification</button>
                                        </>
                                        : <></>}
                                    {data.ApplicationStatus === ApplicationStatuses.applicationSubmitted ?
                                        <>
                                            <button onClick={() => {
                                                setMeetingmodel(true);
                                            }} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>Schedule Meeting</button>
                                        </>
                                        : <></>}
                                    <button onClick={async () => {
                                        document.getElementById("hidcls").style.display = "none";
                                        var node = document.getElementById('convertToImage').innerHTML;
                                        var header = document.getElementById('fileheader').innerHTML;
                                        fetch("api/dashboard/DigitalAcountDashboard/DownloadPDF",
                                            {
                                                method: 'POST',
                                                headers: {
                                                    'Content-Type': 'application/json',
                                                },
                                                body: JSON.stringify({ "html": header + node }),
                                            }).then(response => response.blob())
                                            .then(blob => {
                                                var url = window.URL.createObjectURL(blob);
                                                var a = document.createElement('a');
                                                a.href = url;
                                                a.download = `${data.ReferenceId}.pdf`;
                                                a.click();
                                                document.getElementById("hidcls").style.display = "block";
                                            });

                                        // htmlToImage.toPng(node)
                                        //     .then(function (dataUrl) {
                                        //         var link = document.createElement('a');
                                        //         link.download = `${data.ReferenceId}.png`;
                                        //         link.href = dataUrl;
                                        //         link.click();
                                        //     });

                                    }} className="btn" style={{ marginLeft: 30, backgroundColor: "#ffc423", color: "white" }}>Download Application</button>
                                    {data.ApplicationStatus === ApplicationStatuses.applicationDraft ?
                                        <button onClick={() => {
                                            RejectHandler(99);
                                        }} className="btn" style={{ marginLeft: 30, backgroundColor: "#ffc423", color: "white" }}>Reset Liveness Test</button>
                                        : <></>}
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <div id="fileheader" style={{ display: "none" }}>
                        <Row>
                            <Col md={2}></Col>
                            <Col md={7} style={{ textAlign: "center" }}>
                                <img style={{ height: 60, width: 180 }} src={LogoB64} />
                                <h4>Digital Onboarding Application</h4>
                                <h4>{data.CustomerName}</h4>
                            </Col>
                            <Col md={3} style={{ textAlign: "center" }}>
                                <label>Downloaded On</label>
                                <br />
                                <lable>{new Date().toLocaleString()}</lable>
                            </Col>
                        </Row>
                    </div>

                    <Row style={{ marginBottom: 10 }}>
                        <Col md={12}>
                            <h3 className="page-title">Application Logs</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col>
                            <Card>
                                <CardBody>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>DateTime</th>
                                                <th>Status</th>
                                                <th>User</th>
                                                <th>Internal Notes</th>
                                                <th>Other Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.ApplicationStatusTimeStamps !== "" && data.ApplicationStatusTimeStamps !== undefined ? JSON.parse(data.ApplicationStatusTimeStamps).map(p => (
                                                <tr>
                                                    <td>{(new Date(p?.DateTime).toDateString()).replace("Invalid Date", "")}</td>
                                                    <td>{ApplicationStatusList.filter(o => o.id === p.TransactionStatus)[0].name}</td>
                                                    <td>{p?.UserEmail}</td>
                                                    <td>{p.Notes}</td>
                                                    <td>{p.TransactionStatus === ApplicationStatusesEnum.Application_Discrepant ?
                                                        p.Remarks
                                                        : p.TransactionStatus === ApplicationStatusesEnum.Application_Successful ?
                                                            <>
                                                                <p>IBAN Number : {p.successfulApplication.IBANNumber}</p>
                                                                <p>Account Title : {p.successfulApplication.AccountTitle}</p>
                                                                <p>Account Opening Date : {(new Date(p.successfulApplication.AccountOpeningDate).toDateString())}</p>
                                                                <p>Branch Name : {p.successfulApplication.BranchName}</p>
                                                                <p>SWIFT Code : {p.successfulApplication.SWIFTCode}</p>UserEmail
                                                            </> :
                                                            ""
                                                    }</td>
                                                </tr>

                                            )) : <></>}
                                        </tbody>
                                    </table>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row style={{ marginBottom: 10 }}>
                        <Col md={12}>
                            <h3 className="page-title">CNIC Number Logs</h3>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10 }}>
                        <Col>
                            <Card>
                                <CardBody>
                                    <table className='table table-striped'>
                                        <thead>
                                            <tr>
                                                <th>DateTime</th>
                                                <th>CNIC From</th>
                                                <th>CNIC To</th>
                                                <th>Mobile Number From</th>
                                                <th>Mobile Number To</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.changeCnicNumberLogs !== "" && data?.changeCnicNumberLogs !== null && data?.changeCnicNumberLogs !== undefined ? JSON.parse(data?.changeCnicNumberLogs).map(p => (
                                                <tr>
                                                    <td>{(new Date(p?.ChangeDate).toDateString()).replace("Invalid Date", "")}</td>
                                                    <td>{p?.ChangeFromCnic}</td>
                                                    <td>{p?.ChangeToCnic}</td>
                                                    <td>{p?.ChangeFromNumber}</td>
                                                    <td>{p?.ChangeToNumber}</td>
                                                </tr>

                                            )) : <></>}
                                        </tbody>
                                    </table>


                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                </div>
                : <>
                    <div style={{ textAlign: "center" }}>
                        {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                        <div className={`load${true ? '' : ' loaded'}`}>

                            <div className="load__icon-wrap">
                                <svg className="load__icon">
                                    <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </>}
            {
                meetingmodel == true ?
                    <SeheduleMeeting model={true} setModal={setMeetingmodel} id={parmsdata.Id} index={parmsdata.Index} />
                    : null
            }
            {
                notesmodel == true ?
                    <AddNotes model={true} setModal={setNotesmodel} id={parmsdata.Id} index={parmsdata.Index} statusid={statuid} />
                    : null
            }
        </div >
    )
}

export default UserList;