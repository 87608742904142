import React, { useState,useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const Example = ({ sdata = [] }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    var arr = [];
    sdata?.forEach(element => {
      arr.push(
        {
          name: element.ProductType,
          this: element.ThisYear,
          previous: element.LastYear,
          amt: element.ThisYear + element.LastYear,
        }
      );

    });
    setData([...arr]);
  }, [data])
  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart
        width={"100%"}
        height={330}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="previous" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="this" stroke="#82ca9d" />
      </LineChart>
    </ResponsiveContainer>
  );
}
export default Example;