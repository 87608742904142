import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory, useLocation } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Recaptcha from 'react-recaptcha'
import Animatedpage from "../animatepage";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import CustomCheck from "../customComponents/CustomCheck";

const Verify = () => {
    const { setActive } = useContext(StepsContext);
    const { setLoading, showError } = useContext(AlertDataContext);
    const { VerifyRef, ResentOtp, VerifyOtp } = useContext(FunctionsContext)
    const { Bindata } = useContext(DataContext);

    const [validRef, SetValidRef] = useState(false);
    const [countdown, setcountdown] = useState(60);
    const [data, setData] = useState({
        mnumber: "+9333333333",
        email: "example@gmail.com"
    })
    const [ReferralId, setReferralId] = useState("");
    const [otp, setotp] = useState("")
    const [otpEmail, setotpEmail] = useState("")
    const history = useHistory();
    const [Capcha, setCapcha] = useState(false);
    useEffect(() => {
        setActive("");
        if (validRef == true) {
            setcountdown(60);
            var t = setInterval(() => {
                if (countdown > -1) {
                    var s = countdown - 1;
                    setcountdown(prevTime => prevTime - 1);
                }
            }, 1000);
            var s = setTimeout(() => {
                clearInterval(t);
                clearTimeout(s)
            }, 1000 * 60);
            return () => {
                clearInterval(t);
                clearTimeout(s)
            }
        }
    }, [validRef])

    const VerifyReference = async () => {
        if (Capcha) {
            localStorage.removeItem("dataCon")
            localStorage.removeItem("ExpiryTime")
            setLoading(true);
            var url_string = window.location.href;
            var url = new URL(url_string);
            var Referral = url.searchParams.get("ReferralId");
            setReferralId(Referral);
            var data = await VerifyRef(Referral);
            console.log(data);
            if (data.StatusCode === 0) {
                var d = {
                    mnumber: data.ResponseObject.PhoneNumber,
                    email: data.ResponseObject.Email,
                    cnic: ""
                }
                setData({ ...d });
                SetValidRef(true);
                setLoading(false);
                setcountdown(60);
                var t = setInterval(() => {
                    //if (countdown > -1) {
                    var s = countdown - 1;
                    setcountdown(prevTime => prevTime - 1);
                    //}
                }, 1000);
                var s = setTimeout(() => {
                    clearInterval(t);
                    clearTimeout(s)
                }, 1000 * 60);
            } else {
                alert("Referral Id is not valid")
            }
            setLoading(false);
        } else {
            alert("Captcha is required.")
        }

    }

    var callback = function () {

    };
    var verifyCallback = function (response) {

        setCapcha(true)
    };
    const resend = async () => {

        setLoading(true);
        var resp = await ResentOtp(data);
        setLoading(false);

        setcountdown(60);
        var t = setInterval(() => {
            //if (countdown > -1) {
            var s = countdown - 1;
            setcountdown(prevTime => prevTime - 1);
            //}
        }, 1000);
        var s = setTimeout(() => {
            clearInterval(t);
            clearTimeout(s)
        }, 1000 * 60);
    }
    const varifyotp = async () => {
        setLoading(true);
        window.setedit = true;
        var resp = await VerifyOtp(data, otp, otpEmail, true, ReferralId);
        setLoading(false);
        if (resp.StatusCode === 0) {

            if (resp.StatusCode === 0) {
                console.log(resp);
                var o = await Bindata(resp.ResponseObject);
                sessionStorage.setItem("ReferralId", ReferralId);
                history.push("/");


            } else {
                showError("Some Error occurs. Please check your details");
            };
        } else {
            showError(resp.StatusMessage);
        }
    }



    return (
        <Animatedpage submit={validRef ? varifyotp : VerifyReference}>
            {validRef ?
                <Grid item xs={12} id="s1q2">
                    <Row>
                        <div>
                            <p className="Qhelper">Phone Number : <b>{data.mnumber}</b></p>
                            <p className="Qhelper">Email : <b>{data.email}</b></p>
                        </div>
                        <div>
                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> / 1</p> */}
                            <p className="Qhelper">Enter the OTP code sent to you mobile number.</p>
                            <input className="inputtxt" type={"text"} maxLength={4} minLength={4} name="otp" onChange={(txt) => setotp(txt.target.value)} required />
                            <p className="Qhelper">Enter the OTP code sent to you Email.</p>
                            <input className="inputtxt" type={"text"} maxLength={4} minLength={4} name="otp" onChange={(txt) => setotpEmail(txt.target.value)} required style={{ marginBottom: 20 }} />
                            {countdown < 1 ? <p onClick={() => VerifyReference()} className="Qhelper" style={{ textAlign: "center", marginBottom: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 0, width: 110, cursor: "pointer", padding: 10, color: "white" }}>Resent OTP</p> : <p className="Qhelper">OTP expire in 00:{countdown < 10 ? "0" + countdown : countdown}</p>}
                            {/* <Recaptcha

                            sitekey="6Ld_88YcAAAAAB179MAic7n2N5tgDn6jHERoYKew"
                            render="explicit"
                            verifyCallback={verifyCallback}
                            onloadCallback={callback}
                        /> */}
                        </div>
                        {/* <CustomCheck list={ApiData.CheckBoxList} Name={"OTP_OTPCheck_1"} setvalue={setvalue} required={true} /> */}
                        <div className="btndiv">
                            <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("basicdetails")}>
                                <ArrowLeft style={{ marginLeft: -10 }} />
                                Back
                            </button>
                            <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Next
                                <ArrowRight style={{ marginRight: -10 }} />
                            </button>
                        </div>
                    </Row>
                </Grid>
                : <>
                    <Grid item xs={12} id="s1q2">
                        <Row>
                            <div>
                                <h1 class="Q" style={{ fontSize: 20, marginTop: 20 }}>Verify You Are Human.</h1>
                                <Recaptcha

                                    sitekey="6Ld_88YcAAAAAB179MAic7n2N5tgDn6jHERoYKew"
                                    render="explicit"
                                    verifyCallback={verifyCallback}
                                    onloadCallback={callback}
                                />
                            </div>
                            <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>Verify
                                <ArrowRight style={{ marginRight: -10 }} />
                            </button>
                        </Row>
                    </Grid>
                </>}
        </Animatedpage >
    )

}
export default Verify;