import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import { StepsContext } from "../Context/Steps.Context";
import Blue from '../../Assets/blue.png'
import Mustaqeem from '../../Assets/mustaqeem.png'

import { useHistory } from "react-router";
import Animatedpage from "../animatepage";
import { getBankingTypes, getEnums } from "../service";
import { ApiDataContext } from "../Context/Api.context";

import axios from "axios";
import DocA from '../../Assets/Documents/Annexure A - Digital Account Product list and Features.pdf'
import DocB from '../../Assets/Documents/Annexure B - Requirement for Account Opening.pdf'
import DocCMustaqeem from '../../Assets/Documents/Annexure C - Digital Account Account KFS Mustaqeem.pdf'
import DocC from '../../Assets/Documents/Annexure C - Digital Account Account KFS.pdf'
import { AppScreen } from "./screen";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";

const AccountType = () => {
    const { data, setData } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext);
    const { DynamicModelShow, setLoading, showError, DynamicModelHide } = useContext(AlertDataContext)
    const { VerifyDetail } = useContext(FunctionsContext)

    const [sel, setsel] = useState(false)
    const history = useHistory();
    const [enable, setenable] = useState(-1 < AppScreen[sessionStorage.getItem("CurrentScreen")]);

    useEffect(() => {
        setActive("Banking Type");
        async function getbanking() {

            if (ApiData.Banking === undefined) {
                var arr = await getBankingTypes();
                setAccounttypelist([...arr]);
                setApiData({ ...ApiData, Banking: arr });
                //setData({ ...data, selectedAT: arr[0].BankingType })
            } else {
                setAccounttypelist([...ApiData.Banking]);
            }

        }

        getbanking();
        showNotify();
        setenable(-1 < AppScreen[sessionStorage.getItem("CurrentScreen")]);
    }, [])
    useEffect(() => {
        setsel(data.selectedAT?.BankingType ? false : true);

    }, [data]);
    const [Accounttypelist, setAccounttypelist] = useState([]);
    const showNotify = () => {
        
        var t = <>
            <h2 style={{ textAlign: "center", color: "red" }}>Important</h2>
            <h4>Please proceed, if you are not;</h4>
            <ul>
                <li>Blind/Visually Impaired</li>
                <li>Illiterate</li>
                <li>Shaky signatures bearer</li>
                <li>Mentally disordered</li>
                <li>Politically exposed person - PEP (Person or immediate family member holding prominent public position e.g. head of state, senior official (executive/judiciary/military) active politician etc.)</li>
            </ul>
            <p>For further details, feel free to visit nearest Soneri Bank branch or call 021-111-SONERI(766374)</p>
            <button
                style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                onClick={() => DynamicModelHide()}
            >Proceed
                <ArrowRight />
            </button>
        </>;
        DynamicModelShow("", t, false);
    }

    const showpermissionAlert = () => {
        
        var t = <>
            <h3 style={{ textAlign: "center", color: "red" }}>Permission to Access Location and Camera</h3>
            <h4>Digital Onboarding wants to Allow the location and camera permission. Please allow the location and camera in setting to move forward.</h4>
            <button
                style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}
                onClick={() => DynamicModelHide()}
            >Close
                <ArrowRight />
            </button>
        </>;
        DynamicModelShow("", t, false);
    }

    const handlePermission = () => {
        return new Promise(async (resolve, reject) => {
            var re = false;
            navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                if (result.state == 'granted') {
                    re = true;
                } else if (result.state == 'denied') {
                    resolve(false);
                }
                navigator.permissions.query({ name: 'camera' }).then(function (result) {
                    if (result.state == 'granted') {
                        re = true;
                    } else if (result.state == 'denied') {
                        resolve(false);
                    }

                    resolve(re);
                });

            });

        })
    }

    return (
        <Animatedpage enablemode={() => setenable(false)} submit={async () => {
            if (!sel) {
                var per = await handlePermission();
                if (!per) {
                    showpermissionAlert();
                    return;
                }
                if (window.setedit == true) {
                    var resp = await VerifyDetail(data, enable);
                    setLoading(false);
                    if (resp.StatusCode === 0) {
                        history.push("accountoption")
                    } else {
                        showError("Some Error occurs. Please check your details");
                    }
                } else {
                    history.push("accountoption")
                }
                //history.push("/accountoption")
            } else {
                alert("Please select a banking segment.");
            }
        }}
            buttons={
                <>
                    <button
                        style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 0 }} >
                        {window.setedit === true ? "Update" : "Next"}
                        <ArrowRight />
                    </button>
                </>
            }

        >
            <Grid item xs={12} style={{ marginBottom: 30 }}>
                <Row className="slide">
                    {/* <p style={{ marginTop: 30 }} className="Qhelper" id="s1q1">Question <b>1</b></p> */}
                    <h1 className="Q" style={{ fontSize: 20, marginTop: 20 }}>Select your prefered banking segment</h1>
                    {Accounttypelist.map(p => (
                        <div key={p.id} style={{ height: 120, width: 200, padding: 20 }} className={"AccountTypediv" + (data.selectedAT.id === p.id ? " Active" : "")} onClick={() => {
                            if (!enable) {
                                if (p.BankingType === "Mustaqeem" && data?.option?.AccountType === "Freelancer Account" && data?.currency !== "1" && data?.currency !== "PKR") {
                                    setData({ ...data, selectedAT: p, type: "1" })
                                    setsel(false)
                                } else {
                                    setData({ ...data, selectedAT: p })
                                    setsel(false)
                                }

                            }

                            }}>
                            {p.BankingType === "Mustaqeem" ? <img src={Mustaqeem} style={{ width: 91 }} /> : <img src={Blue} style={{ width: 160 }} />}
                            {data.selectedAT.id === p.id ? <CheckBox className="AccountTypeCheck" style={{ color: "var(--light)" }} /> : null}
                            <p className="AccountTypeName" style={{ fontWeight: "bold" }}>{p.BankingType}</p>
                            <div>
                                <ul>
                                    {p.Description.map(i => (
                                        <li key={i}>{i}</li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                    ))}
                    <br />

                </Row>
            </Grid>
            <Grid item xs={12} id="footermain" style={{ background: "var(--light)", position: "absolute", height: 130, bottom: 0, right: 0, width: "60%" }}>
                <Row style={{ padding: 10, marginLeft: "0% !important" }}>
                    <div>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={DocA} download>Products List &amp; Features</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={DocB} download>Account Opening Documents</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={DocC} download>Key Fact Statement</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href={DocCMustaqeem} download>Key Fact Statement (Mustaqeem)</a></h5>
                        <h5 className="Q" style={{ fontSize: 14 }}><a style={{ textDecoration: "none" }} href="/downloads" target="_blank">All Downloads</a></h5>
                    </div>
                </Row>
            </Grid>
        </Animatedpage>

    )
}

export default AccountType;