import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { screen } from '../../../components/Questions/screen';
import { Config } from '../../../congig';

import Check from '../../assets/ready.svg'
import Cancel from '../../assets/cancel.svg'
import { CheckBox } from '@material-ui/icons';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'View',
        backgroundColor: 'white'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGView: 1,
        fontSize: 15,
        fontWeight: 400
    },
    para: {
        backgroundColor: "white",
        paddingLeft: 5,
        borderColor: "#ffc423",
        borderBottomWidth: 2,
        borderStyle: "solid",
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        //height: "fit-content",
        fontSize: 14,
        width: "100%",
        //outlineWidth: 0,
        marginTop: 5
    }
});

// Create Document Component
const MyDocument = ({ data, imgurl }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View>
                <Text style={{ marginBottom: 20 }}>Account Details</Text>
                <View>
                    <Image source={imgurl} style={{ height: 100, width: 80 }}></Image>
                </View>
                <View style={{ marginTop: 20 }}>
                    <View>
                        <View style={{ marginTop: 0 }}>
                            <Text style={styles.section}>Reference Id</Text>
                            <View >
                                <Text style={styles.para}>{data.ReferenceId}</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ marginTop: 0 }}>
                            <Text style={styles.section}>Created On</Text>
                            <View >
                                <Text style={styles.para}>{new Date(data.CreatedOn).toDateString()}</Text>
                            </View>
                        </View>

                    </View>
                </View>
                <View>
                    <View>
                        <View style={{ marginTop: 0 }}>
                            <Text style={styles.section}>Submitted On</Text>
                            <View >
                                <Text style={styles.para}>{data.ApplicationStatusTimeStamps !== "" ? (new Date(JSON.parse(data.ApplicationStatusTimeStamps).filter(p => p.TransactionStatus === 2)[0]?.DateTime).toDateString()).replace("Invalid Date", "") : ""}</Text>
                            </View>
                        </View>

                    </View>
                    <View>
                    </View>
                </View>
                <View style={{ marginTop: 20 }}>
                    <View>
                        <View style={{ marginTop: 0 }}>
                            <Text style={styles.section}>Banking segment</Text>
                            <View >
                                <Text style={styles.para}>{data.BankingType}</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ marginTop: 10 }}>
                            <Text style={styles.section}>Product type</Text>
                            <View >
                                <Text style={styles.para}>{data.ProductType}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View>
                    <View>
                        <View style={{ marginTop: 0 }}>
                            <Text style={styles.section}>Currency</Text>
                            <View >
                                <Text style={styles.para}>{data.Currency}</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ marginTop: 0 }}>
                            <Text style={styles.section}>Account type</Text>
                            <View >
                                <Text style={styles.para}>{data.AccountType}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <View>
                    <View>
                        <View style={{ marginTop: 0 }}>
                            <Text style={styles.section}>Account Category</Text>
                            <View >
                                <Text style={styles.para}>{data.AccountCategory}</Text>
                            </View>
                        </View>
                    </View>
                    <View>
                        <View style={{ marginTop: 0 }}>
                            <Text style={styles.section}>Purpose of account</Text>
                            <View >
                                <Text style={styles.para}>{data.PurposeOfAccount}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </Page>
    </Document >
);
export default MyDocument
