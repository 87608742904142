import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Col, Row } from 'reactstrap';
import { ApplicationStatusesEnum } from '../Enums';
import { postRequest } from '../service';
// import { app } from '../../containers/Services';
import './model.css';
const AddNotes = ({ modal, setModal, id, index, statusid }) => {
    const toggle = () => setModal(false);
    const [notes, setnotes] = useState("");
    const [Loading, setLoading] = useState(false);
    const history = useHistory();
    const [remarks, setRemarks] = useState("");
    const [SWIFT, setSWIFT] = useState("")
    const [IBAN, setIBAN] = useState("")
    const [tittle, settittle] = useState("")
    const [OD, setOD] = useState("")
    const [Branch, setBranch] = useState("")
    const submit = async () => {
        if ((statusid === ApplicationStatusesEnum.Application_Successful && notes !== "") || statusid !== ApplicationStatusesEnum.Application_Successful) {
            if (statusid === ApplicationStatusesEnum.Application_Discrepant && remarks === "") {
                alert("Remarks is required");
                return;
            }
            setLoading(true);
            var t = await postRequest(statusid === 99 ? "api/dashboard/DigitalAcountDashboard/ResetLiveness" : "api/dashboard/DigitalAcountDashboard/DeclineApplication", {
                "Id": id, "Index": index, "Status": statusid, "Notes": notes, "Remarks": remarks, "statusDetails": {
                    "IBANNumber": IBAN,
                    "AccountTitle": tittle,
                    "AccountOpeningDate": OD === "" ? null : OD,
                    "BranchName": Branch,
                    "SWIFTCode": SWIFT,
                }
            });
            if (t.StatusCode === 0) {
                history.goBack()
            } else {
                alert("Some Error Occurs please try again.");
                console.error(t);
            }
            setLoading(false);
        } else {
            alert("Notes is required");
        }
    }
    useEffect(() => {
    }, [])
    return (
        <Modal isOpen={true} toggle={toggle} style={{ padding: 0 }}>
            <ModalHeader toggle={toggle}>Add Application Notes</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <div className="form__form-group" style={{ marginTop: 0 }}>
                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                Internal Notes</span>
                            <div className="form__form-group-field">
                                <textarea
                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 100, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                    rows={4}
                                    autoComplete="off"
                                    placeholder=""

                                    value={notes}
                                    onChange={(txt) => { setnotes(txt.target.value); }}
                                ></textarea>
                            </div>
                        </div>

                        {
                            statusid === ApplicationStatusesEnum.Application_Discrepant ?
                                <div className="form__form-group" style={{ marginTop: 0 }}>
                                    <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                        Remarks</span>
                                    <div className="form__form-group-field">

                                        <textarea
                                            style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 100, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                            rows={4}
                                            autoComplete="off"
                                            placeholder=""

                                            value={remarks}
                                            onChange={(txt) => { setRemarks(txt.target.value); }}
                                        ></textarea>
                                    </div>
                                </div>
                                : statusid === ApplicationStatusesEnum.Application_Successful ?
                                    <>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                IBAN Number / Account Number</span>
                                            <div className="form__form-group-field">

                                                <input
                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 30, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                    type={"text"}
                                                    autoComplete="off"
                                                    value={IBAN}
                                                    onChange={(txt) => { setIBAN(txt.target.value); }}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                Account Title</span>
                                            <div className="form__form-group-field">

                                                <input
                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 30, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                    type={"text"}
                                                    autoComplete="off"
                                                    value={tittle}
                                                    onChange={(txt) => { settittle(txt.target.value); }}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                Account Opening Date</span>
                                            <div className="form__form-group-field">

                                                <input
                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 30, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                    type={"date"}
                                                    autoComplete="off"
                                                    value={OD}
                                                    onChange={(txt) => { setOD(txt.target.value); }}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                Branch Name and code</span>
                                            <div className="form__form-group-field">

                                                <input
                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 30, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                    type={"text"}
                                                    autoComplete="off"
                                                    value={Branch}
                                                    onChange={(txt) => { setBranch(txt.target.value); }}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="form__form-group" style={{ marginTop: 0 }}>
                                            <span className="form__form-group-label" style={{ fontSize: 15, fontWeight: 400 }}>
                                                SWIFT Code of the bank</span>
                                            <div className="form__form-group-field">

                                                <input
                                                    style={{ backgroundColor: "white", paddingLeft: 5, borderColor: "var(--faysal)", borderBottomWidth: 2, borderTopWidth: 2, borderLeftWidth: 2, borderRightWidth: 2, height: 30, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                                    type={"text"}
                                                    autoComplete="off"
                                                    value={SWIFT}
                                                    onChange={(txt) => { setSWIFT(txt.target.value); }}
                                                ></input>
                                            </div>
                                        </div>
                                    </> : <></>
                        }
                    </Col>

                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => submit()} disabled={Loading}>Submit</Button>{' '}
                <Button color="secondary" onClick={toggle} disabled={Loading}>Cancel</Button>
            </ModalFooter>
        </Modal>

    );
}
export default AddNotes;