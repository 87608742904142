import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Card, CardBody, CardHeader, Col, Container, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import SeheduleMeeting from './ScheduleMeeting';
import { postRequest } from '../service';
import { ButtonList } from '../Enums';
import { Auth } from '../../Context/local.context';


const UserList = () => {
    const history = useHistory()
    var parmsdata = history.location.state;
    const [filterlist, setfilterlist] = useState(parmsdata.RoleList);
    const [Addedroles, setAddedroles] = useState(parmsdata.Roles);
    const [loaded, setLoaded] = useState(true);
    const [selRoles, setselRoles] = useState({});
    const { AuthData } = useContext(Auth)

    const AddUserRoles = async () => {
        
        if (Addedroles.length > 0) {
            alert("User already has a role.");
            return;
        }

        if (selRoles !== "" && selRoles !== 0) {
            setLoaded(false)
            var data = {
                RoleId: selRoles,
                UserId: parmsdata.Id
            }
            var resp = await postRequest("api/dashboard/Auth/AddToRole", data);
            if (resp.StatusCode === 0) {
                history.goBack();
            } else {
                alert(resp.StatusMessage)
            }
            setLoaded(true);
        } else {
            alert("Please select a role.");
        }
    }

    const RemoveUserRoles = async (Roleid) => {
        
        setLoaded(false)
        var data = {
            RoleId: Roleid,
            UserId: parmsdata.Id
        }
        var resp = await postRequest("api/dashboard/Auth/RemoveToRole", data);
        if (resp.StatusCode === 0) {
            history.goBack();
        } else {
            alert(resp.StatusMessage)
        }
        setLoaded(true)
    }
    return (
        !loaded ? (
            <div style={{ textAlign: "center" }}>
                {/* <h3 style={{alignSelf:"center",margin:"auto"}}>Please Wait..</h3> */}
                <div className={`load${true ? '' : ' loaded'}`}>

                    <div className="load__icon-wrap">
                        <svg className="load__icon">
                            <path fill="#2c2d6e" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                        </svg>
                    </div>
                </div>
            </div>
        ) :
            <div style={{}}>
                <div>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col md={12}>
                                    <h3 className="page-title">Add/Edit User Roles</h3>
                                </Col>
                                <Col md={6}>
                                    <h5 className="page-title" style={{ fontSize: 15 }}>{parmsdata.Email}</h5>

                                    <select

                                        style={{ height: 35, fontSize: 14, width: "100%", outlineWidth: 0, marginTop: 5 }}
                                        onChange={(txt) => { setselRoles(txt.target.value); }}
                                    //disabled={Addedroles.lenght > 0 ? true : false}
                                    >
                                        <option value={0}>Select Role</option>
                                        {filterlist.map(p => (
                                            <>
                                                {Addedroles.includes(p.Id) ? null :
                                                    <option value={p.Id}>{p.Name}</option>
                                                }
                                            </>
                                        ))}

                                    </select>
                                    {AuthData.Buttons.findIndex(p => p === ButtonList.AddUserRole) !== -1 ?
                                        <button onClick={() => {
                                            AddUserRoles()

                                        }}
                                            //disabled={Addedroles.lenght > 0 ? true : false}
                                            className="btn" style={{ marginLeft: 5, marginTop: 10, backgroundColor: "var(--faysal)", color: "white" }}>Add Role</button>
                                        : <></>}
                                </Col>
                                <Col md={6}>
                                    <h5 className="page-title" style={{ fontSize: 15 }}>User Roles</h5>
                                    <Table responsive hover bordered>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Role</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Addedroles.map((p, i) => (
                                                <tr key={p}>
                                                    <th scope="row"><p>{(i + 1)}</p></th>
                                                    <td><p>{filterlist.filter(r => r.Id === p)[0].Name}</p></td>
                                                    <td>
                                                        {AuthData.Buttons.findIndex(p => p === ButtonList.RemoveUserRole) !== -1 ?
                                                            <button onClick={() => RemoveUserRoles(p)} className="btn" style={{ marginLeft: 30, backgroundColor: "var(--islamic)", color: "white" }}>Delete</button>
                                                            : <></>}
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            </div >
    )
}

export default UserList;