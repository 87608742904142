import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { ApiDataContext } from "../Context/Api.context";
import { getAccountDetails } from "../service";
import { AppScreen } from "./screen";
import { FunctionsContext } from "../Context/Functions.context";
import { AlertDataContext } from "../Context/Alert.context";
import CustomCheck from "../customComponents/CustomCheck";

const AccountOtherdetails = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const { VerifyDetail } = useContext(FunctionsContext)
    const { setLoading, showError } = useContext(AlertDataContext)

    const [Layout, setLayout] = useState(false)
    const history = useHistory();
    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);

    useEffect(() => {
        setActive("Product Details");
        async function PopulateData() {
            if (!ApiData?.currencyList?.length > 0) {

                var arr = await getAccountDetails();
                setApiData({ ...ApiData, ...arr });

                ;
            }
        }

        PopulateData();
    }, [])
    return (
        <>
            <Animatedpage enablemode={() => setenable(false)} submit={async () => {
                if (window.setedit) {
                    var resp = await VerifyDetail(data, enable);
                    setLoading(false);
                    if (resp.StatusCode === 0) {
                        history.push("basicdetails")
                    } else {
                        showError("Some Error occurs. Please check your details");
                    }
                } else {
                    history.push("basicdetails")
                }
            }} pop={() => setLayout(true)}
                buttons={
                    <>
                        <button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => history.push("accountoption")}>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" variant="contained" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>
                            {window.setedit === true ? "Update" : "Next"}
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </>
                }
            >
                {Layout ?
                    <Grid item xs={12} id="s1q2">
                        <Row>
                            <fieldset disabled={enable}>

                                <div>
                                    <h1 className="Q" style={{ marginTop: 20 }}>Select your account currency</h1>
                                    {/* <p className="Qhelper">Please enter your full name as it appears on your CNIC, SNIC or NICOP.</p> */}
                                    <select className="inputtxt" disabled={data.option.AccountType === "Asaan Digital Account" || data.option.AccountType == "Asaan Digital Remittance" || data.option.AccountType === "Freelancer Account"}
                                        onChange={(txt) => {

                                            if (data.selectedAT.BankingType === "Mustaqeem" && data.option.AccountType === "Freelancer Account" && txt.target.value !== "1") {
                                                setData({ ...data, type: "1", currency: txt.target.value })
                                            } else {
                                                setData({ ...data, currency: txt.target.value })
                                            }
                                        }}
                                    >
                                        {ApiData.currencyList.map(p => (
                                            <option value={p.id} selected={p.id === parseInt(data.currency) || p.id === data.currency || p.Name === data.currency}>{p.Name}</option>
                                        ))}
                                    </select>
                                </div>

                                <div>
                                    <h1 className="Q" style={{ marginTop: 20 }}>Select your account type</h1>
                                    {/* <p className="Qhelper">Please enter your full name as it appears on your CNIC, SNIC or NICOP.</p> */}
                                    {/* <select className="inputtxt" disabled={data.selectedAT === "Islamic" && data.option === "Freelancer Account" && data.currency !== "PKR"}
                        onChange={(txt) => {
                            setData({ ...data, type: txt.target.value })

                        }}
                    >
                        <option value="Current" selected={data.type==="Current"?true:false}>Current</option>
                        <option value="Saving" selected={data.type==="Saving"?true:false}>Saving</option>
                    </select> */}
                                    <div>
                                        {ApiData.accountTypeList.map((p, i) => (
                                            <div style={{ display: "inline-block" }}>
                                                <input type="radio" name="type" disabled={data.selectedAT.BankingType === "Mustaqeem" && data.option.AccountType === "Freelancer Account" && (parseInt(data.currency) !== 1 && data.currency !== "1" && data.currency !== "PKR")}
                                                    onClick={(txt) => {

                                                        setData({ ...data, type: txt.target.value })

                                                    }} value={p.id} id={p.Name} required={i === 0 ? true : false}
                                                    checked={parseInt(data.type) === p.id || data.type === p.id || data.type === p.Name ? true : false}
                                                />
                                                <label for={p.Name} className="Q" style={{ marginLeft: 10, marginRight: 10 }}>{p.Name}</label>
                                            </div>
                                        ))}


                                    </div>

                                </div>
                                <div>
                                    <h1 className="Q" style={{ marginTop: 20 }}>Select your account category</h1>
                                    {/* <p className="Qhelper">Please enter your full name as it appears on your CNIC, SNIC or NICOP.</p> */}
                                    {/* <select className="inputtxt"
                            onChange={(txt) => setData({ ...data, category: txt.target.value })}
                            disabled
                        >
                            <option>Single</option>
                            <option>Joint</option>
                            <option>Minor</option>
                        </select> */}
                                    <div style={{ display: "inline-block" }}>
                                        <input type="radio" name="category"
                                            onClick={(txt) => {
                                                setData({ ...data, category: txt.target.value })

                                            }} value="1" id="Single" checked /><label for="Single" className="Q" style={{ marginLeft: 10, marginRight: 10 }}>Single</label>
                                    </div>
                                </div>
                                {data.option.AccountType === "Freelancer Account" ?
                                    <>
                                        <div>
                                            <h1 className="Q" style={{ marginTop: 20 }}>{
                                                ApiData.CheckBoxList.filter(p => p.Name == "PreBasic1_1")[0].Discription
                                            }</h1>
                                            <select name="PreBasic1_1"
                                                onChange={(txt) => {
                                                    setData({
                                                        ...data, PreBasic1_1: txt.target.value == "true" ? ApiData.CheckBoxList.filter(p => p.Name == "PreBasic1_1")[0].id : 0,
                                                        exportercurrency: txt.target.value == "true" ? ApiData.currencyList.filter(p => p.id === 2)[0].id : 0
                                                    })
                                                }}
                                                className="inputtxt"
                                            >
                                                <option value={false} selected={data.PreBasic1_1 == 0}>No</option>
                                                <option value={true} selected={data.PreBasic1_1 == ApiData.CheckBoxList.filter(p => p.Name == "PreBasic1_1")[0].Discription}>Yes</option>
                                            </select>

                                        </div>
                                        {
                                            data.PreBasic1_1 == 0 || data.PreBasic1_1 == undefined ?
                                                <>
                                                    <CustomCheck list={ApiData.CheckBoxList} Name={"PreBasic2_1"} setvalue={setvalue} required={true} />
                                                </>
                                                :
                                                <>
                                                    <div>
                                                        <h1 className="Q" style={{ marginTop: 20 }}>Select your exporters account currency</h1>
                                                        {/* <p className="Qhelper">Please enter your full name as it appears on your CNIC, SNIC or NICOP.</p> */}
                                                        <select className="inputtxt"
                                                            onChange={(txt) => {
                                                                setData({ ...data, exportercurrency: txt.target.value })
                                                            }}
                                                            required
                                                        >
                                                            {/* <option >Select Currency</option> */}
                                                            {ApiData.currencyList.filter(p => p.id !== 1).map(p => (
                                                                <option value={p.id} selected={p.id === parseInt(data.exportercurrency) || p.id === data.exportercurrency || p.Name === data.exportercurrency}>{p.Name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <br />
                                                    <div>
                                                        <p>Exporters' Special Foreign Currency Account-ESFCA as secondary account for retention purpose </p>
                                                        <ul>
                                                            <li>Account Type: Current</li>
                                                            <li>Allowed Account Currency: USD, GBP, EUR</li>
                                                            <li>Monthly Average Balance: Not Applicable</li>
                                                            <li>Minimum balance Charges: Not Applicable</li>
                                                            <li>Transaction/Balance Limit: Unlimited</li>
                                                            <li>Credit shall only be allowed against payment received from outside Pakistan against Freelancing services including IT.</li>
                                                            <li>In Pakistan withdrawals only in PKR will be allowed, however, for international payments foreign currency can be withdrawn or remitted.</li>
                                                        </ul>
                                                    </div>

                                                </>}
                                    </>
                                    : <></>
                                }

                            </fieldset>

                        </Row>
                    </Grid>
                    : <></>}
            </Animatedpage>
        </>
    )

}
export default AccountOtherdetails;