

import React, { useContext, useEffect, useState } from "react";
import { ApiDataContext } from "./Api.context";
import { StepsContext } from "./Steps.Context";

const DataContext = React.createContext({});
var CryptoJS = require("crypto-js");
const DataContextProvider = ({ children }) => {
    const { ApiData } = useContext(ApiDataContext)
    const { setActive, setComplete, AddSteps } = useContext(StepsContext);


    const [data, setDatastate] = useState({
        selectedAT: "1",
        option: "1",
        category: 1,
        currency: "1",
        type: "",
        cnic: "",
        purpose: "",
        mnumber: "",
        Occupation: "Salaried",
        NutureTrans: [],
        ATMReason: [],
        Checkbook: "Yes",
        ATM: "Yes",

        ATMCard: {},
        kinnumber: "",
        landline: "",
        nationality: "168",
        resident: "168",
        Zakat: "yes",
        COUNTRYTAXRESIDENCE: [{}, {}, {}],
        FATCADeclarations: "",
        FATCAStatus: "",
        officenumber: ""
    });
    useEffect(() => {

        //checkExpiration();

        var t = window.localStorage.getItem("dataCon");

        if (t !== null && t !== undefined) {
            var bytes = CryptoJS.AES.decrypt(t, 'BI#@@@$$');
            console.log(bytes, "bytes")
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            var s = decryptedData;
            setDatastate({ ...s });
            console.log(s);

        } else {
            fetch("api/DigitalAccount/ClearCookie").then(() => {
                console.log("cookie clean");
                localStorage.setItem("CurrentScreen", "/");
            }).catch(err => {
                console.log(err);
            })
        }

    }, [])
    const setvalue = (txt) => {

        var value = txt.target.value;
        var name = txt.target.name;
        var type = txt.target.type;
        var t = data;

        if (type === "file") {
            var s = [];
            for (let index = 0; index < txt.target.files.length; index++) {
                const element = txt.target.files[index];
                s.push({
                    name: element.name
                })

            }
            t[name] = s;
            setDatastate({ ...t });
        }
        else if (type === "checkbox") {
            if (txt.target.checked) {
                t[name] = value;
            } else {
                t[name] = "";
            }
            setDatastate({ ...t });
        }
        else {
            t[name] = value;
            setDatastate({ ...t });
        }
        // window.localStorage.setItem("dataCon", JSON.stringify({ ...t }))
    }
    const setvalueinArray = (txt, arrayname, index) => {

        var value = txt.target.value;
        var name = txt.target.name;
        var type = txt.target.type;
        var t = data;
        if (t[arrayname] === undefined) {
            t[arrayname] = [];
        }
        if (t[arrayname].length < index + 1) {
            t[arrayname].push({});
        }
        if (type === "file") {
            t[arrayname][index][name] = txt.target.files;
            setDatastate({ ...t });
        }
        else if (type === "checkbox") {
            t[arrayname][index][name] = txt.target.checked;
            setDatastate({ ...t });
        }
        else {

            t[arrayname][index][name] = value;
            setDatastate({ ...t });
        }
        ///  window.localStorage.setItem("dataCon", JSON.stringify({ ...t }))
    }

    const setmultiple = (txt, name) => {
        var t = data;
        t[name] = txt;
        setDatastate({ ...t });
        //window.localStorage.setItem("dataCon", JSON.stringify({ ...t }))

    }

    function Bindata(resdata) {
        return new Promise((resolve, reject) => {
            var AccountDetails = resdata.data.AccountDetails;
            var BeneficialDetails = resdata.data.BeneficialDetails;
            var CardAndChequeBookDetails = resdata.data.CardAndChequeBookDetails;
            var IndividualInformationPart_II = resdata.data.IndividualInformationPart_II;
            var IndividualInformationPart_III = resdata.data.IndividualInformationPart_III;
            var RemitterDetails = resdata.data.RemitterDetails;
            var ZakatAndTransactionDetails = resdata.data.ZakatAndTransactionDetails;
            var IndividualInformationPart_I = resdata.data.IndividualInformationPart_I;


            var d = {
                NutureTrans: [],
                ATMReason: [],
                FATCADeclarations: "",
                FATCAStatus: "",
                ATMCard: {},
                COUNTRYTAXRESIDENCE: [{}, {}, {}],

            };
            if (AccountDetails !== null) {

                d = {
                    ...d,
                    "selectedAT": ApiData.Banking.filter(p => p.BankingType === AccountDetails.BankingType)[0],
                    "option": ApiData.Producttype.filter(p => p.Id === AccountDetails.ProductTypeId)[0],
                    "currency": AccountDetails.Currency,
                    "type": AccountDetails.AccountType,
                    "category": AccountDetails.AccountCategory,
                    "customerName": AccountDetails.CustomerName !== null || AccountDetails.CustomerName !== "" ? AccountDetails.CustomerName : resdata.Userdata.CustomerName,
                    "cnic": resdata.Userdata.CNIC,
                    "issuancedate": AccountDetails.NadraIssuanceDate.split('T')[0],
                    "dateofbirth": (AccountDetails.DateOfBirth !== null || AccountDetails.DateOfBirth !== "" ? AccountDetails.DateOfBirth : resdata.Userdata.DateOfBirth).split('T')[0],
                    "mnumber": AccountDetails.PhoneNumber.slice(2),
                    "email": AccountDetails.Email,
                    "expirydate": AccountDetails.ExpiryDate.split('T')[0],
                    "cnictype": AccountDetails.CNICType,
                    "latitude": AccountDetails.latitude,
                    "longitude": AccountDetails.longitude,
                    "BD_Whatsapp_1": AccountDetails.MobileNumberConsentDetail,
                    "BD_Lifetime_1": AccountDetails.ExpiryLifetimeDetail,
                    "PreBasic1_1": AccountDetails.PreBasic1_1,
                    "PreBasic2_1": AccountDetails.PreBasic2_1,
                    "exportercurrency":AccountDetails.exportercurrency

                }
                // if(AccountDetails.ProductTypeId==="2"||AccountDetails.ProductTypeId==2){
                //     AddSteps("Remitter Details", "FATCA & CRS Details");
                // }
            }
            if (IndividualInformationPart_I !== null) {
                d = {
                    ...d,
                    "mother": IndividualInformationPart_I.MotherMaidenName,
                    "father": IndividualInformationPart_I.FatherHusbandName,
                    "pob": IndividualInformationPart_I.PlaceOfBirth,
                    "gender": IndividualInformationPart_I.Gender,
                    "Marital": IndividualInformationPart_I.MaritalStatus,
                }
            }

            if (IndividualInformationPart_II !== null) {
                d = {
                    ...d,
                    "nationality": IndividualInformationPart_II.Nationality,
                    "resident": IndividualInformationPart_II.ResidentCountry,
                    "othNationality": IndividualInformationPart_II.OtherNationality,
                    "education": IndividualInformationPart_II.Education,
                    "PresentResidential": IndividualInformationPart_II.PresentResidentialAddress,
                    "landline": IndividualInformationPart_II.Landline,
                    "PermanentResidential": IndividualInformationPart_II.PermanentResidentialAddress,
                    "kinname": IndividualInformationPart_II.NextofKinName,
                    "kinaddress": IndividualInformationPart_II.NextOfKinAddress,
                    "kinrelation": IndividualInformationPart_II.NextOfKinRelation,
                    "kinnumber": IndividualInformationPart_II.NextOfKinContactNumber,
                    "kincnic": IndividualInformationPart_II.NextOfKinCNIC,
                    "PermanentCity": IndividualInformationPart_II.PermanentCity,
                    "PermanentProvince": IndividualInformationPart_II.PermanentProvince,
                    "PresentCity": IndividualInformationPart_II.PresentCity,
                    "PresentProvince": IndividualInformationPart_II.PresentProvince,
                    "OTHERINFO_SameAsAbove_1": IndividualInformationPart_II.SameAsAbove,
                    "FINALIZE_Address1_1": IndividualInformationPart_II.FINALIZE_Address1_1,
                    "bill": IndividualInformationPart_II.BillDocString
                }
            }

            if (IndividualInformationPart_III !== null) {
                d = {
                    ...d,
                    "employeer": IndividualInformationPart_III.EmployeerName,
                    "officeaddress": IndividualInformationPart_III.OfficeAddress,
                    "officenumber": IndividualInformationPart_III.OfficePhone,
                    "Occupation": IndividualInformationPart_III.Occupation,
                    "NatureOccupation": IndividualInformationPart_III.NatureOccupation,
                    "OfficeProvince": IndividualInformationPart_III.OfficeProvince,
                    "OfficeCity": IndividualInformationPart_III.OfficeCity,
                    "Income": IndividualInformationPart_III.SourceOfIncome.split(","),
                    "FINALIZE_Occupation_1": IndividualInformationPart_III.OccupationCheckBox,
                    "ZakatExemptionCheckBox": IndividualInformationPart_III.ZakatExemptionCheckBox,
                    "Zakat": IndividualInformationPart_III.ZakatExemption === true ? "1" : "0",
                    "Zakatreason": IndividualInformationPart_III.ReasonForExemptionOfZakat,
                    "ZNT_NonMuslim_1": IndividualInformationPart_III.ZakatExemptionCheckBox,
                    "ZNT_Fiqqah_1": IndividualInformationPart_III.ZakatExemptionCheckBox,
                    "declarationCZ": IndividualInformationPart_III.CZ50Path
                }
            }
            if (BeneficialDetails !== null) {
                d = {
                    ...d,
                    "BeneficialName": BeneficialDetails.Name,
                    "BeneficialAddress": BeneficialDetails.Address,
                    "BeneficialMobile": BeneficialDetails.Mobile,
                    "BeneficialLegalID": BeneficialDetails.LegalIdNo,
                    "BeneficialRelationship": BeneficialDetails.Relationship,
                    "FP_Check_1": BeneficialDetails.BeneficialCheck,
                    "BeneficialCity": BeneficialDetails.BeneficialCity,
                    "BeneficialProvince": BeneficialDetails.BeneficialProvince,
                }
            }
            if (ZakatAndTransactionDetails !== null) {
                var arr = [];
                ZakatAndTransactionDetails.countryTaxResidence?.map(p => {
                    arr.push({
                        "taxResidence": p === null ? 0 : p.Country,
                        "TIN": p.TIN,
                        "TINReason": p.TINReason,
                        "TINremarks": p.TINRemarks
                    })
                })
                d = {
                    ...d,
                    "FATCAStatus": ZakatAndTransactionDetails.FATCAStatus,
                    "FATCADeclarations": ZakatAndTransactionDetails.FATCADeclarations,
                    "COUNTRYTAXRESIDENCE": arr,
                    "WFile": ZakatAndTransactionDetails.FATCADeclarationsPath

                }
            }
            if (RemitterDetails !== null) {
                d = {
                    ...d,
                    "remitterName": RemitterDetails.Name,
                    "remitterRelation": RemitterDetails.Relationship,
                    "remitterlegalID": RemitterDetails.LegalId,
                }
            }

            if (CardAndChequeBookDetails !== null) {
                d = {
                    ...d,
                    "Checkbook": CardAndChequeBookDetails.ChequeBook === true ? "1" : "0",
                    "CheckbookLeave": CardAndChequeBookDetails.ChequeBookNoOfLeaves === "1" ? 25 : 0,
                    "ATM": CardAndChequeBookDetails.ATMDebitCard === true ? "1" : "0",
                    "EFT": CardAndChequeBookDetails.EFT === true ? "1" : "0",
                    "ESS": CardAndChequeBookDetails.ESS === true ? "1" : "0",
                    "Branchname": CardAndChequeBookDetails.BranchCode,
                    "Branchref": CardAndChequeBookDetails.BranchRef,
                    "ESF": CardAndChequeBookDetails.ESF,
                    "ATMReason": CardAndChequeBookDetails.CardReason.split(","),//context.,
                    "ATMCard": CardAndChequeBookDetails.ATMDebitCard === true ? ApiData.CardTypeDetailList.ResponseObject.filter(p => p.Name === CardAndChequeBookDetails.DebitCardType)[0] : {},
                    "NutureTrans": CardAndChequeBookDetails.NatureOfTransactions.split(","),// context.,
                    "EstimatedIncome": CardAndChequeBookDetails.EstimatedAnnualIncome,
                    "MonthlyCredittrans": CardAndChequeBookDetails.ExpectedMonthlyCreditTransactions,
                    "MonthlyDebittrans": CardAndChequeBookDetails.ExpectedMonthlyDebitTransactions,
                    "MonthlyCredit": CardAndChequeBookDetails.ExpectedMonthlyCredit,
                    "MonthlyDebit": CardAndChequeBookDetails.ExpectedMonthlyDebit,
                    "purpose": CardAndChequeBookDetails.PurposeOfAccount,
                    "smssubscription": CardAndChequeBookDetails.SMSSubscription === true ? "1" : "0",
                    "CorrespondingAddress": CardAndChequeBookDetails.CorrespondingAddress,
                    "CNC_Signature1_1": CardAndChequeBookDetails.SignatureCheck1,
                    "CNC_Signature2_1": CardAndChequeBookDetails.SignatureCheck2,
                    "CNC_Signature3_1": CardAndChequeBookDetails.SignatureCheck3,
                    "CNC_Signature4_1": CardAndChequeBookDetails.SignatureCheck4,
                    "CNC_ESF_1": CardAndChequeBookDetails.ESFCheckbox,
                    "CNC_EFT_1": CardAndChequeBookDetails.EFTCheckbox,
                    "CNC_Checkbook_1": CardAndChequeBookDetails.ChequeCheck,
                }
            }

            setDatastate({ ...d });
            resolve();
        })
    }

    function checkExpiration() {
        var values = JSON.parse(window.localStorage.getItem('ExpiryTime'));
        if (values !== null && values !== undefined && new Date(values?.expiry) < new Date()) {
            window.localStorage.removeItem("dataCon")
            window.localStorage.removeItem("ExpiryTime")

        }
    }
    useEffect(() => {
        if (data.selectedAT !== "1") {
            updateDataTolocal();
        }
    }, [data])
    const updateDataTolocal = () => {

        var myHour = new Date();
        var now = new Date();
        myHour.setHours(now.getHours() + 1);
        var encrypteddata = CryptoJS.AES.encrypt(JSON.stringify(data), 'BI#@@@$$').toString();
        window.localStorage.setItem('ExpiryTime', JSON.stringify({ expiry: myHour.getTime() }));
        window.localStorage.setItem("dataCon", encrypteddata)

    }

    const setData = (datatoupdate) => {
        if (datatoupdate?.selectedAT !== "1") {
            setDatastate({ ...datatoupdate });
        }
    }

    return (
        <DataContext.Provider value={{
            data,
            setData,
            setvalue,
            Bindata,
            setmultiple,
            setvalueinArray
        }}>
            {children}
        </DataContext.Provider>
    )
};

export {
    DataContext,
    DataContextProvider
};