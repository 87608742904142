import React from "react";
import ErrorIcon from '@material-ui/icons/Error';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CNIC from '../../Assets/cnic.jpg';
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    // maxWidth:"30vw",
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
const Cinput = ({ name, onChange,value, required }) => {
    return <>
        <input className="inputtxt" name={name} value={value} onChange={(txt) => onChange(txt)} required />
        <HtmlTooltip
        title={
          <React.Fragment>
              Enter the name mention on your CNIC.
            <img src={CNIC}  style={{width:"20vw"}}/>
          </React.Fragment>
        }
      >
        <ErrorIcon color={"#ffc423"} style={{fill:"#ffc423", marginLeft: -30, zIndex: 5 }} />
        </HtmlTooltip>
    </>;
}

export default Cinput;