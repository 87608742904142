import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox, AttachFile } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import { FunctionsContext } from "../Context/Functions.context";
import { AlertDataContext } from "../Context/Alert.context";
import { CheckAlpha, CheckNumeric } from "../service";
import { ApiDataContext } from "../Context/Api.context";
import { AppScreen } from "./screen";
import CustomCheck from "../customComponents/CustomCheck";

const BeneficialInfo = () => {
    const { data, setData, setvalue } = useContext(DataContext);
    const { setActive, AddSteps } = useContext(StepsContext);
    const { Beneficial } = useContext(FunctionsContext)
    const { setLoading } = useContext(AlertDataContext);
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const [addresscheck, setaddresscheck] = useState(false);
    const history = useHistory();

    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);


    useEffect(() => {
        AddSteps("Fund Provider", "Applicant Profile Information");
        setActive("Fund Provider");
    }, [])
    const datasubmit = async (e) => {
        //e.preventDefault();
        setLoading(true);
        var resp = await Beneficial(data);
        setLoading(false);

        if (resp.StatusCode === 0) {
            history.push("znt");
        }
    }

    return (
        <Animatedpage submit={() => !enable ? datasubmit() : history.push("znt")}
            enablemode={() => setenable(false)}
            buttons={
                <div className="btndiv">
                    <button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                        history.push("finalize")
                    }>
                        <ArrowLeft style={{ marginLeft: -10 }} />
                        Back
                    </button>
                    <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }} >
                        {window.setedit === true ? "Update" : "Next"}
                        <ArrowRight style={{ marginRight: -10 }} />
                    </button>
                </div >
            }
        >
            {
                ApiData?.CityList ?
                    <Grid item xs={12} id="s1q2">
                        < Row >
                            <h1 className="Q">Please provide the details of Fund Provider along with supporting documents.</h1>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q">Name</h1>
                                <input className="inputtxt" name="BeneficialName" value={data.BeneficialName} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper2">As per NADRA ID document</p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> /7</p> */}
                                <h1 className="Q">Legal ID No.</h1>
                                <input className="inputtxt" maxLength={13} minLength={13} name="BeneficialLegalID" value={data.BeneficialLegalID} onChange={(txt) => CheckNumeric(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper2">As per NADRA ID document</p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                <h1 className="Q">Province</h1>
                                <select className="inputtxt" name="BeneficialProvince" value={data.BeneficialProvince} onChange={(txt) => setvalue(txt)} >
                                    <option value={""}>Select</option>
                                    {ApiData.CityList.map(p => (
                                        <option value={p.Province}>{p.Province}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2"></p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /3</p> */}
                                <h1 className="Q">City</h1>
                                <select className="inputtxt" name="BeneficialCity" value={data.BeneficialCity} onChange={(txt) => setvalue(txt)} >
                                    <option value={""}>Select</option>
                                    {ApiData.CityList.filter(p => p.Province === data.BeneficialProvince)[0]?.Cities.map(p => (
                                        <option value={p}>{p}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2"></p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> /7</p> */}
                                <h1 className="Q">Address</h1>
                                <input type={"checkbox"} id="setaddresscheck" name="setaddresscheck" onChange={(e) => { setaddresscheck(e.target.checked) }}
                                    disabled={data?.officeaddress === undefined || data?.officeaddress === "" ? true : false}
                                /><label for="setaddresscheck">Office Address</label>
                                <input className="inputtxt" name="BeneficialAddress" onChange={(txt) => setvalue(txt)} required
                                    value={addresscheck && data?.officeaddress != undefined && data?.officeaddress !== "" ? data?.officeaddress : data.BeneficialAddress}
                                    disabled={addresscheck && data?.officeaddress != undefined && data?.officeaddress !== "" ? true : false}
                                />
                                {addresscheck && data?.officeaddress === undefined || data?.officeaddress === "" ?
                                    <p className="Qhelper" style={{ margin: 1, color: "red" }}>Office
                                        Address Found Blank</p>
                                    : <></>}
                                <p className="Qhelper2">House no., Street no, Area, Sector etc.</p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>4</b> /7</p> */}
                                <h1 className="Q">Mobile No.</h1>
                                <div class="uk-width-1-2@s input-group">
                                    <div class="input-group-append">
                                        <div class="input-group-text">
                                            +92
                                        </div>
                                    </div>
                                    <input class="inputtxt" type="tel" maxLength={13} minLength={9} placeholder="" style={{ width: "93.3%", borderLeft: "none !important" }} value={data.BeneficialMobile} name="BeneficialMobile" onChange={(txt) => CheckNumeric(txt, () => {
                                        var fl = txt.target.value.charAt(0);
                                        if (fl == 0) {
                                            txt.target.value = txt.target.value.slice(0, -1)
                                        }
                                        setvalue(txt)
                                    }, () => { })} required />

                                </div>
                                <p className="Qhelper2">Example +92-3001111111</p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>5</b> /7</p> */}
                                <h1 className="Q">Relationship</h1>
                                <input className="inputtxt" name="BeneficialRelationship" value={data.BeneficialRelationship} onChange={(txt) => CheckAlpha(txt, () => { setvalue(txt) }, () => { })} required />
                                <p className="Qhelper2"></p>
                            </div>

                            <div className="inputrow">
                                <div className="inputcolumn1">
                                    <AttachFile style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                </div>
                                <div className="inputcolumn2">
                                    {AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] ?
                                        <>
                                            <h1 className="Q">Legal ID Document  (Attach front and back both)</h1>
                                            <div className="btn"
                                                onClick={() => { document.querySelector("input[name='Beneficialdoc']").click(); }}
                                                style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                {data.Beneficialdoc ? data.Beneficialdoc.length > 1 ? +data.Beneficialdoc.length +" Files selected" :data.Beneficialdoc[0]?.name : "Change File"}
                                            </div>
                                            <input className="inputtxt" multiple={true} type="file" accept="image/png,image/jpg,image/jpeg" id="Beneficialdoc" name="Beneficialdoc" onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                            <p className="Qhelper2">Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                        </>
                                        :
                                        <>
                                            <h1 className="Q">Legal ID Document  (Attach front and back both)</h1>
                                            <input type={"file"} multiple={true} name="Beneficialdoc" id="Beneficialdoc" onChange={(txt) => setvalue(txt)} required />
                                            <p className="Qhelper2">Attach legal ID document of beneficial owner</p>
                                        </>}
                                </div>
                            </div>
                            <div className="inputrow">
                                <div className="inputcolumn1">
                                    <AttachFile style={{ marginTop: 30, fill: "var(--secondary)", fontSize: 20 }} />
                                </div>
                                <div className="inputcolumn2">
                                    {AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] ?
                                        <>
                                            <h1 className="Q">Source Of Income Evidence  (Multiple files can be attached)</h1>
                                            <div className="btn"
                                                onClick={() => { document.querySelector("input[name='Beneficialincomedoc']").click(); }}
                                                style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                {data.Beneficialincomedoc ? data.Beneficialincomedoc.length > 1 ? data.Beneficialincomedoc.length + " Files selected" : data.Beneficialincomedoc[0]?.name : "Change File"}
                                            </div>
                                            <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" id="Beneficialincomedoc" name="Beneficialincomedoc" multiple={true} onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                            <p className="Qhelper2">Ensure to Attach/Scan Original (colored) Identity Document (PNG or JPEG)</p>
                                        </>
                                        :
                                        <>
                                            <h1 className="Q">Source Of Income Evidence  (Multiple files can be attached)</h1>
                                            <input type={"file"} multiple={true} name="Beneficialincomedoc" id="Beneficialincomedoc" onChange={(txt) => setvalue(txt)} />
                                            <p className="Qhelper2">Attach source of income evidence of beneficial owner</p>
                                        </>}
                                </div>
                            </div>
                            <>
                                <CustomCheck list={ApiData.CheckBoxList} Name={"FP_Check_1"} setvalue={setvalue} required={true} />
                            </>

                        </Row >
                    </Grid >
                    : <></>}
        </Animatedpage >
    );
}

export default BeneficialInfo;