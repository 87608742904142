import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { applyPolyfills, defineCustomElements } from "fas-web-ui-components/cameraModule/loader";

applyPolyfills().then(() => {
    defineCustomElements(window);
});
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
//if (process.env.NODE_ENV !== "development")
console.log = () => { };
console.warn = () => { };
console.error = () => { };
console.info = () => { };

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement);


