import React, { useEffect} from 'react';
import ReactDOM from 'react-dom';
import Admin from './admin/Main';
import './index.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { FunctionsContextProvider } from '../components/Context/Functions.context';
import { Config } from '../congig';



export default function Index() {

  //useEffect(() => {
  //  if (Config.ADMIN === true) {
  //    require('./admin.css');
  //  }
  //}, [])


  return (
    <>
      <Switch>
        <Redirect exact from='/admin' to='/admin/login' />
        <Redirect exact from='/' to='/admin/login' />
        <Route path="/app" component={Admin} />
        <Route path="/admin" component={Admin} />
      </Switch>

    </>

  );

}
