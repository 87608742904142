import React, { useContext, useEffect, useState } from "react";
import { Chip, Grid, MenuItem } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import Select from '@material-ui/core/Select';
import Input from "reactstrap/lib/Input";
import Zakat from '../../Assets/Documents/Annexure F - Zakat Exemption Declaration for Muslims.pdf'
import W8 from '../../Assets/Documents/FATCA Form W8.pdf'
import W9 from '../../Assets/Documents/FATCA Form W-9.pdf'
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import { ApiDataContext } from "../Context/Api.context";
import { AppScreen } from "./screen";
import CustomCheck from "../customComponents/CustomCheck";
const ZakatNTrans = () => {

    const { data, setData, setvalue, setvalueinArray } = useContext(DataContext);
    const { setActive, setComplete } = useContext(StepsContext);
    const { showInfo, showError, setLoading } = useContext(AlertDataContext);
    const { ZakatNTrans } = useContext(FunctionsContext)
    const { ApiData, setApiData } = useContext(ApiDataContext)
    const [Layout, setLayout] = useState(false)
    const history = useHistory();
    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);


    const datasubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);
        var resp = await ZakatNTrans(data, enable);
        setLoading(false);

        if (resp.StatusCode === 0) {

            history.push(data.option.AccountType === "Asaan Digital Remittance" ? "remitter" : "cnc")
        } else {
            showError("Some Error occurs. Please check your details");
        }
    }
    useEffect(() => {
        if (data.currency !== "1" && data.type === "1") {
            setData({ ...data, Zakat: false });
        }
        setActive("FATCA & CRS Details");
    }, [ApiData])
    return (
        <>
            <Animatedpage enablemode={() => setenable(false)} submit={() => { !enable ? datasubmit() : history.push(data.option.AccountType === "Asaan Digital Remittance" ? "remitter" : "cnc") }} pop={() => setLayout(true)}
                buttons={
                    <div className="btndiv">
                        <button style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() => {
                            if (data.Income.findIndex(p => p.value === 2048) === -1 && data.Income.findIndex(p => p === "Dependent on Others Income") === -1) {
                                history.push("finalize");
                            } else {
                                history.push("beneficial");
                            }
                        }
                        }>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>
                            {window.setedit === true ? "Update" : "Next"}
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                }
            >
                {Layout ?
                    <Grid item xs={12} id="s1q2">
                        <Row>




                            <div style={{ marginTop: 20 }}>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                <h1 className="Q">FATCA Status</h1>
                                {/* <input className="inputtxt" type="text" name="pob" onChange={(txt) => setvalue(txt)} required /> */}
                                <select className="inputtxt" name="FATCAStatus" onChange={(txt) => {
                                    setvalue(txt);
                                    if (txt.target.value === "2") {
                                        setData({ ...data, FATCADeclarations: "1" });
                                    } else if (txt.target.value === "3") {
                                        setData({ ...data, FATCADeclarations: "2" });
                                    }
                                }} required
                                >
                                    <option value="">Select</option>
                                    {ApiData.FATCAStatuslist.map(p => (
                                        <option value={p.id} selected={data.FATCAStatus !== undefined && parseInt(data.FATCAStatus) === p.id || data.FATCAStatus === p.Name}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2"></p>
                                {data.FATCAStatus === "2" ?
                                    <>
                                        <p className="Q" style={{ fontSize: 12 }}>
                                            US person means Citizen / Resident /
                                            Passport holder/Green Card holder/ Tax
                                            Resident as per substantial presence/ Tax
                                            payer for any other reason
                                        </p>
                                    </> :
                                    data.FATCAStatus === "3" ?
                                        <>
                                            <p className="Q" style={{ fontSize: 12 }}>
                                                US indicia/connection means
                                                a person who is not a US person but was
                                                born in USA and / or given standing
                                                instructions to transfer funds to an
                                                account maintained in USA and / or have
                                                any Power of Attorney / Authorized
                                                Signatory / Mandate holder having US
                                                address and / or have US residence /
                                                mailing / Sole Care of address and / or
                                                have US telephone number.
                                            </p>
                                        </> :
                                        <></>
                                }
                            </div>
                            {data.FATCAStatus !== "" && data.FATCAStatus !== "1" && data.FATCAStatus !== "Non-US Person" ?
                                <>
                                    <div style={{ marginTop: 20 }}>
                                        {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                        <h1 className="Q">FATCA Declarations</h1>
                                        {/* <input className="inputtxt" type="text" name="pob" onChange={(txt) => setvalue(txt)} required /> */}
                                        <select className="inputtxt" name="FATCADeclarations" onChange={(txt) => setvalue(txt)} required disabled
                                        >
                                            <option value="">Select</option>
                                            {ApiData.FATCADeclarationslist.map(p => (
                                                <option value={p.id} selected={data.FATCADeclarations !== undefined && parseInt(data.FATCADeclarations) === p.id || data.FATCADeclarations === p.Name} >{p.Name}</option>
                                            ))}
                                        </select>
                                        <p className="Qhelper2"></p>

                                    </div>
                                    <a href={parseInt(data.FATCADeclarations) === 2 ? W8 : W9} className="Q" style={{ marginBottom: 10, fontSize: 12 }} download>Download
                                        ({data.FATCADeclarations?.length === 0 ? "" : ApiData.FATCADeclarationslist.filter(p => p.id === parseInt(data.FATCADeclarations) || p.Name === data.FATCADeclarations)[0]?.Name})</a>
                                    <div style={{ marginTop: 20 }}>
                                        {AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] && data?.WFile !== undefined ?
                                            <>
                                                <h1 className="Q">Attach  ({data.FATCADeclarations?.length === 0 ? "" : ApiData.FATCADeclarationslist.filter(p => p.id === parseInt(data.FATCADeclarations) || p.Name === data.FATCADeclarations)[0]?.Name})</h1>
                                                <div className="btn"
                                                    onClick={() => { document.querySelector("input[name='WFile']").click(); }}
                                                    style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                    {data.WFile && data.WFile[0]?.name !== undefined ? data.WFile[0]?.name : "Change File"}
                                                </div>
                                                <input type={"file"} multiple={false} id="WFile" name="WFile" onChange={(txt) => setvalue(txt)} style={{ display: "none" }} required={data?.WFile?.length > 0 ? false : true} />
                                                <p className="Qhelper2">Countries in which you are a resident for tax purposes.</p>
                                            </>
                                            :
                                            <>
                                                <h1 className="Q">Attach  ({data.FATCADeclarations?.length === 0 ? "" : ApiData.FATCADeclarationslist.filter(p => p.id === parseInt(data.FATCADeclarations))[0]?.Name})</h1>
                                                <input type={"file"} multiple={false} id="WFile" name="WFile" onChange={(txt) => setvalue(txt)} required
                                                />
                                                <p className="Qhelper2">Countries in which you are a resident for tax purposes.</p>
                                            </>}
                                    </div>
                                </>
                                : <></>}
                            <>
                                <h1 className="Q" style={{ marginTop: 20, marginBottom: 20 }}>CRS Details</h1>
                                <h1 className="Q" style={{ marginTop: 20, marginBottom: 20 }}>COUNTRY OF TAX RESIDENCE FOR TAX PURPOSES</h1>
                                {[0].map(p =>
                                    <div style={{ borderWidth: 2, borderColor: "var(--primary)", borderStyle: "solid", padding: 10, marginBottom: 10, borderRadius: 10 }}>
                                        <div >
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                            <h1 className="Q">Country of Residence for Tax Purpose</h1>
                                            <select className="inputtxt" name="taxResidence" onChange={(txt) => setvalueinArray(txt, "COUNTRYTAXRESIDENCE", p)} required={p !== 0 ? false : true}
                                            >
                                                <option value="">Select</option>

                                                {ApiData.Country.map(c => (
                                                    <option value={c.id} selected={data?.COUNTRYTAXRESIDENCE !== undefined && (parseInt(data?.COUNTRYTAXRESIDENCE[p]?.taxResidence) === c.id || data?.COUNTRYTAXRESIDENCE[p]?.taxResidence === c.Name?.replaceAll(" ", ""))}>{c.Name}</option>
                                                ))}
                                            </select>
                                            <p className="Qhelper2">Countries in which you are a resident for tax purposes.</p>

                                        </div>
                                        <div>
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                            <h1 className="Q">Tax Identification No. (TIN)</h1>
                                            <input className="inputtxt" type="text" name="TIN" value={data?.COUNTRYTAXRESIDENCE !== undefined ? data?.COUNTRYTAXRESIDENCE[p]?.TIN : ""} onChange={(txt) => setvalueinArray(txt, "COUNTRYTAXRESIDENCE", p)} />
                                            <p className="Qhelper2">Provide Taxpayer Identification Number TIN &amp; functional equivalent for country indicated.</p>
                                        </div>
                                        <div>
                                            {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                            <h1 className="Q">Reason if no TIN available</h1>

                                            <select className="inputtxt" name="TINReason" onChange={(txt) => setvalueinArray(txt, "COUNTRYTAXRESIDENCE", p)} required={data?.COUNTRYTAXRESIDENCE !== undefined && (data?.COUNTRYTAXRESIDENCE[p]?.TIN === undefined || data?.COUNTRYTAXRESIDENCE[p]?.TIN === "") && p === 0 ? true : false}
                                            >
                                                <option value="">Select</option>

                                                {ApiData.TINReasonList.map(c => (
                                                    <option value={c.id} selected={data?.COUNTRYTAXRESIDENCE !== undefined && (parseInt(data?.COUNTRYTAXRESIDENCE[p]?.TINReason) === c.id || data?.COUNTRYTAXRESIDENCE[p]?.TINReason === c.Name)}>{c.Name}</option>
                                                ))}

                                            </select>
                                            <p className="Qhelper2"></p>
                                        </div>
                                        {data?.COUNTRYTAXRESIDENCE !== undefined && (data?.COUNTRYTAXRESIDENCE[p]?.TINReason + "" === "2" || (data?.COUNTRYTAXRESIDENCE[p]?.TINReason + "" !== "None" && data?.COUNTRYTAXRESIDENCE[p]?.TINremarks !== "" && data?.COUNTRYTAXRESIDENCE[p]?.TINremarks !== undefined && data?.COUNTRYTAXRESIDENCE[p]?.TIN !== null && data?.COUNTRYTAXRESIDENCE[p]?.TINremarks !== null)) ?
                                            <div>
                                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>3</b> / 6</p> */}
                                                <h1 className="Q">Remarks</h1>
                                                <input className="inputtxt" type="text" name="TINremarks" value={data?.COUNTRYTAXRESIDENCE[p]?.TINremarks} onChange={(txt) => setvalueinArray(txt, "COUNTRYTAXRESIDENCE", p)} required={p !== 0 ? false : true} />
                                                <p className="Qhelper2">Provide Taxpayer Identification Number TIN &amp; functional equivalent for country indicated.</p>
                                            </div>
                                            : <></>
                                        }
                                    </div>)}
                            </>
                        </Row>
                    </Grid>
                    : <></>}
            </Animatedpage>

        </>
    );
}

export default ZakatNTrans;