import React, { useContext, useEffect, useRef, useState } from "react";
import { Grid } from "@material-ui/core";
import { ArrowRight, ArrowLeft, CheckBox, AttachFile, Sort } from "@material-ui/icons";
import Button from "reactstrap/lib/Button";
import Row from "../Row";
import { DataContext } from "../Context/Data.Context";
import Blue from '../../Assets/blue.png'
import { useHistory } from "react-router";
import { StepsContext } from "../Context/Steps.Context";
import Animatedpage from "../animatepage";
import CardSample from "../../Assets/cardsample.png";
import Paypak from '../../Assets/paypak.png'
import Mastergold from '../../Assets/mastergold.png'
import Master from '../../Assets/master.png'
import { Chip, MenuItem } from "@material-ui/core";

// import Select from '@material-ui/core/Select';
import Select from 'react-select';
import Input from "reactstrap/lib/Input";
import { makeStyles } from "@material-ui/styles";

import { ApiDataContext } from "../Context/Api.context";
import { AlertDataContext } from "../Context/Alert.context";
import { FunctionsContext } from "../Context/Functions.context";
import { Config } from "../../congig";
import { AppScreen } from "./screen";
import CustomCheck from "../customComponents/CustomCheck";
import { sortArray } from "../service";

const CNC = () => {
    const { data, setData, setvalue, setmultiple } = useContext(DataContext);
    const { setActive } = useContext(StepsContext);
    const { ApiData } = useContext(ApiDataContext)
    const { DynamicModelShow, showError, setLoading } = useContext(AlertDataContext);
    const { CNCInfo, Finish } = useContext(FunctionsContext)


    const [plist, setPlist] = useState([]);
    const [brancheslist, setbrancheslist] = useState([])
    const cityref = useRef();
    const [show, setshow] = useState(false);

    const history = useHistory();

    const [enable, setenable] = useState(AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")]);

    const showFDAModel = (data) => {
        var t = <>
            <ul>
                {data.LongDescription.map(o => (
                    <li>{o}</li>
                ))}
            </ul>
            {/* {data.Note !== "" ?
                <p className="Q">Note:{data.Note}</p>
                : <></>} */}
        </>;
        DynamicModelShow(data.Name, t, true);
    }
    const populatecities = () => {
        var cities = {};
        var t = '<option value="">Select</option>';
        sortArray(brancheslist, "City").map(p => {

            if (p.Province === data.Branchprovince && !(p.City in cities)) {
                t += `<option ${parseInt(data.Branchcity) === p.City || data.Branchcity === p.City ? "selected" : ""} value="${p.City}">${p.City}</option>`;

                cities[p.City] = 1;

            }
        })
        cityref.current.innerHTML = t;
    }
    useEffect(() => {
        
        setActive("Account & Card Details");
        setData({ ...data, CheckbookLeave: 1 });
        checkifvalueExist();
    }, [])
    useEffect(() => {
        if (plist.length === 0) {
            popbanklist();
        }
    }, [ApiData])
    const popbanklist = () => {
        
        setLoading(true);
        if (ApiData.BankList?.length > 0) {
            var t = ApiData.BankList;

            if (t?.length > 0) {
                var arr = [];
                var alreadyp = [];
                var branches = [];
                for (let index = 0; index < t.length; index++) {

                    const element = JSON.parse(t[index]);
                    if (alreadyp[element.Province] === undefined) {
                        arr.push(element.Province);
                        alreadyp[element.Province] = 1;
                    }
                    branches.push(element);
                }
                setPlist([...arr]);
                setbrancheslist([...branches]);
            }
        }
        setLoading(true);

    }

    const datasubmit = async (e) => {

        if (data?.CorrespondingAddress === "3" && (data?.officeaddress === undefined || data?.officeaddress === "")) {
            alert("Please select corresponding address.")
            return;
        }

        setLoading(true);

        if (sessionStorage.getItem("ReferralId") !== "" && sessionStorage.getItem("ReferralId") !== null && sessionStorage.getItem("ReferralId") !== undefined) {
            if (window.setedit == true) {
                var resp = await CNCInfo(data, enable);
            }
            var t = await Finish();
            history.push("thankyou");
        } else {
            var resp = await CNCInfo(data, enable);
            if (resp.StatusCode === 0) {
                //setApiData({ ...ApiData, ...resp.ResponseObject });
                history.push("liveness")
            }
            else if (resp.StatusCode === 28) {
                ;
                setshow(false);
                var t = ApiData.Banking.filter(p => p.BankingType == resp.StatusMessage)[0];
                var selectat = data.selectedAT;
                selectat.Id = t.id;
                selectat.BankingType = t.BankingType;
                //setApiData({ ...ApiData, ...resp.ResponseObject });selectedAT
                setData({ ...data, selectedAT: { ...selectat } });
                setshow(true);
                showError("Invalid Bank Branch Selection. Please check your details");
            }
            else {
                showError("Some Error occurs. Please check your details");
            }
        }
        setLoading(false);
    }

    const populatemultiopt = (arr) => {
        var s = [];
        arr.map(p => {
            s.push({ value: p.id, label: p.Name });
        })
        return s;
    }
    const checkifvalueExist = () => {
        return new Promise((resolve, reject) => {
            
            if (data.NutureTrans !== undefined && data?.NutureTrans.length > 0) {
                var s = [];
                data?.NutureTrans.map(p => {
                    if (p?.label !== undefined) {
                        s.push(p);

                    } else {
                        var opt = ApiData.NatureOfTransactionsList.filter(o => o.Name === p.trim())[0];
                        if (opt?.Name !== undefined) {
                            s.push({ value: opt.id, label: opt.Name });
                            //setmultiple({ value: opt.id, label: opt.Name }, "NutureTrans")
                        }
                    }
                });
                setmultiple(s, "NutureTrans")
                //setData({ ...data, NutureTrans: s });
            }

            if (data.ATMReason !== undefined && data?.ATMReason.length > 0) {
                var s = [];
                data?.ATMReason.map(p => {
                    if (p !== "None") {
                        if (p?.label !== undefined) {

                            s.push(p);
                        } else {
                            var opt = ApiData.CardReason.filter(o => o.Name === p.trim())[0];
                            if (opt?.Name !== undefined) {
                                // setmultiple({ value: opt.id, label: opt.Name }, "ATMReason")
                                s.push({ value: opt.id, label: opt.Name });
                            }
                        }
                    }
                });
                setmultiple(s, "ATMReason")
                //setData({ ...data, ATMReason: s });
            }
            if (data.Branchname !== "") {
                var details = brancheslist.filter(o => o["Branch Codes"] === data.Branchname);
                if (details?.length > 0) {
                    setData({ ...data, Branchprovince: details[0].Province, Branchcity: details[0].City });
                }
            }
            resolve();
        })
    }

    useEffect(async () => {
        if (show === true) {
            setLoading(true);
            await checkifvalueExist()
            populatecities();
            setLoading(false);
        }
    }, [show])

    useEffect(() => {
        if (data?.Branchprovince !== undefined && show === true) {
            var cities = {};
            var t = '<option value="">Select</option>';
            brancheslist.map(p => {

                if (p.Province === data.Branchprovince && !(p.City in cities)) {
                    t += `<option ${parseInt(data.Branchcity) === p.City || data.Branchcity === p.City ? "selected" : ""} value="${p.City}">${p.City}</option>`;

                    cities[p.City] = 1;

                }
            })
            cityref.current.innerHTML = t;
        }
    }, [data?.Branchprovince, show])
    return (
        <>


            <Animatedpage enablemode={() => { setenable(false); }} submit={() => { datasubmit() }} pop={() => { setshow(true); populatecities(""); }}
                buttons={
                    <div className="btndiv">
                        <button variant="contained" style={{ margin: 10, background: "var(--secondary)", border: "none", borderRadius: 10, marginTop: 20 }} onClick={() =>
                            history.push(data.option.AccountType === "Asaan Digital Remittance" ? "remitter" : "znt")
                        }>
                            <ArrowLeft style={{ marginLeft: -10 }} />
                            Back
                        </button>
                        <button type="submit" style={{ margin: 10, background: "var(--primary)", border: "none", borderRadius: 10, marginTop: 20 }}>
                            {window.setedit === true ? "Update" : "Next"}
                            <ArrowRight style={{ marginRight: -10 }} />
                        </button>
                    </div>
                }
            >
                {show ?
                    <Grid item xs={12} id="s1q2">
                        <Row>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q">Estimated Annual Income </h1>
                                <select className="inputtxt" name="EstimatedIncome" onChange={(txt) => setvalue(txt)} required
                                //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                >
                                    <option value="">Select</option>
                                    {ApiData.AnnualIncomeList.map(p => (
                                        <option value={p.id} selected={data.EstimatedIncome !== undefined && (parseInt(data.EstimatedIncome) === p.id || data.EstimatedIncome === p.Name)}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2"></p>

                            </div>
                            <div>
                                <h1 className="Q" style={{ marginTop: 20 }}>Select your purpose of account</h1>
                                {/* <p className="Qhelper">Please enter your full name as it appears on your CNIC, SNIC or NICOP.</p> */}
                                <select className="inputtxt"
                                    onChange={(txt) => setData({ ...data, purpose: txt.target.value })}
                                    name="purpose"
                                >
                                    <option value="">Select</option>
                                    {
                                        ApiData.accountPurposeList.map(p => (
                                            <option value={p.id} selected={data.purpose !== undefined && parseInt(data.purpose) === p.id || data.purpose === p.Name}>{p.Name}</option>
                                        ))
                                    }
                                </select>
                            </div>


                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                <h1 className="Q">Electronic Fund Transfer (EFT)Consent</h1>

                                <select className="inputtxt" name="EFT" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>
                                    <option value="0" selected={data.EFT !== undefined && parseInt(data.EFT) === 0}>No</option>
                                    <option value="1" selected={data.EFT !== undefined && parseInt(data.EFT) === 1}>Yes</option>
                                </select>
                                <p className="Qhelper2"></p>
                                {data.EFT === "1" ?
                                    <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_EFT_1"} setvalue={setvalue} required={true} />
                                    : <></>}
                            </div>


                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>2</b> /2</p> */}
                                <h1 className="Q">ATM Debit Card</h1>
                                <select className="inputtxt" name="ATM" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>
                                    <option value="0" selected={parseInt(data.ATM) === 0} >No</option>
                                    <option value="1" selected={parseInt(data.ATM) === 1} >Yes</option>

                                </select>
                                <p className="Qhelper2"></p>

                            </div>
                            {data.ATM === "1" ?
                                <>
                                    <div>
                                        <h1 className="Q">Card Type</h1>
                                        {ApiData.CardTypeDetailList.ResponseObject.map((p, i) => (
                                            <>
                                                <div key={p.Id} className={"Carddiv" + ((data.ATMCard?.Id === p.Id) ? " Active" : "")} onClick={() => {
                                                    if (!enable) {
                                                        setData({ ...data, ATMCard: p })
                                                    }
                                                    showFDAModel(p)
                                                }} style={{ width: 300, height: 220 }}>
                                                    {data.ATMCard?.Id === p.Id ? <CheckBox className="CardCheck" style={{ color: "var(--light)" }} /> : null}
                                                    <p className="Q" style={{ display: "inline-block", marginTop: 0 }}>{p.Name}</p>
                                                    <img src={(Config.LOCALIMG ? Config.LOCALADDRESS : window.location.origin) + p.CardPath} />
                                                </div>
                                                {i === 0 ? <br /> : <></>}
                                            </>
                                        ))}
                                        <p className="Qhelper2"></p>

                                    </div>
                                    {
                                        data.ATMCard.Name !== undefined && data.ATMCard?.Name !== "Soneri PayPak Debit Card" ?

                                            <div>
                                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                                <h1 className="Q" style={{ marginTop: 20 }}>Reason for selection of {data.ATMCard?.Name}</h1>
                                                <Select
                                                    defaultValue={data.ATMReason}
                                                    isMulti
                                                    name="colors"
                                                    //onChange={() => { }}
                                                    options={populatemultiopt(ApiData.CardReason)}
                                                    className="basic-multi-select"
                                                    classNamePrefix="select"
                                                    onChange={(txt) => setmultiple(txt, "ATMReason")}
                                                />
                                                <p className="Qhelper2"></p>

                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                                : <></>}

                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                <h1 className="Q">Cheque Book</h1>

                                <select className="inputtxt" name="Checkbook" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>

                                    <option value="0" selected={data.Checkbook !== undefined && parseInt(data.Checkbook) === 0}>No</option>
                                    <option value="1" selected={data.Checkbook !== undefined && parseInt(data.Checkbook) === 1}>Yes</option>
                                </select>
                                <p className="Qhelper2"></p>
                            </div>
                            {data.Checkbook === "1" ?
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper"></p> */}
                                    <h1 className="Q">Select No. of Leaves</h1>
                                    <select className="inputtxt" name="CheckbookLeave" onChange={(txt) => setvalue(txt)} required disabled>
                                        {/* <option value="">Select</option> */}

                                        {ApiData.LeavesList.map(p => (
                                            <option value={p.id} selected={data.CheckbookLeave !== undefined && parseInt(data.CheckbookLeave) === p.id || data.CheckbookLeave === p.Name}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>
                                    <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Checkbook_1"} setvalue={setvalue} required={true} />
                                </div>
                                : <></>}




                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q" style={{ marginTop: 20 }}>Nature of Transactions</h1>

                                <Select
                                    defaultValue={data.NutureTrans}
                                    isMulti
                                    name="colors"
                                    options={populatemultiopt(ApiData.NatureOfTransactionsList)}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={(txt) => setmultiple(txt, "NutureTrans")}
                                />
                                <p className="Qhelper2"></p>

                            </div>

                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q">Expected Monthly Credit – Transactions</h1>
                                <select className="inputtxt" name="MonthlyCredittrans" onChange={(txt) => setvalue(txt)}
                                //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                >
                                    <option value="">Select</option>

                                    {ApiData.TransactionCountList.map(p => (
                                        <option value={p.id} selected={data.MonthlyCredittrans !== undefined && parseInt(data.MonthlyCredittrans) === p.id || data.MonthlyCredittrans === p.Name}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2"></p>

                            </div>

                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q">Expected Monthly Credit Turnover </h1>
                                <select className="inputtxt" name="MonthlyCredit" onChange={(txt) => setvalue(txt)}
                                //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                >
                                    <option value="">Select</option>

                                    {ApiData.ExpectedMonthlyList.map(p => (
                                        <option value={p.id} selected={data.MonthlyCredit !== undefined && parseInt(data.MonthlyCredit) === p.id || data.MonthlyCredit === p.Name}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2"></p>

                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q">Expected Monthly Debit – Transactions</h1>
                                <select className="inputtxt" name="MonthlyDebittrans" onChange={(txt) => setvalue(txt)}
                                //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                >
                                    <option value="">Select</option>

                                    {ApiData.TransactionCountList.map(p => (
                                        <option value={p.id} selected={data.MonthlyDebittrans !== undefined && parseInt(data.MonthlyDebittrans) === p.id || data.MonthlyDebittrans === p.Name}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2"></p>

                            </div>

                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q">Expected Monthly Debit Turnover </h1>
                                <select className="inputtxt" name="MonthlyDebit" onChange={(txt) => setvalue(txt)}
                                //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                >
                                    <option value="">Select</option>

                                    {ApiData.ExpectedMonthlyList.map(p => (
                                        <option value={p.id} selected={data.MonthlyDebit !== undefined && parseInt(data.MonthlyDebit) === p.id || data.MonthlyDebit === p.Name}>{p.Name}</option>
                                    ))}
                                </select>
                                <p className="Qhelper2"></p>

                            </div>





                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /7</p> */}
                                <h1 className="Q">SMS Subscription</h1>
                                <select className="inputtxt" name="smssubscription" onChange={(txt) => setvalue(txt)} required
                                //disabled={data.currency==="PKR"&&data.type==="current"?true:false}
                                >
                                    <option value="">Select</option>
                                    <option value="0" selected={data.smssubscription !== undefined && parseInt(data.smssubscription) === 0}>No</option>
                                    <option value="1" selected={data.smssubscription !== undefined && parseInt(data.smssubscription) === 1}>Yes</option>
                                </select>
                                <p className="Qhelper2">Charges as per SOC</p>

                            </div>


                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                <h1 className="Q">E-Statement Subscription</h1>

                                <select className="inputtxt" name="ESS" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>

                                    <option value="0" selected={data.ESS !== undefined && parseInt(data.ESS) === 0}>No</option>
                                    <option value="1" selected={data.ESS !== undefined && parseInt(data.ESS) === 1}>Yes</option>
                                </select>
                                <p className="Qhelper2"></p>
                            </div>
                            {data.ESS === "1" ?
                                <div>
                                    {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                    <h1 className="Q">E-mail <b>{data.email}</b></h1>
                                    <h1 className="Q">E-Statement Frequency</h1>
                                    <select className="inputtxt" name="ESF" onChange={(txt) => setvalue(txt)} required>
                                        <option value="">Select</option>

                                        {ApiData.StatementFrequencyList.map(p => (
                                            <option value={p.id} selected={data.ESF !== undefined && parseInt(data.ESF) === p.id || data.ESF === p.Name}>{p.Name}</option>
                                        ))}
                                    </select>
                                    <p className="Qhelper2"></p>
                                    <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_ESF_1"} setvalue={setvalue} required={true} />
                                </div>
                                : <></>
                            }
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                <h1 className="Q">Corresponding Address</h1>
                                <select className="inputtxt" name="CorrespondingAddress" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>

                                    {ApiData.CorrespondingAddressList.map(p => (
                                        <option value={p.id} selected={data.CorrespondingAddress !== undefined && parseInt(data.CorrespondingAddress) === p.id || data.CorrespondingAddress === p.Name}>{p.Name}</option>
                                    ))}
                                </select>
                                {data?.CorrespondingAddress === "3" && (data?.officeaddress === undefined || data?.officeaddress === "") ?

                                    <>
                                        <p className="Qhelper" style={{ margin: 1, color: "red" }}>Office
                                            Address Found Blank</p>
                                    </>
                                    : <></>}
                                <p className="Qhelper2"></p>
                            </div>
                            <div>
                                {/* <p style={{ marginTop: 40 }} className="Qhelper">Question <b>1</b> /2</p> */}
                                <h1 className="Q">Select your prefered Soneri Bank Branch</h1>
                                <h1 className="Q" style={{ fontSize: 12 }}>Province / Region</h1>
                                <select className="inputtxt" name="Branchprovince" onChange={(txt) => { setvalue(txt); populatecities() }} required>
                                    <option value="">Select</option>
                                    {
                                        sortArray(plist, "abc").map(p => (
                                            <option value={p} selected={data.Branchprovince !== undefined && parseInt(data.Branchprovince) === p || data.Branchprovince === p}>{p}</option>
                                        ))
                                    }
                                </select>

                                <h1 className="Q" style={{ fontSize: 12 }}>City</h1>
                                <select className="inputtxt" name="Branchcity" onChange={(txt) => { setvalue(txt) }} ref={cityref} required>

                                </select>
                                <h1 className="Q" style={{ fontSize: 12 }}>Branch</h1>
                                <select className="inputtxt" name="Branchname" onChange={(txt) => setvalue(txt)} required>
                                    <option value="">Select</option>
                                    {brancheslist.map(p => (
                                        <>
                                            {
                                                p.City === data.Branchcity && p.Segment === data.selectedAT.BankingType ?
                                                    (data.option?.Id === 3 && p["FX/AD Branch"]?.trim() === "Yes" && (parseInt(data?.currency) !== 1 && data?.currency !== "1" && data?.currency !== "PKR")) ?
                                                        <option selected={data?.Branchname !== undefined && (parseInt(data?.Branchname) === p["Branch Codes"] || data?.Branchname === p["Branch Codes"])} value={p["Branch Codes"]}>{p["Branch Name"]}</option>
                                                        : ((parseInt(data.currency) === 1 || data.currency === "1" || data.currency === "PKR")) ?
                                                            <option selected={data.Branchname !== undefined && (parseInt(data?.Branchname) === p["Branch Codes"] || data?.Branchname === p["Branch Codes"])} value={p["Branch Codes"]}>{p["Branch Name"]}</option>
                                                            : <></>
                                                    : <></>
                                            }
                                        </>
                                    ))}
                                </select>
                                <h1 className="Q" style={{ fontSize: 12 }}>Branch Staff Referral</h1>
                                <input className="inputtxt" name="Branchref" value={data.Branchref} onChange={(txt) => setvalue(txt)} />
                                <p className="Qhelper2"></p>
                            </div>
                            {data.Checkbook === "1" ? <>
                                <div className="inputrow">
                                    <div className="inputcolumn2">
                                        {AppScreen[history.location.pathname.replace("/", "")] < AppScreen[sessionStorage.getItem("CurrentScreen")] ?
                                            <>
                                                <h1 className="Q">Upload Specimen Signature for Account</h1>
                                                <div className="btn"
                                                    onClick={() => { document.querySelector("input[name='signature']").click(); }}
                                                    style={{ background: "var(--primary)", border: "none", borderRadius: 10 }} >
                                                    {data.signature ? data.signature[0]?.name : "Change File"}
                                                </div>
                                                <input className="inputtxt" type="file" accept="image/png,image/jpg,image/jpeg" name="signature" id="signature" multiple={false} onChange={(txt) => setvalue(txt)} style={{ display: "none" }} />
                                                <p className="Qhelper2">Please ensure to upload your signatures neatly affixed on a plain white paper in Blue/Black ink. Please avoid imprinted signatures.</p>
                                            </>
                                            :
                                            <>
                                                <h1 className="Q">Upload Specimen Signature for Account</h1>
                                                <input className="inputtxt" multiple={false} name="signature" id="signature" type="file" onChange={(txt) => setvalue(txt)} required />
                                                <p className="Qhelper">Please ensure to upload your signatures neatly affixed on a plain white paper in Blue/Black ink. Please avoid imprinted signatures.</p>
                                            </>}
                                    </div>
                                </div>
                                <div>
                                    <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Signature1_2"} setvalue={setvalue} required={true} />
                                    {/* <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Signature2_1"} setvalue={setvalue} required={true} />
                                <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Signature3_1"} setvalue={setvalue} required={true} />
                                <CustomCheck list={ApiData.CheckBoxList} Name={"CNC_Signature4_1"} setvalue={setvalue} required={true} /> */}
                                    {/* <label for="trm" className="Qhelper">
                                        <input type="checkbox" id="trm" style={{ marginRight: 5, width: 18, height: 18 }} required />
                                        I/We do hereby agree with the terms mentioned above.
                                    </label > */}
                                </div>
                            </> : <></>}
                        </Row>
                    </Grid>
                    :
                    <></>
                }
            </Animatedpage>
        </>
    );
}

export default CNC;